 <template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;overflow: hidden;">
        <div style="height: 100%;width: 100%; display: flex; flex-direction: column; overflow: hidden;">
            <el-form>
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: auto;display: flex;">        
                    <el-form-item style="margin-left: 10px;">
                        <el-button type="primary" plain size="" icon="el-icon-search" @click="refrashdate">搜索</el-button>
                        <!-- <el-button type="primary" @click="refrashdate">查询</el-button>  el-icon-folder-add-->
                    </el-form-item>
                    <el-form-item v-if="checkvisible('新增')" style="margin-left: 10px;">
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="addrule">新增角色</el-button>
                    </el-form-item>
                    <el-form-item v-if="checkvisible('删除')" style="margin-left: 10px;">
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="saverulename(1)">删除角色</el-button>
                    </el-form-item>        
                    <el-form-item v-if="checkvisible('修改')" style="margin-left: 10px;">
                        <el-button type="success" plain icon="el-icon-folder-add" @click="saveruleauthinfo">保存权限</el-button>
                    </el-form-item> 
            </div>
        </el-form>
            <div style="width:100%;display:flex;">
                <div style="width:10%">
                    <div  style="height:auto;margin: 5px;background-color: rgb(251, 251, 251);"
                        v-for="(item, index) in rulelist" :key="index">
                        <div :class="[item.isactive?'disactive':'active']" @click="clickrulename(item)" >
                            <i class="el-icon-user"></i>
                            <i style="margin-left: 5px;">{{ item.rulename }}</i>
                        </div>
                        <!-- <el-button type="success" plain icon="el-icon-folder-add">{{ item.rulename }}</el-button> -->
                    </div>
                </div>
                <div
                    style="width:70%;height: 100%;border-left: solid 1px #EBEEF5; box-shadow: 0 2px 4px rgba(126, 126, 126, 0.12), 0 0 6px rgba(116, 116, 116, 0.04)">
                    <div v-for="(item,index) in menuauthlist " :key="index">
                        <div style="border-bottom: solid 1px  #EBEEF5;margin: 10px;">
                            <i class="el-icon-files" style="color: #303133;font-size: 12px;">
                               <i style="font-size: 12px;margin-left: 5px;">{{item.menuname}}</i> 
                            </i>
                        </div>
                        <div style="margin-top: 5px;margin-left: 40px;">     
                            <!-- <el-checkbox-group   size="mini"> -->
                                <el-checkbox border v-model="items.isselect" size="mini" v-for="(items,indexs) in item.atuhlist" :label="items.authname" :key="indexs">{{ items.authname }}</el-checkbox>
                            <!-- </el-checkbox-group> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="详情编辑" :visible.sync="dialog_taskinfo" :close-on-click-modal="false" :destroy-on-close="true">
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">角色名称</div>
                <div style="width: 80%;"><el-input    v-model="rulename" placeholder=""></el-input></div>
               </div>
               <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saverulename(0)">保存</el-button>
                <el-button @click="dialog_taskinfo = false">关闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { INSRULELIST,GETRULELIST,GETAUTHRULELIST,UPRULELISTINFO} from "@/api";

export default {

    data() {
        return {
            mguid:'',
            dialog_taskinfo: false,
            rulename:'',
            rulelist:[
                { rulename: '管理员' ,isactive:false},
                { rulename: '打单员' ,isactive:false},
                { rulename: '业务员' ,isactive:true},
                { rulename: '负责人' ,isactive:false},
                { rulename: '标签信息' ,isactive:false},
                { rulename: '标签池子' ,isactive:false},
                { rulename: '激活申请',isactive:false }
            ],
            menuauthlist: [
                { menuname: '公司信息' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:false},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]},
                { menuname: '角色管理' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:true},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]},
                { menuname: '用户管理' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:true},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]},
                { menuname: '产品管理' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:true},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]},
                { menuname: '标签信息' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:true},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]},
                { menuname: '激活申请' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:true},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]},
                { menuname: '标签池' ,
                atuhlist:[
                    {authname:'新增',isselect:true},
                    {authname:'修改',isselect:true},
                    {authname:'删除',isselect:true},
                    {authname:'停用',isselect:true},
                    {authname:'查询',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true},
                    {authname:'新增',isselect:true}  
                ]}
            ]
        }
    },
    methods: {
        checkvisible(btname){
            let isvisible = false

            const menuData = this.$store.state.jiaoseguanli.find(v => v.bname === btname);

            if (menuData) {
                if (menuData.isvisible) { isvisible = menuData.isvisible } else isvisible = false
            }
            else isvisible = false
            return isvisible
        },
        delrule(){

        },
        addrule(){
this.dialog_taskinfo=true
this.rulename=''
        },
        async  saveruleauthinfo(){
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let jsonstr=JSON.stringify(this.menuauthlist);
       // console.log(this.menuauthlist);
        
        const { data: res } = await UPRULELISTINFO({
        usercode,
        token,
        jsonstr     
      });
        let result = JSON.parse(res)[0];
       // console.log('result',result)
            if (result.code !== 200) {
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {            
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });          
            }
        },
      async  saverulename(mflag){
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let mmguid=this.$store.state.btypeinfo.email;
        
        let rulename=this.rulename
        let flag=mflag
        let mguid=!this.mguid?'x':this.mguid
        const { data: res } = await INSRULELIST({
        usercode,
        token,
        mmguid ,
        mguid,
        rulename,
        flag
      });
        let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.gettasktablelistdata();
                if(mflag==0)
                this.dialog_taskinfo=false
            }
        },
        clickrulename(item){ 
            this.rulelist.forEach(function(items,index){
                items.isactive=false
            })
            item.isactive=true
            this.mguid=item.mguid
            this.rulename=item.rulename;
          //  console.log('点击了开始查询')
            this.getauthdetail(item);
        },
 async getauthdetail(item){
    let usercode = window.sessionStorage.getItem("usercode");
      let token = window.sessionStorage.getItem("token");
      let mguid=item.mguid;
      
      const { data: res } = await GETAUTHRULELIST({
        usercode,
        token,
        mguid 
      });
      let ret = JSON.parse(res);
      // console.log('查询数据',ret)
      if (!ret[0]){
        this.menuauthlist=[]
      }else{
        this.menuauthlist=ret    
      }
 },
        dateFormat(data) {
            let date = new Date(data);
            let YY = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD;
        },
        refrashdate() {
            this.gettasktablelistdata();
        },

      async  gettasktablelistdata(e) {
       // console.log('查询数据')
      let usercode = window.sessionStorage.getItem("usercode");
      let token = window.sessionStorage.getItem("token");
      let mmguid=this.$store.state.btypeinfo.email;
      const { data: res } = await GETRULELIST({
        usercode,
        token,
        mmguid 
      });
      let ret = JSON.parse(res);
      //console.log('查询数据',ret)
      if (!ret[0]){
        this.rulelist=[]
        this.menuauthlist=[]
      }else{
        this.rulelist=ret
        this.clickrulename(this.rulelist[0])
       // this.getauthdetail(this.rulelist[0])
      }
        }

    },
    mounted() {
          //  console.log('先执行我了')
           this.refrashdate()
        }
}
</script>
<style lang='less' scoped>
.active{
    background-color:#e9f4e5 ;
    color: #67C23A;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #67C23A;
    border-radius:2px;
    cursor: pointer;
}
.disactive{
    background-color:#67C23A;
    color: #e9f4e5 ;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e9f4e5;
    border-radius:2px;
    cursor: pointer;
}
</style>
<template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;">
        <div style="height: 100%;width: 100%;">
            <div style="background-color: rgb(243, 243, 243);width: 100%;height: 40px;display: flex;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="起始日期">
                        <el-date-picker v-model="begindate" align="right" type="date" placeholder="选择日期"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="截止日期">
                        <el-date-picker v-model="enddate" align="right" type="date" placeholder="选择日期"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="refrashdate">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table :data="tableData" stripe :height="tableHeight" ref="table" id="tableId"
            style="width: 100%" border stripe fit :header-cell-style="{
                background: '#E4E7ED', height: '20px', color: '#000000', border: '1px solid #EBEEF5'
            }"
            >
                <el-table-column :prop="item.proname" :label="item.name" width="180" v-for="item in tableheader" sortable
                    :key="item.name" v-if="item.isvisible"                   
                    >                  
                </el-table-column>     
            </el-table>            
        </div>
        <el-dialog title="详情编辑" :visible.sync="dialog_taskinfo" width="60%"> 
<div style="width: 100%;display: flex;margin-top: 3px;">
    <div style="width: 10%;">处理建议</div>
    <div style="width: 80%;">
         <el-input type="textarea" v-model="mcomment"></el-input>
    </div>
</div> 
 
<div style="width: 100%;display: flex;margin-top: 15px;">
    <div style="width: 10%;">是否关闭</div>
    <div style="width: 80%;">
        <el-switch v-model="isclose" active-text="关闭" inactive-text="处理中">
       </el-switch>
    </div>
</div>      
<div slot="footer" class="dialog-footer">   
    <el-button type="primary" @click="saveformdata">提交</el-button>   
    <el-button @click="dialog_taskinfo = false">关闭</el-button>
</div>
</el-dialog>
</div>
</template>
<script>
import { GETTASKITEMLISTDATA, GETTASKTREELIST, GETTASKDATAKPI, weburl,GETCOMPUTLOG  } from "@/api";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
    data() {
        return {
            mcomment:'',
            mydate:'',
            msdate:'',
            isclose:'',
            mdate:'',
            dialog_taskinfo:false,
            taskcode: '',
            deafultcvalue: '',
            menutreelist: [],
            begindate: '',
            enddate: '',
            radio1: '',
            tableheader: [],
            tableData: [],
            mguid:'',
            tableHeight:''

        }
    },
    methods: {
        async saveformdata(){
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            //console.log('mcomment',this.mcomment);
            let mcommnet=this.mcomment;
            let mydate=this.mydate;
            let msdate=this.msdate;
            let isclose=this.isclose;
            let mguid=this.mguid;
            const { data: res } = await UPIsNglistData({
                usercode,
                token,
                mcommnet,
                mydate,
                msdate,
                isclose,
                mguid

            });
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                this.$notify.error({
                    title: "错误",
                    message: result.message,
                    duration: 1000,
                });
            } else {
                // this.$notify({
                //     title: "成功",
                //     message: result.message,
                //     type: "success",
                // });
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.dialog_taskinfo=false;
                this.refrashdate();
            };

        },
 
  refrashdate() {
            if (this.begindate) {

            } else {
                this.$notify.error({
                    title: "错误",
                    message: '请选择日期',
                    duration: 1000,
                });
                return;
            };
            if (this.enddate) {

            } else {
                this.$notify.error({
                    title: "错误",
                    message: '请选择日期',
                    duration: 1000,
                });
                return;
            };

            this.gettasktablelistdata();
        },

 
        async gettasktablelistdata(e) {
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let begindate = this.begindate;
            let enddate = this.enddate;
            let username = 'this.enddate';
            let depart = 'this.enddate';
            const { data: res } = await GETCOMPUTLOG({
                usercode,
                token,
                begindate,
                enddate,
                username,
                depart

            });
            //console.log(res);
            let tableData = [];
           let  mlist = JSON.parse(res);
         
            if (mlist.length != 0) {
 
                this.tableData = mlist[0].tablelist;
                this.tableheader=mlist[0].tableheader;
                console.log(this.tableData);
                console.log(this.tableheader);
            }
        }
    },
    mounted() {
 
        this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 10;
    }
};
</script>
 
<style lang='less' scoped>
 

</style>
<template>
    <div style="width: 100%;">
<div style="height: auto;width: auto;display: flex;justify-content: left;">
 
  <el-button @click="queryoauserlist" type="info" plain >刷新数据</el-button>
  <el-button v-if="checkvisible('新增')" type="primary" plain @click="showdialog">新增用户</el-button>
 
</div>
<div class="table-container">
<el-table
      :data="tableData"
      style="width: 100%"
    
      >
      <el-table-column
        prop="makedate"
        label="创建日期"
        width="100">
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户姓名"
        width="100">
      </el-table-column>
      <el-table-column
        prop="usercode"
        label="用户编号"
        width="100">
      </el-table-column>
      <el-table-column
        prop="depart"
        label="部门"
        width="100">
      </el-table-column>  
      <el-table-column
        prop="duty"
        label="职务"
        width="100">
      </el-table-column>      
      <el-table-column
        prop="rulename"
        label="角色名称"
        width="100">
      </el-table-column>  
      <!-- <el-table-column
        prop="isstop"
        label="是否停用"
        width="100">
      </el-table-column> -->
      <el-table-column label="操作" width="400">
        <template slot-scope="scope">
          <el-button v-if="checkvisible('修改')"
            size="mini"
            plain
            @click="handleEdit(scope.$index, scope.row)">修改</el-button>
          <el-button v-if="checkvisible('删除')"
            size="mini"
            type="danger"
            plain
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            <!-- <el-button
            size="mini"
            type="danger"
            @click="handlereject(scope.$index, scope.row)">{{scope.row.btntext}}</el-button>           -->
        </template>
      </el-table-column>
    </el-table>
  </div>
    <el-dialog title="用户信息" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="sizeForm" label-width="80px" size="mini">
        <i class="el-icon-warning" style="color: brown;font-size: 12px;">登录账号：默认为用户编号.  登录密码：默认为用户编号（登录后可以自行修改）</i>
  <div style="display: flex;margin-top: 5px;">
    <div style="width: 50%;">
  <el-form-item label="用户编号">
    <el-input v-model="sizeForm.usercode"></el-input>
  </el-form-item>
</div>
<div style="width: 50%;">
  <el-form-item label="用户名称">
    <el-input v-model="sizeForm.username"></el-input>
  </el-form-item>
</div>
</div>
<!-- <div style="display: flex;"> -->
    <div style="width: 50%;">
  <el-form-item label="所在部门">
    <el-input v-model="sizeForm.depart"></el-input>
  </el-form-item>
</div>
<div style="width: 50%;">
  <el-form-item label="所任职务">
    <el-input v-model="sizeForm.duty"></el-input>
  </el-form-item>
</div>
<div style="width: 50%;">
  <el-form-item label="权限角色">
    <el-select
    v-model="sizeForm.rule"
    clearable
    filterable
    allow-create
    default-first-option
    placeholder="请选择角色权限">
    <el-option
      v-for="item in mrulist"
      :key="item.mguid"
      :label="item.rulename"
      :value="item.mguid">
    </el-option>
  </el-select>
  </el-form-item>
</div>
<!-- </div> -->
 
</el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="restpwd">重置密码</el-button>
    <el-button type="primary" @click="saveuserinfo">保存</el-button>
    <!-- <el-button @click="dialogFormVisible = false">删除</el-button> -->
    <!-- <el-button type="primary" @click="dialogFormVisible = false">禁用</el-button>    -->
  </div>
</el-dialog>
    </div>
  </template>
  <script>
  import { saveOAuserinfo,queryOAuserinfolist,GETRULELIST } from "@/api";

  export default {
    data() {
      return {
        tableData: [],
        mrulist:[],
        dialogTableVisible: false,
        dialogFormVisible: false,
        sizeForm: {
          username: '',
          usercode: '',
          depart: '',
          duty: '',
          id:'0',
          mpassword:'',
          isstop:'',
          btntext:'',
          tableHeight: this.tableHeight,
          rule:''
        }
         
      };
    },
    computed: {
 
    },
    watch: {
     
    },
    mounted() { 
      this.queryoauserlist();
    //   this.$nextTick(() => {
    //   let h =
    //     window.innerHeight ||
    //     document.documentElement.clientHeight ||
    //     document.body.clientHeight;
    //   this.tableHeight = h - 120;
    // });
    },
    destroyed() {
      
    },
    created() {
      
    },
    methods: {
      checkvisible(btname){
             
           // console.log('menuData',this.$store.state.btnauthor)
           const menuData=this.$store.state.yonghuxinxi.find(v => v.bname === btname);
           //console.log('menuData',menuData)
           let mresult=false
           if(!menuData)
           mresult=false
          else
          mresult=menuData.isvisible
            return mresult
        },
      async queryrulelist(){
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let mmguid=this.$store.state.btypeinfo.email;
        const { data: res } = await GETRULELIST({
        usercode,
        token,
        mmguid
      });
      let ret=JSON.parse(res);
      //console.log('用户列表',ret)
      this.mrulist=[]
      if(!ret[0]){
        this.mrulist=[]
      }else
      this.mrulist = ret
      },
     async restpwd(){
        if(this.sizeForm.id!='0')
        {
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let id=this.sizeForm.id;
        let duty='重置密码';
      const { data: res } = await saveOAuserinfo({
        usercode,
        username:token,
        id,
        mpassword:'',
        depart:'',
        duty
      });
 
      const result = JSON.parse(res)[0];
      if (result.code !== 200) {
        this.$notify.error({
          title: "失败",
          message: result.message,
          duration: 1000,
        });
      } else {
        // this.$notify({
        //   title: "成功",
        //   message: result.message,
        //   type: "success",
        //   duration: 2000,
        // });
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        this.queryoauserlist();
      }  


        }else{
          this.$notify.error({
          title: "失败",
          message: '未查到到该用户信息！重置密码失败',
          duration: 1000,
        });
        };
      },
      showdialog(mid){
        this.sizeForm.id='0';
        this.sizeForm.username='';
        this.sizeForm.usercode='';
        this.sizeForm.depart='';
        this.sizeForm.duty='';
        this.sizeForm.isstop='启用';
        this.sizeForm.rule=''
        this.queryrulelist()
        this.dialogFormVisible=true;
      },
      async handlereject(index,item){
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let id=item.billid;
        let duty='停用';
      const { data: res } = await saveOAuserinfo({
        usercode,
        username:token,
        id,
        mpassword:'',
        depart:'',
        duty  
      });
 
      const result = JSON.parse(res)[0];
      if (result.code !== 200) {
        this.$notify.error({
          title: "失败",
          message: result.message,
          duration: 1000,
        });
      } else {
        // this.$notify({
        //   title: "成功",
        //   message: result.message,
        //   type: "success",
        //   duration: 2000,
        // });
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        this.queryoauserlist();
      }  
      },      
      async handleDelete(index,item){
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let mguid=this.$store.state.btypeinfo.email;
        let id=item.billid;
        let duty='删除';
      const { data: res } = await saveOAuserinfo({
        usercode,
        mguid,
        username:token,
        id,
        mpassword:'',
        depart:'',
        duty:'删除'  

      });
 
      const result = JSON.parse(res)[0];
      if (result.code !== 200) {
        this.$notify.error({
          title: "失败",
          message: result.message,
          duration: 1000,
        });
      } else {
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        this.queryoauserlist();
      }  
      },
      handleEdit(index,item){
        this.sizeForm.id=item.billid;
        this.sizeForm.username=item.username;
        this.sizeForm.usercode=item.usercode;
        this.sizeForm.depart=item.depart;
        this.sizeForm.duty=item.duty;
        this.sizeForm.isstop=item.isstop;
        this.queryrulelist()
        this.dialogFormVisible=true;
      },
      async queryoauserlist(){
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        let mguid=this.$store.state.btypeinfo.email;
        const { data: res } = await queryOAuserinfolist({
        usercode,
        token,
        mguid
      });
       
      this.tableData = JSON.parse(res);
      },
      async saveuserinfo(){
      let usercode = window.sessionStorage.getItem("usercode");
      let token = window.sessionStorage.getItem("token");
      let mguid=this.$store.state.btypeinfo.email;
      //console.log(this.sizeForm.rule)
      let rulemguid=this.sizeForm.rule 
      const { data: res } = await saveOAuserinfo({
        usercode:this.sizeForm.usercode,
        username:this.sizeForm.username,
        id: this.sizeForm.id,
        mpassword:this.sizeForm.usercode,
        depart:this.sizeForm.depart,
        duty:this.sizeForm.duty  ,
        mguid,
       rulemguid

      });
 
      const result = JSON.parse(res)[0];
      if (result.code !== 200) {
        this.$notify.error({
          title: "失败",
          message: result.message,
          duration: 1000,
        });
      } else {
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        this.queryoauserlist();
        this.dialogFormVisible=false; 
      }  
    },
       
      queryEventInGroup(){
  
      },
      queryParams(){
  
      },
      calculateDeadTime(val) {
          
      }
    } 
  };
  </script>
  <style lang='less' scoped>
  .table-container{
    height: calc(100%-200px);
  }
  </style>
<template>
  <div>
    <div class="navContainer">
      <div class="nav w clearfix">
        <div class="logo">
          <router-link to="/journalism"
            ><img src="./images/LOGO.png" alt=""
          /></router-link>
        </div>
        <div class="nav-menu">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            router
          >
            <el-menu-item index="/journalism">新闻资讯</el-menu-item>
            <el-menu-item index="/product">产品中心</el-menu-item>
            <el-menu-item index="/order">订单中心</el-menu-item>
            <el-menu-item index="/contact">联系我们</el-menu-item>
            <el-menu-item index="/issue" v-if="$store.state.isIssue"
              >新闻发布</el-menu-item
            >
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "/product",
    };
  },
  methods: {
    handleSelect(index, indexPath) {
      this.activeIndex = index;
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
  },
  // 检测路由变化
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },
  created() {
    this.setCurrentRoute();
  },
};
</script>
<style lang='less' scoped>
.navContainer {
  height: 150px;
  width: 100%;
  background-image: url(./images/bj.png);
  background-position: center;
  .nav {
    position: relative;
    .nav-menu {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
    .logo {
      float: left;
      height: 150px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
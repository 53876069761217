<template>
  <div>
    <div class="loginContainer">
      <div class="login">
        <div class="l-left">
          <img src="./images/ppp.png" alt="" />
        </div>
        <div class="l-right">
          <el-col>
            <el-card shadow="never">
              <div class="title">电子质保管控系统</div>
              <div class="h_login">
                欢迎登陆
                <span>LOGIN&nbsp;PLEASE</span>
              </div>
              <div class="inputContainer">
                <div class="input-item">
                  <label for="user"> 账户 </label>
                  <br />
                  <input
                    type="text"
                    name=""
                    id="user"
                    placeholder="请输入用户名"
                    v-model="user"
                  />
                </div>
                <div class="input-item">
                  <label for="pas"> 密码 </label>
                  <br />
                  <input
                    type="password"
                    name=""
                    id="pas"
                    placeholder="请输入密码"
                    v-model="pas"
                  />
                </div>
                <!-- <div class="input-item">
                  <label for="code"> 验证码 </label>
                  <br />
                  <div class="code">
                    <input
                      type="text"
                      name=""
                      id="code"
                      placeholder="请输入验证码"
                      v-model="code"
                    />
                    <canvas id="canvas" @click="canvasFlush"></canvas>
                  </div>
                </div> -->
                <div class="btn">
                  <button @click="login">立即登录</button>
                </div>
              </div>
            </el-card>
          </el-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login } from "@/api";
export default {
  data() {
    return {
      user: "",
      pas: "",
      code: "",
    };
  },
  methods: {
    canvasFlush() {
      this.code_draw();
    },
    async login() {
      const { user, pas, code } = this;
      const num = $("#canvas").attr("data-code");
      if (!user || !pas) {
        // this.$notify.error({
        //   title: "错误",
        //   message: "用户名或者密码不能为空!",
        //   duration: 1000,
        // });
        this.$message({
          message:"用户名或者密码不能为空!",
          type: 'error',
          duration:1000
        });
      }
      //  else if (!code) {
      //   this.$notify.error({
      //     title: "错误",
      //     message: "请输入验证码!",
      //     duration: 1000,
      //   });
      // } else if (code.toLowerCase() !== num) {
      //   this.$notify.error({
      //     title: "错误",
      //     message: "验证码输入错误!",
      //     duration: 1000,
      //   });
      //   this.code_draw();
      //   this.code = "";
      // } 
      else {
        const { data: res } = await Login({
          usercode: user,
          pwd: pas,
          token: "{06945D8F - 4605 - 4514 - A467 - D2BD9B65A797}",
        });
        let result = JSON.parse(res);
        if (result[0].code !== 200) {
 
          this.$message({
          message:result[0].message,
          type: 'error',
          duration:1000
        });
        } else {
 
          this.$message({
                    message: '已登录',
                    type: 'success',
                    duration: 1000
                });
          window.sessionStorage.setItem("token", result[0].mtoken);
          window.sessionStorage.setItem("usercode", user);
          this.$store.state.btypeinfo=[]
          this.$store.state.btnauthor=[],
          this.$store.state.chanpinxinxi=[],
          this.$store.state.biaoqianxinxi=[],
          this.$store.state.jihuoshenqing=[],
          this.$store.state.biaoqianchi=[],
          this.$store.state.gongsixinxi=[],
          this.$store.state.yonghuxinxi=[],
          this.$store.state.jiaoseguanli=[],
        
        this.$store.state.menulist=[],
          this.$router.push("/journalism");
          
        }
      }
    },
    // 生成二维码
    code_draw() {
      var canvas_width = $("#canvas").width();
      var canvas_height = $("#canvas").height();
      var canvas = document.getElementById("canvas"); //获取到canvas的对象，演员
      var context = canvas.getContext("2d"); //获取到canvas画图的环境，演员表演的舞台
      canvas.width = canvas_width;
      canvas.height = canvas_height;
      var sCode =
        "A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0"; //取值范围
      var aCode = sCode.split(",");
      var aLength = aCode.length; //获取到数组的长度
      var value = [];
      for (var i = 0; i <= 3; i++) {
        var j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        var deg = (Math.random() * 30 * Math.PI) / 180; //产生0~30之间的随机弧度
        var txt = aCode[j]; //得到随机的一个内容
        value[i] = txt.toLowerCase();
        var x = 10 + i * 20; //文字在canvas上的x坐标
        var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 23px 微软雅黑";

        context.translate(x, y);
        context.rotate(deg);

        context.fillStyle = this.code_randomColor();
        context.fillText(txt, 0, 0);

        context.rotate(-deg);
        context.translate(-x, -y);
      }
      // 将生成的值以属性的方法添加到元素
      value = value.join("");
      $("#canvas").attr("data-code", value);
      //验证码上显示线条
      for (var i = 0; i <= 5; i++) {
        context.strokeStyle = this.code_randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.lineTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.stroke();
      }
      //验证码上显示小点
      for (var i = 0; i <= 30; i++) {
        context.strokeStyle = this.code_randomColor();
        context.beginPath();
        var x = Math.random() * canvas_width;
        var y = Math.random() * canvas_height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }
    },
    code_randomColor() {
      //得到随机的颜色值
      var r = Math.floor(Math.random() * 256);
      var g = Math.floor(Math.random() * 256);
      var b = Math.floor(Math.random() * 256);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
  },
  mounted() {
    //this.code_draw();
    
  },
};
</script>
<style lang='less' scoped>
.loginContainer {
  width: 100%;
  height: 100vh;
  background-image: url(./images/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  .login {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .codeImgBOx {
      width: 60px;
      height: 28px;
      background: pink;
      position: absolute;
      top: 0;
      right: 0;
    }
    .l-left {
      height: 625px;
      img {
        height: 100%;
        width: 600px;
      }
    }
    .l-right {
      width: 500px;
      height: 625px;
      .el-col-24 {
        height: 100%;
      }
      .el-card {
        border: 0;
        height: 100%;
        border-bottom-left-radius: 0;
      }
      .title {
        padding-top: 60px;
        font-size: 26px;
        font-weight: 600;
        text-align: center;
      }
      .inputContainer {
        .btn {
          button {
            cursor: pointer;
            width: 280px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            border: 0;
            outline: none;
            background: #2385d4;
            color: #fff;
            font-size: 16px;
          }
          text-align: center;
          margin-top: 30px;
        }
        .input-item {
          margin-left: 100px;
          margin-bottom: 10px;
          .code {
            position: relative;
            canvas {
              position: absolute;
              right: 57px;
              background: #c0ccc2;
              width: 88px;
              height: 31px;
              border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;
            }
          }
          label {
            color: #535353;
            margin-bottom: 3px;
            display: inline-block;
            font-weight: 600;
            font-size: 16px;
          }
          input {
            border: 0;
            outline: none;
            border: 1px solid #dcdcdc;
            height: 30px;
            border-radius: 20px;
            text-indent: 10px;
            width: 298px;
          }
        }
      }
      .h_login {
        color: #bdbdbd;
        padding: 50px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
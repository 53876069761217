<template>
  <div style="width: 100%;height: 100%;overflow-x:hidden; display: flex; flex-direction: column;">
    <div v-if="false" class="header">
      <div class="clearfix w headerBox" style="width: 98%;">
        <div class="h-left" style="display: flex;align-items: center;justify-content: left;">
          <!-- <img style="width: 120px;height: 40px;" src="./images/huaya.png"/> -->
          <div @click="iscollbolean">
            <i class="el-icon-s-fold"></i>
          </div>
          <div style="margin-left: 20px;" :data-title="companyname" @click="nameclick">{{ companyname }}</div>
        
        </div>
      
        <div class="h-right">

          <div style="width: 100%;display:flex">

            <div style=" font-size: 12px;
            margin-left: 12px;
            color: #fff;
            font-weight: 400;">
              <i class="el-icon-s-custom"></i>
              {{ username }}【{{ depart }}】
            </div>

            <div style=" font-size: 12px;
            margin-left: 50px;
            color: #fff;
            font-weight: 400;" @click="dialogPassVisible = true">
              <i class="el-icon-unlock"></i>
              修改密码
            </div>

            <div style=" font-size: 12px;
            margin-left: 50px;
            color: #fff;
            font-weight: 400;">
              <i class="el-icon-s-tools"></i>
              <span style="margin-left:4px;" @click="loginoutChange">退出登录</span>
            </div>

          </div>
        </div>
      </div>
    </div>
     
    <!-- <div style="margin-bottom: 5px;background-color: blue;width: 100%;">
      xxx
    </div> -->
    <div style="width: 100%; flex: 1;display: flex; overflow: hidden;">

      <div   style="width: auto;height: auto;border-right: solid 1px #EBEEF5;">
        <!-- <div v-if="!collapse_bol" style="display: flex;height: 50px;align-items: center;">

        <div  style="display: flex;width: 100%;justify-content: center;">
        <span style="color: #606266;font-size: 14px;font-weight: 500;">新飞科技</span>
        </div>
       </div> -->
        <el-menu :default-active="activeMenu" class="el-menu-vertical-demo" :collapse="collapse_bol" :router="true"
          :default-openeds="openeds" @select="selectitem">
            <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>功能菜单</span>
            </template>  
            <el-menu-item  v-for="(item, index) in menulist" :index="item.url" :key="index">  
              
              <i class="el-icon-folder-opened" style="font-size: 18px;color: #909399;"></i>
            {{ item.title}}</el-menu-item>
         </el-submenu> 
        
        </el-menu>
      </div>
   
      <div
        style="flex: 1;height: 100%;background-color: rgb(255, 255, 255);box-sizing: border-box; padding: 10px; overflow: hidden;">
        <div style="height: 30px;width: 100%;display: flex;">
        <div style="width: 50%;display: flex;align-items: center;" >
          <i style="font-size: 20px;" :class="icofolder" @click="iscollbolean"></i>
           
          <i style="font-size: 22px;font-weight: 500;margin-left: 10px;">{{companyname}}</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 10px;color: #afb2b9;">有效期</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 3px;color:#67C23A;">{{stopdate}}</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 10px;color: #afb2b9;">剩余</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 3px;color:#67C23A;">{{stopday}}</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 3px;color: #afb2b9;">天</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 15px;color: #afb2b9;">剩余二维码数量：</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 3px;color: #67C23A;">{{bcount}}</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 15px;color: #afb2b9;">可建商品剩余数量：</i>
          <i style="font-size: 12px;font-weight: 500;margin-left: 3px;color:#67C23A;">{{pcount}}</i>
          <!-- <el-breadcrumb style="margin-left: 20px;" separator="/">
  <el-breadcrumb-item  v-for="(item,index) in breadcrumblist " :key="index"  >{{item.title}}</el-breadcrumb-item>
</el-breadcrumb> -->
        </div>
        <div style="width: 50%;display: flex;justify-content: right;align-items: center;">
          <el-avatar :size="40" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
          <!-- <i style="font-size: 20px;" class="el-icon-s-custom"></i> -->
          <i style="font-size: 12px;margin-left: 10px;cursor: pointer;"> {{ username }}</i>
          <div style=" font-size: 12px;
            margin-left: 20px;
            cursor: pointer;
            font-weight: 400;" @click="dialogPassVisible = true">
              <i class="el-icon-unlock"></i>
              修改密码
            </div>

            <div style=" font-size: 12px;
            margin-left: 20px;
             
            font-weight: 400;">
              <i class="el-icon-s-tools"></i>
              <span style="margin-left:4px;cursor: pointer;" @click="loginoutChange">退出登录</span>
            </div>
          <i style="font-size: 20px;margin-left: 20px;" class="el-icon-full-screen" @click="toggleFullScreen"></i>
          
        </div>

        </div>
        <el-tabs class="table-tabs" v-model="activeMenu" :stretch="false" closable @tab-remove="removeTab" type="card"
          @tab-click="tabsselect">
          <!-- <span
            class="circle"
            style="background: #faab00;"
          ></span> -->
          <el-tab-pane v-for="item in editableTabs" :key="item.url" :label="item.title" :name="item.url" >
            <!-- <span slot="label" class="activeMy">123456</span> -->
            <!-- <span slot="label" style="height: 1px ;width: 1px ;background-color: black;padding: 0px !important;border-radius: 50%;">&nbsp;</span> -->
           
            <div style="width: 100%;height: 100%;display: flex;justify-content: center; overflow: hidden;">
              <keep-alive>
                <!-- <router-view></router-view> -->
                <component :is="item.component"></component>
              </keep-alive>
            </div>
          </el-tab-pane>
        </el-tabs>
 
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogPassVisible">
      <div style="width: 100%;display: flex;align-items: center;">
        <div style="width: 20%;">新密码:</div>
        <div style="width: 60%;"><el-input v-model="npwd"></el-input></div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="chppd()">保存</el-button>
        <el-button @click="dialogPassVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
 
  </div>
</template>
<script>

import { loginout, getUserInfo, OACHANGEPWD,QPAGEAUTHLIST,QBUTONAUTHOR,getUserInfoss } from "@/api";
import ProductInfo from '../ProductInfo/index.vue';
import BarcodeList from '../BarcodeList/index.vue';
import ApplyOpenList from '../ApplyOpenList/index.vue';
import Nbarcodelist from '../Nbarcodelist/index.vue';
import Companeyinfo from '../Companeyinfo/index.vue';
import MuserInfo from '../MuserInfo/index.vue';
import Ruleauth from '../Ruleauth/index.vue';
export default {
  components: {
    ProductInfo,
    BarcodeList,
    ApplyOpenList,
    Nbarcodelist,
    Companeyinfo,
    MuserInfo,
    Ruleauth
  },
  data() {

    return {
      stopdate:'',
    pcount:'',
    bcount:'',
    stopday:'',
      righttile:true,
      screenHeight: document.documentElement.clientHeight, // 屏幕高度
      tableHeight: '',
      activeMenu: '/',
      menulist: [],
      collapse_bol: false,
      dialogTitleVisible: false,
      companyname: '',
      npwd: '',
      dialogPassVisible: false,
      author: false,
      index: 1,
      pageSize: 5,
      totalSize: null,
      list: [],
      listAll: [],
      openeds: ['1', '2'],
      username: '',
      depart: '',
      userData: [],
      duty: '',

      editableTabsValue: '1',
      editableTabs: [],
      tabIndex: 1,
      icofolder:'el-icon-s-fold'

    };
  },
  computed: {
    tabComponet () {
      const component = this.editableTabs.find(v => v.url === this.activeMenu)
      if (component) {
        return component.component
      } else {
        return ''
      }
    }
  },
  watch: {
    'screenHeight': function (val) { // 监听屏幕高度变化
      var oIframe = document.getElementById('maindiv')
      if(oIframe){
        oIframe.style.height = (Number(val) - 120) + 'px'
      }
      
    }
  },
  methods: {
    toggleFullScreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  },
    tabsselect(item) {
      this.activeMenu = item.name;
  
      this.$router.push(item.name);
    },
    selectitem(item) {
      this.activeMenu = item
      const menuData = this.menulist.find(v => v.url === item);
      this.getbtnauthor(menuData.title);
      const tab = this.editableTabs.find(v => v.url === item);
      if (this.editableTabs.length <= 0 || !tab) {
        this.editableTabs.push(menuData)
      }
     // console.log(this.editableTabs)
    },

    iscollbolean() {
      if (this.collapse_bol) {
        this.icofolder='el-icon-s-fold'
        this.collapse_bol = false;
      } else {
        this.collapse_bol = true;
        this.icofolder='el-icon-s-unfold'
      }

    },
 
    nameclick(e) {
      this.dialogTitleVisible = true;
 
    },
    clicktabletitle(targetName) {
      this.$router.push(this.editableTabs[targetName.index].pageurl);

    },
    addTab(targetName, url) {
      let newTabName = ++this.tabIndex + ''
      this.editableTabs.push({
        title: targetName,
        name: newTabName,
        content: '',
        url: url
      })
      this.editableTabsValue = newTabName;
      this.$router.push(url, { params: { flag: '2' } });

    },
    removeTab(tabPath) {
 
      const tabIndex = this.editableTabs.findIndex(v => v.url === tabPath);
      // 如果只剩下一个tab则禁止删除
      if (this.editableTabs.length <= 1) {
        return this.$message.warning('当前仅剩一个标签页，禁止删除！')
      }
      if (tabIndex >= 0) {
        this.editableTabs.splice(tabIndex, 1);
      }
      if (this.activeMenu === tabPath) {
        let nextPath = this.editableTabs[tabIndex - 1] || this.editableTabs[tabIndex + 1] || this.editableTabs[tabIndex];
        // this.tabsselect({...this.editableTabs[nextPath], name: this.editableTabs[nextPath].url})
        this.activeMenu = nextPath.url
      }
    },

    // 登出
    async loginoutChange() {
      let usercode = window.sessionStorage.getItem("usercode");
      let token = window.sessionStorage.getItem("token");
      const { data: res } = await loginout({ usercode, token });
      let result = JSON.parse(res)[0];

      if (result.code !== 200) {
 
        this.$message({
          message: "退出失败,请重试!",
          type: 'error',
          duration: 1000
        });
      } else {
 
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        window.sessionStorage.removeItem("usercode");
        window.sessionStorage.removeItem("token");
        this.$store.commit("isIssueChange", false);
        this.$router.push("/login");
      }
    },


    async chppd() {
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      const { data: res } = await OACHANGEPWD({
        usercode,
        token,
        pwd: this.npwd
      });

      let result = JSON.parse(res)[0];

      if (result.code !== 200) {
 
        this.$message({
          message: result.message,
          type: 'error',
          duration: 1000
        });

      } else {
 
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        this.dialogPassVisible = false;
        this.npwd = '';
      }
    },
async getbtnauthor(btnname){
  //console.log('btncaption',btnname)
  if (btnname=='产品信息'){
 if(this.$store.state.chanpinxinxi.length>0) return
}
if (btnname=='标签信息'){
  if(this.$store.state.biaoqianxinxi.length>0) return
  
}
if (btnname=='激活申请'){
  if(this.$store.state.jihuoshenqing.length>0) return
   
}
if (btnname=='标签池'){
  if(this.$store.state.biaoqianchi.length>0) return
  
}
if (btnname=='公司信息'){
  if(this.$store.state.gongsixinxi.length>0) return
   
}
if (btnname=='用户信息'){
  if(this.$store.state.yonghuxinxi.length>0) return
 
}
if (btnname=='角色管理'){
  if(this.$store.state.jiaoseguanli.length>0) return
  
}
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      let mguid=this.$store.state.btypeinfo.email;
      let bname=btnname
      const { data: res } = await QBUTONAUTHOR({
        usercode,
        token,
        mguid,
        bname
      });
    
    let ret=JSON.parse(res);
   //console.log('页面按钮权限',ret);
    if (!ret[0]){
this.$store.state.btnauthor=[]
if (btnname=='产品信息'){
  this.$store.state.chanpinxinxi=[]
}
if (btnname=='标签信息'){
  this.$store.state.biaoqianxinxi=[]
}
if (btnname=='激活申请'){
  this.$store.state.jihuoshenqing=[]
}
if (btnname=='标签池'){
  this.$store.state.biaoqianchi=[]
}
if (btnname=='公司信息'){
  this.$store.state.gongsixinxi=[]
}
if (btnname=='用户信息'){
  this.$store.state.yonghuxinxi=[]
}
if (btnname=='角色管理'){
  this.$store.state.jiaoseguanli=[]
}
    }else
    {
if (btnname=='产品信息'){
  this.$store.state.chanpinxinxi=ret 
}
if (btnname=='标签信息'){
  this.$store.state.biaoqianxinxi=ret 
}
if (btnname=='激活申请'){
  this.$store.state.jihuoshenqing=ret 
}
if (btnname=='标签池'){
  this.$store.state.biaoqianchi=ret 
}
if (btnname=='公司信息'){
  this.$store.state.gongsixinxi=ret 
}
if (btnname=='用户信息'){
  this.$store.state.yonghuxinxi=ret 
}
if (btnname=='角色管理'){
  this.$store.state.jiaoseguanli=ret 
}
     // this.$store.state.btnauthor.push({btnname:ret});
     // console.log(this.$store.state.btnauthor)
      //this.$store.state.btnauthor=ret    
    }
},
async getpagelist(){
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      let mguid=this.$store.state.btypeinfo.email;
      const { data: res } = await QPAGEAUTHLIST({
        usercode,
        token,
        mguid
      });
    
    let ret=JSON.parse(res);
    //console.log('页面权限',ret);
    if (!ret[0]){
this.menulist=[]
    }else
    {
      this.menulist=ret;
      this.$router.push(ret[0].url)
      this.activeMenu = this.$route.path  
     const currMenu = this.menulist.find(v => v.url === this.$route.path)
     //console.log('xxxxx',currMenu)
     this.getbtnauthor(currMenu.title);
     this.editableTabs = [currMenu]
 //this.router.push(ret[0].url)
    }
},


    async userInfo() {
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      const { data: res } = await getUserInfo({
        usercode,
        token,
      });

      this.userData = JSON.parse(res)[0];
      
      if (this.userData) {
        //  console.log(this.userData)
        this.$store.state.btypeinfo = this.userData;
        this.username = this.$store.state.btypeinfo.bfullname;
        this.depart = this.$store.state.btypeinfo.baddress;
        this.duty = this.$store.state.btypeinfo.bphone;
        this.companyname=this.$store.state.btypeinfo.bperson;
        this.pcount=this.$store.state.btypeinfo.bankname;
        this.bcount=this.$store.state.btypeinfo.taxnum;
        this.stopday=this.$store.state.btypeinfo.bankaccount;
        this.stopdate=this.$store.state.btypeinfo.cwperson
;
       //console.log(this.username,this.depart)
      
        this.getpagelist()
        if (this.userData.bfullname === "系统管理员") {
  
          this.author = true
          this.$store.commit("isIssueChange", true);
 
        } else {
          this.author = false;
          this.$store.commit("isIssueChange", false);
        }
        
      }
    },
    async refrashuserInfo() {
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      const { data: res } = await getUserInfoss({
        usercode,
        token,
      });

     let ret = JSON.parse(res)[0];   
      if (ret) {
        this.pcount=ret.bankname;
        this.bcount=ret.taxnum;
        this.stopday=ret.bankaccount;
        this.stopdate=ret.cwperson
      }
    },
  },
  mounted() {
 
   let timer = setInterval(() => {
     this.refrashuserInfo()
},50000)
    this.userInfo();
    //this.selectitem(this.menulist[0])
    var _this = this
    window.onresize = function () { // 定义窗口大小变更通知事件
      _this.screenHeight = document.documentElement.clientHeight // 窗口高度
    }
  },
};
</script>
<style lang='less' scoped>
// ::v-deep .el-tabs__nav {

//     height: 20px;
//    margin-top: 10px;
// }
// ::v-deep .el-tabs__item{
// height: 25px;
// }
.circle {
  position: absolute;
  top: 17px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.activeMy{
  background-color: black;
  border-radius: 50%;
 
  // display: inline;
}
::v-deep .el-tabs__item {
    padding: 0 10px;
    height: 25px;
    box-sizing: border-box;
    line-height: 25px;
    width: max-content;
  }
::v-deep el-tabs__header{
  height: 25px;
}
::v-deep el-tabs__item:hover {
  color: rgb(250, 250, 250);
  background-color: #409EFF;
  //border-radius:10px;
}
::v-deep .el-tabs__item.is-active {
    color: rgb(255, 255, 255);
    background-color: #409EFF;
   // border-radius:5px;
}


.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-tabs__item:hover {
  color: orange;
  background-color: orange;
}

.el-tabs__item.is-active {
  color: orange;
  background-color: orange;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px !important;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.tac {
  width: 100%;
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 100px !important;
}

.journalismContainer {
  margin-top: 10px;

  .paginationBox {
    .block {
      margin-top: 20px;
      float: right;
      width: 100%;
      text-align: center;
      margin-bottom: 180px;
    }
  }

  .journalism {
    width: 1000px;
    margin: 0 auto;

    .imgBox {
      img {
        width: 250px;
        height: 140px;
        object-fit: cover;
      }
    }

    ul {
      margin-top: 20px;
    }

    ul li {
      display: flex;
      justify-content: space-between;
      padding: 25px 0;
      border-bottom: 2px solid #cccccc;

      .moreBox {
        cursor: pointer;
        display: flex;
        align-items: center;

        .more {
          border: 1px solid #8f8f8f;
          border-radius: 50%;
          padding: 10px;
          display: flex;
          align-items: center;

          .el-icon-arrow-down {
            font-size: 16px;
          }
        }
      }

      .Date {
        padding-top: 20px;
        display: flex;
        flex-direction: column;

        .month {
          font-size: 18px;
          color: #989898;
        }

        .year {
          margin-top: 5px;
          color: #8d8d8d;
          font-size: 14px;
          font-style: oblique;
        }
      }

      .content {
        width: 50%;

        .title {
          cursor: pointer;
          color: #333333;
          font-size: 18px;
          font-weight: 500;
        }

        .title:hover {
          color: #c44f52;
        }

        .details {
          margin-top: 15px;
          color: #999999;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

.header {
  height: 50px;
  background: #14aafb;
  line-height: 50px;

  .yfmoneyTitle {
    margin-left: 25px;
    color: #f6f6f6;
    font-size: 16px;

    .yfmoney {
      color: #e4040b;
    }
  }

  .headerBox {
    height: 55px;

    .shop-btn {
      float: left;
    }
  }

  .h-left {
    float: left;
    height: 100%;
    color: #eeecec;
    font-size: 20px;
    width: 30%;
  }

  .userImg_1 {
    // border-radius: 100%;
    // overflow: hidden;
    width: 20px;
    height: 20px;

  }

  .userImg_user {
    // border-radius: 100%;
    overflow: hidden;
    width: 120px;
    height: 40px;

  }

  .userImg_user image {

    height: 100%;
  }

  .h-right {
    width: 50%;
    float: right;
    height: 100%;

    .userInfo {
      align-items: center;
      display: flex;

      .userImg {
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name {
        margin: 0 10px;
        font-size: 18px;
      }

      .shop-btn {
        margin-left: 25px;
        width: 120px;

        .shopBtn {
          display: flex;
          width: 120px;
          height: 32px;
          align-items: center;
          justify-content: center;
          background: #f20d14;
          border-radius: 4px;
          cursor: pointer;
          line-height: 12px;

          img {
            margin-right: 3px;
          }

          span {
            font-size: 17px;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.table-tabs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /deep/ .el-tabs__content {
    flex: 1;
    width: 100%;
    overflow: hidden;

    .el-tab-pane {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/element.css'
// 格式化金钱全局函数
import money from './assets/changeNumMoneyToChinese'
import changetreedata from './assets/changeNumMoneyToChinese'
Vue.use(money)
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
  
// 打印
import Print from 'vue-print-nb'
//import Print from './plugins/print';
Vue.use(Print)
import Vue2OrgTree from 'vue-tree-color'
Vue.use(Vue2OrgTree)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        btnauthor:[],
        chanpinxinxi:[],
        biaoqianxinxi:[],
        jihuoshenqing:[],
        biaoqianchi:[],
        gongsixinxi:[],
        yonghuxinxi:[],
        jiaoseguanli:[],
        //主页面权限菜单列表
        menulist:[],
        // 购物车数量
        shopNum: 0,
        // 购物车
        shopList: [],
        // 新闻发布管理员权限
        isIssue: false,
        // 保存购物车订单单号
        numberOdd: '',
        index: 0,
        menuname:'35系列',
        btypeinfo:[],
        flag:'',
        companyname:'',
        mguid:''
    },
    mutations: {
        changeIndex(state, i) {
            state.index = i
        },
        changeIndexReset(state) {
            state.index = 0
        },
        changeNumberOdd(state, odd) {
            state.numberOdd = odd
        },
        changeShopNum(state, num) {
                state.shopNum += num
        },
        shopNumReset(state) {
            state.shopNum = 0
        },
        shopListAddArr(state, arr) {
            //console.log('传过来的订单明细',arr);
            state.shopList = arr
        },
        shopListReset(state) {
            state.shopList = []
        },
        shopListRemove(state, id) {
            for (let i in state.shopList) {
                if (state.shopList[i].ptypeid == id) {
                    state.shopList.splice(i, 1);
                }
            };
            state.shopNum=state.shopList.length;
        },
        shopListIsShop(state, row) {
           // console.log('新添加的row',row);
            if (state.shopList.length == 0) {
                state.shopList.push(row)
            } else {
                let foodIndex = state.shopList.findIndex(food => food.ptypeid == row.ptypeid);
                if (foodIndex === -1) {
                    state.shopList.push(row)
                } else {
                    let shopList = JSON.parse(JSON.stringify(state.shopList))
                    shopList[foodIndex].qty += row.qty
                    state.shopList = shopList
                }
            };
            state.shopNum=state.shopList.length;      

        },
        shopListEmpty(state) {
            state.shopList = [];
            state.shopNum=0;
            console.log('我清空了一次');
        },
        isIssueChange(state, flag) {
            state.isIssue = flag
        }
    },
    actions: {},
    getters: {
        shopList_get: state => state.shopList,
    }
})
<template>
    <div>
        <h2>测试动态表单</h2>
      <el-form :model="fieldObj" ref="ruleForm" label-width="180px" class="demo-ruleForm">
        <template v-for="(item,index) of fieldArray">
          <template v-if="item.htmlElements==='文本'">
            <el-form-item :label="item.showName"height="auto">
              <el-input v-model="fieldObj[item.showName]"  :rows="1" :placeholder="item.desc"  type="textarea" autosize></el-input>
            </el-form-item>
          </template>
          <template v-if="item.htmlElements==='文本域'">
            <el-form-item :label="item.showName">
              <el-input type="textarea" rows="4" :placeholder="item.desc" v-model="fieldObj[item.showName]" :maxlength="item.fieldLength" show-word-limit></el-input>
            </el-form-item>
          </template>
          <template v-if="item.htmlElements==='图片'">
            <el-form-item :label="item.showName">
                <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
            </el-form-item>
          </template>         
          <template v-if="item.htmlElements==='日期'">
            <el-form-item :prop="item.showName" :label="item.showName">
              <el-date-picker v-model="fieldObj[item.showName]" :name="item.showName" type="date"
                              format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                              :placeholder="item.desc"
              ></el-date-picker>
            </el-form-item>
          </template>
          <template v-if="item.htmlElements==='下拉框'">
            <el-form-item  :label="item.showName">
              <el-select v-model="fieldObj[item.showName]" :placeholder="item.describe">
                <el-option
                  v-for="items in job"
                  :key="items.name"
                  :label="items.name"
                  :value="items.value">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="item.htmlElements==='列表'">
            <el-form-item  :label="item.showName">
              <template v-for="(child,index) in item.itemlist">
                <el-radio v-model="fieldObj[item.showName]" :label="child.value" border>{{child.name}}</el-radio>
              </template>
            </el-form-item>
          </template>
          
 
        </template>
      </el-form>
    </div>
</template>

<script>
  import axios from 'axios'
    export default {
        name: "Test",
      data(){
          return{
            fieldArray:[],// 表单字段集合
            fieldObj:{},
 
            job:[{  // 职业
                name:"医生",
                value:"doctor"
              },{
                name:"老师",
                value:"teacher"
              },{
                name:"司机",
                value:"driver"
            }
            ],
           fielddata:[
           {
        "showName": "文件上传",
        "showValue": null,
        "htmlElements": "图片",
        "requiredOrNot": 1
      },
           {
        "showName": "爱好",
        "showValue": null,
        "htmlElements": "复选框",
        "requiredOrNot": 1,
        "desc":"请选择爱好"
      },
      {
        "showName": "姓名",
        "showValue": null,
        "htmlElements": "输入框",
        "fieldLength": 10,
        "requiredOrNot": 1,
        "desc":"请输入姓名"
      },
      {
        "showName": "描述",
        "showValue": null,
        "htmlElements": "文本域",
        "fieldLength": 99,
        "requiredOrNot": 1,
        "desc":"请输入描述"
      },

      {
        "showName": "性别",
        "showValue": "female",
        "htmlElements": "列表",
        "requiredOrNot": 1,
        "itemlist":[{    // 性别
                name:'男',
                value:"male"
              },{
                name:"女",
                value:"female"
            }
            ]
      },
      {
        "showName": "出生日期",
        "showValue": "2023-09-09",
        "htmlElements": "日历控件",
        "requiredOrNot": 1,
        "desc":"请选择出生日期"
      },
      {
        "showName": "结婚时间",
        "showValue": null,
        "htmlElements": "日历控件",
        "requiredOrNot": 1,
        "desc":"请选择结婚时间"
      },
      {
        "showName": "停工时间",
        "showValue": null,
        "htmlElements": "日历控件",
        "requiredOrNot": 1,
        "desc":"请选择结婚时间"
      },
      {
        "showName": "职业",
        "showValue": null,
        "htmlElements": "下拉框",
        "requiredOrNot": 1,
        "desc":"请选择职业"
      }
    ]

          }
      },
      mounted(){
        this.getFieldData();
      },
      methods:{
          getFieldData(){ // 获取动态表单数据
            this.fieldArray=this.fielddata;
              for(let i=0;i<this.fielddata.length;i++){
                let item=this.fielddata[i];
                if(item.htmlElements==='复选框'){
                  this.$set(this.fieldObj,item.showName,[]);
                }else {
                  this.$set(this.fieldObj,item.showName,item.showValue);
                }
              };
              return;

            axios.get("../../pages/DFormSet/form.json").then(data=>{
              let response=data.data.data;
              this.fieldArray=response;
              for(let i=0;i<response.length;i++){
                let item=response[i];
                if(item.htmlElements==='复选框'){
                  this.$set(this.fieldObj,item.showName,[]);
                }else {
                  this.$set(this.fieldObj,item.showName,item.showValue);
                }
              }
            })
          }
      }
    }
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="pwdContainer">
      <div class="pwd">
        <div class="title">修改密码</div>
        <div class="inputContainer">
          <div class="inputItem">
            <label for="inPass"> 原密码</label>
            <input
              type="password"
              id="inPass"
              v-model="inPass"
              placeholder="请输入原密码"
            />
          </div>
          <div class="inputItem">
            <label for="Pass"> 新密码</label>
            <input
              type="password"
              id="Pass"
              v-model="pass"
              placeholder="请输入新密码"
            />
          </div>
          <div class="inputItem">
            <label for="checkPass"> 新密码</label>
            <input
              type="password"
              id="checkPass"
              v-model="checkPass"
              placeholder="请再次输入新密码"
            />
          </div>
          <div class="btn">
            <button @click="preserveChange">保存</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changepwd } from "@/api";
export default {
  data() {
    return {
      inPass: "",
      pass: "",
      checkPass: "",
    };
  },
  methods: {
    async preserveChange() {
      let { inPass, pass, checkPass } = this;
      if (!inPass) {
        this.$notify.error({
          title: "错误",
          message: "原密码不能为空!",
          duration: 1000,
        });
      } else if (pass !== checkPass) {
        this.$notify.error({
          title: "错误",
          message: "两次输入密码不一致!",
          duration: 1000,
        });
      } else {
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        const { data: res } = await changepwd({
          usercode,
          token,
          pwd: checkPass,
          oldpwd: inPass,
        });
        let result = JSON.parse(res)[0];
        if (result.code !== 200) {
          this.$notify.error({
            title: "错误",
            message: result.message,
            duration: 1000,
          });
        } else {
          // this.$notify({
          //   title: "成功",
          //   message: result.message,
          //   type: "success",
          // });
          this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
          this.inPass = "";
          this.pass = "";
          this.checkPass = "";
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
.pwdContainer {
  .pwd {
    width: 700px;
    margin: 0 auto;
    border: 1px solid #dbdbdb;
  }
  margin-top: 30px;
  .title {
    text-align: center;
    line-height: 30px;
    height: 30px;
    background: #f3f4f6;
    border-bottom: 1px solid #dbdbdb;
  }
  .inputContainer {
    padding-top: 20px;
    width: 240px;
    margin: 0 auto;
    .btn {
      margin-top: 40px;
      text-align: center;
      padding-bottom: 80px;
      button {
        width: 110px;
        height: 30px;
        background: #e4040c;
        color: #fff;
      }
    }
    .inputItem {
      margin-bottom: 20px;
      border: 1px solid #e6e6e6;
      input {
        text-indent: 10px;
        width: 69%;
        height: 100%;
      }
      label {
        width: 30%;
        height: 25px;
        text-align: center;
        line-height: 25px;
        display: inline-block;
        background: #f3f4f6;
        border-right: 1px solid #e6e6e6;
      }
    }
  }
}
</style>
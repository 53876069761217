<template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;overflow: hidden;">
        <div class="table-wrapper" v-if="isdetail"
            style="width: 100%; display: flex; flex-direction: column; overflow: hidden;">
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: auto;display: flex;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="日期范围">
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="产品名称">
                        <el-input style="width: 200px;" placeholder="关键字检索" v-model="chanpinleibie"></el-input>
                    </el-form-item>

                    <el-form-item style="margin-left: 10px;">
                        <el-button type="primary" plain icon="el-icon-search" @click="refrashdate">搜索</el-button>
                    </el-form-item>
                    <el-form-item v-if="checkvisible('新增')">
                        <el-button style="margin-left:60px" type="primary" plain icon="el-icon-folder-add"
                            @click="addptypeinfo">新增</el-button>
                    </el-form-item>
                    <el-form-item v-if="checkvisible('删除')">
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="delbiaoqianchi">删除</el-button>
                    </el-form-item>
                    <el-form-item v-if="checkvisible('导出')">
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="mexportexcel">导出</el-button>
                    </el-form-item>
                    <el-form-item v-if="checkvisible('匹配商品')">
                        <el-button type="success" size="mini" @click="resetscan">
                            开启扫描
                            <i class="el-icon-full-screen"></i>
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item style="font-size: 10px;color: red;" label="编码规则">
                        <el-select v-model="sbarvalue" filterable placeholder="请选择" clearable style="width: 260px;">
    <el-option
      v-for="item in barruleitemlist"
      :key="item.value"
      :label="item.name"
      :value="item.value"
      >
    </el-option>
  </el-select>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="起始流水">
                        <el-input style="width: 100px;" type="number"  v-model="beginnum"></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="截止流水">
                        <el-input style="width: 100px;" type="number" v-model="endnum"></el-input>
                    </el-form-item> 
                </el-form>
            </div>
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: 20px;display: flex;justify-content:right;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(2)" :class="isquanbu ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">全部</span>
                        </div>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(4)" :class="isdaijihuo ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">显示中</span>
                        </div>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(3)" :class="iszhibao ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">隐藏中</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>

            <div style="width: 100%; flex: 1; overflow: hidden;">
                <el-table v-loading="loading" element-loading-text="数据正在加载中..." size="small" :data="orderListAll"
                    style="width: 100%" height="100%" class="table" ref="table"
                    :header-cell-style="{ background: 'rgb(251, 251, 251)', color: '#303133' }" border
                    @selection-change="handleSelectionChange" :selectable="row => row.enabled !== false">
                    <!-- <el-table-column type="selection">

                </el-table-column> -->
                    <el-table-column fixed type="selection" width="35">

                    </el-table-column>
                    <el-table-column fixed label="" width="50">
                        <template slot-scope="scope">
                            <p>{{ scope.$index + 1 }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column sortable fixed prop="makedate" label="建立日期" width="120">
                    </el-table-column>
                    <el-table-column sortable fixed prop="listname" label="产品" width="100">
                    </el-table-column>
                    <el-table-column sortable prop="barrule" label="编码规则" width="280">
                    </el-table-column>
                    <el-table-column sortable prop="minlsnum" label="起始流水" width="180">
                    </el-table-column>
                    <el-table-column sortable prop="maxlsnum" label="结束流水" width="180">
                    </el-table-column>
                    <el-table-column sortable prop="listcount" label="总数量" width="140">
                    </el-table-column>
                    <el-table-column sortable prop="usedcount" label="已使用数量" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="levcount" label="剩余数量" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="barcoderule" style="color: red;" label="状态" width="70">
                        <template slot-scope="scope">
                            <el-switch @change="displaychangeisstop(scope.row)" style="font-size: 10px;"
                                v-model="scope.row.isdisplay" active-color="#909399" inactive-color="#13ce66" active-text=""
                                inactive-text="">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="chakan(scope.$index, scope.row)" type="text" size="small">
                                打开条码
                            </el-button>
                            <el-button v-if="checkvisible('新增')" @click.native.prevent="copyadd(scope.$index, scope.row)" type="text" size="small">
                                复制新增
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="display: flex;justify-content: center;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="index"
                    :page-sizes="[50, 100, 200]" :page-size="pageSize" layout="prev, pager, next, jumper, sizes , total"
                    :total="totalSize" prev-text="上一页" next-text="下一页">
                </el-pagination>
            </div>
        </div>
        <div class="table-wrapper" v-if="!isdetail"
            style="width: 100%; display: flex; flex-direction: column; overflow: hidden;">
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: auto;display: flex;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item style="font-size: 10px;color: red;" label="编码">
                        <el-input style="width: 200px;" placeholder="关键字检索" v-model="form_detail.barcode"></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="编码流水">
                        <el-input style="width: 80px;" type="text" v-model="form_detail.beginnum"></el-input>

                        <span>-</span>

                        <el-input style="width: 80px;" type="text" v-model="form_detail.endnum"></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="内码">
                        <el-input style="width: 200px;" placeholder="关键字检索" v-model="form_detail.mguid"></el-input>
                    </el-form-item>

                    <el-form-item style="margin-left: 10px;">
                        <el-button type="primary" round icon="el-icon-search"
                            @click="gettasktablelistdata_detail">搜索</el-button>
                    </el-form-item>
                    <el-form-item v-if="checkvisible('匹配商品')">
                        <el-button style="margin: left 1px;0px" type="primary" round icon="el-icon-folder-add"
                            @click="comparesumlist">匹配商品</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" round icon="el-icon-folder-add" @click="exportexcel">导出</el-button>
                    </el-form-item>
                    <!-- <el-form-item>
                        <el-button type="text" size="mini" @click="closeSearch">
                            {{ wordcaption }}
                            <i :class="showAll ? 'el-icon-arrow-up ' : 'el-icon-arrow-down'"></i>
                        </el-button>     
                    </el-form-item>    -->
                    <el-form-item v-if="checkvisible('匹配商品')">
                        <el-button type="success" size="mini" @click="resetscan">
                            开启扫描
                            <i class="el-icon-full-screen"></i>
                        </el-button>

                        <!-- <i  style="margin-left:30px;color:#409EFF" class="el-icon-d-arrow-right"></i>
                         <div @click="reback()" style="font-size:10px;color:#409EFF">返回上一级</div> -->
                        <el-button type="text" size="mini" @click="reback">
                            返回上一级
                            <i class="el-icon-d-arrow-right"></i>
                        </el-button>
                    </el-form-item>
                </el-form>

            </div>
            <div style="display:flex;justify-content:right;item-align:center;">
                <span style="font-size:10px;color:#606266;">注：不选中信息，可全部导出/匹配产品。选中信息，可选中信息导出/匹配产品。</span>
            </div>
            <div style="width: 100%; flex: 1; overflow: hidden;">
                <el-table v-loading="loading_detail" element-loading-text="数据正在加载中..." size="small"
                    :data="orderListAll_detial" style="width: 100%" height="100%" class="table" ref="table"
                    :header-cell-style="{ background: 'rgb(251, 251, 251)', color: '#303133' }" border
                    @selection-change="handleSelectionChange_detail" :selectable="row => row.enabled !== false">
                    <el-table-column fixed type="selection" width="35">

                    </el-table-column>
                    <el-table-column fixed label="" width="50">
                        <template slot-scope="scope">
                            <p>{{ scope.$index + 1 }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column sortable fixed prop="listname" label="产品" width="180">
                    </el-table-column>
                    <el-table-column sortable prop="barcode" label="编码" width="220">
                    </el-table-column>
                    <el-table-column sortable prop="mguid" label="内码" width="420">
                        <template slot-scope="scope">
                            <span>{{ scope.row.mguid }} </span>
                            <i style="margin-left: 10px;" class="el-icon-tickets"
                                v-clipboard:copy="'https://qa.xinfei028.com/c/?id=' + scope.row.mguid.toString().replace(/-/g, '')"
                                v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
                            <!-- <el-tag style="margin-left:10px" size="mini" closable>点击复制</el-tag> -->

                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="display: flex;justify-content: center;">
                <el-pagination @size-change="handleSizeChange_detail" @current-change="handleCurrentChange_detail"
                    :current-page="index_detail" :page-sizes="[50, 100, 200, 500]" :page-size="pageSize_detail"
                    layout="prev, pager, next, jumper, sizes , total" :total="totalSize_detail" prev-text="上一页"
                    next-text="下一页">
                </el-pagination>
            </div>
        </div>


        <el-dialog title="详情编辑" :visible.sync="dialog_taskinfo" width="60%" :close-on-click-modal="false"
            :destroy-on-close="true">

            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品</div>
                <div style="width: 80%;"><el-input v-model="formdata.prodname" placeholder=""></el-input></div>
            </div>

            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">编码规则
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 80%;display: flex;align-items: center;">
                    <div style="width: 40%;"><el-input v-model="formdata.barcoderule" placeholder=""></el-input></div>
                    <div style="width: 60%;display: flex;margin-left:10px">
                        <i style="margin-left: 2px;" class="el-icon-tickets" v-clipboard:copy="'[固定字段]+[年]+[月]+[日]+[3位流水]'"
                            v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
                        <p style="font-size: 10px;color: #303133;margin-left: 5px;">[</p>
                        <p style="font-size: 10px;color: #F56C6C;">固定字段</p>
                        <p style="font-size: 10px;color: #303133;">]+[年]+[月]+[日]+[</p>
                        <p style="font-size: 10px;color: #F56C6C;">3</p>
                        <p style="font-size: 10px;color: #303133;">位流水]</p>
                        <p style="font-size: 10px;color: #606266;margin-left: 6px;">以上部分可随意组合，红色字体内容可自定义</p>
                    </div>
                </div>
            </div>

            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">生成数量</div>
                <div style="width: 40%;"><el-input type="number" v-model="formdata.yxdays" placeholder=""></el-input></div>
                <span style="color: #F56C6C;font-size: 12px;display: flex;align-items: center;">一次最多生成条码500条</span>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveformdata(0)">保存</el-button>
                <el-button @click="dialog_taskinfo = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="条码扫描" :visible.sync="dialog_scan" width="80%" :close-on-click-modal="false"
            :destroy-on-close="true" fullscreen>
            <div style="width: 100%;display: flex;margin-top: 3px;item-align:center;">
                <div style="width: 5%;item-align:center;display:flex;">
                    <span style="display: flex;align-items: center;item-align:center;">匹配商品</span>

                </div>
                <div style="width: 20%;">
                    <el-input @click.native="searchprodlist" readonly suffix-icon="el-icon-search"
                        v-model="prodcode"></el-input>
                </div>
                <div style="width: 5%;display:flex;align-items: center;margin-left: 20px;">扫描框</div>
                <div style="width: 30%;">
                    <el-input style="" ref="scaninput" autofocus="autofocus"
                        @keydown.enter.native="courierNumberEnterDown()" @keyup.enter.native="courierNumberEnterUp()"
                        type="textarea" autosize v-model="neimabianhao"></el-input>
                </div>
                <div style="width: 5%;item-align:center;display:flex;margin-left: 5px;">
                    <span style="display: flex;align-items: center;item-align:center;">出厂日期</span>

                </div>
                <div style="width: 30%;margin-left: 5px;">
                    <el-date-picker v-model="chuchangdate" type="date" placeholder="选择日期">
                    </el-date-picker>
                </div>

                <div style="width:30%;margin-left: 50px;display:flex;item-align:center;justify-content:right;">
                    <el-button size="mini" type="warning" @click="tijiaopipei">匹配提交</el-button>
                    <el-button size="mini" type="primary" @click="dialog_scan = false">关闭</el-button>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;item-align:center;">

                <!-- <div style="width: 10%;display: flex;align-items: center;height: 20px;"> 
                        <span  style="font-size:10px;color:#409EFF;display:flex;align-items: center;margin-left: 10px;">一次最多扫描60个条码</span>
                </div>      -->
                <div style="width: 100%;display:flex;align-items: center;background-color: #303133;height: 30px;">
                    <span style="font-size:10px;color:#f7f4f4;display:flex;align-items: center;
                        margin-left: 50px;">【一次最多扫描60个条码,当点击匹配提交后，如果条码有问题，则条码底色为红色的标签属于问题标签】</span>
                    <span style="font-size:10px;color:#f7f4f4;display:flex;align-items: center;
                        margin-left: 10px;">本次共扫描</span>
                    <span style="font-size:10px;color:#f7f4f4;display:flex;align-items: center;
                        margin-left: 14px;">{{ scantotal }}</span>
                    <span style="font-size:10px;color:#f7f4f4;display:flex;align-items: center;
                        margin-left: 10px;">PCS</span>
                </div>

            </div>

            <div style="height:auto">
                <div style="width:100%;height:40px;display:flex;flex-flow:row wrap;">
                    <div :class="[item.iserror == 0 ? 'isnormal' : 'iserror']" v-for="(item, index) in scanlist "
                        :key="index">{{
                            item.barcode }}
                    </div>
                </div>
            </div>

        </el-dialog>
        <el-dialog title="产品搜索" :visible.sync="dialog_prodlist">
            <div style="width: 100%;display: flex;margin-top: 3px;item-align:center;">
                <div style="width: 10%;item-align:center;display:flex;">
                    <span style="display: flex;align-items: center;item-align:center;">商品检索</span>
                </div>
                <div style="width: 40%;">
                    <el-input ref="searchpd" style="" placeholder="请输入内容" suffix-icon="el-icon-search"
                        v-model="prodcode_list"></el-input>
                </div>

                <div style="width:30%;margin-left: 2px;display:flex;item-align:center;justify-content:right;">
                    <el-button size="mini" type="warning" @click="searchprolist">搜索</el-button>
                    <el-button size="mini" type="primary" @click="dialog_prodlist = false">关闭</el-button>
                </div>
            </div>
            <div v-if="mflag == 1" style="display: flex;width: 100%;align-items: center;margin-top: 5px;">
                <div style="width: 10%;item-align:center;display:flex;">
                    <span style="display: flex;align-items: center;item-align:center;">出厂日期</span>
                </div>
                <div style="width: 40%;">
                    <el-date-picker v-model="chuchangdate" type="date" placeholder="选择日期">
                    </el-date-picker>
                </div>
            </div>
            <el-table :data="prodlist">
                <el-table-column sortable fixed prop="prodtype" label="产品类别" width="150">
                </el-table-column>
                <el-table-column property="prodcode" label="产品编号" width="150"></el-table-column>
                <el-table-column property="prodname" label="产品名称" width="200"></el-table-column>
                <el-table-column property="proddes" label="规格型号"></el-table-column>
                <el-table-column property="proddes" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="selectprodid(scope.row)">选中</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

    </div>
</template>
<script>
import { weburl, QEMPORTEXCELINFO, INSNBARCODELIST, GETNBARCODELIST, BATCHSTOPBARCODELIST,
     GETNBARCODELISTDETAIL, QSKULIST, COMPAREPRODBAR,Qbarrulelistitem } from "@/api";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import WangEditor from "@/components/WangEditor";
import QRCode from 'qrcodejs2';
import SearchButton from '@/components/SearchButton/index.vue';
export default {
    components: { WangEditor, SearchButton },
    name: "User",
    dicts: ['sys_normal_disable', 'sys_user_sex'],

    data() {
        return {
            beginnum:'',
            endnum:'',
            barruleitemlist:[],
            sbarvalue:'',
            chuchangdate: '',
            isquanbu: true,
            prodcode_list: '',
            dialog_prodlist: false,
            prodlist: [],
            scantotal: 0,
            scanlist: [],
            mscanbarlist: '',
            dialog_scan: false,
            isdetail: true,
            orderListAll_detial: [],
            totalSize_detail: null,
            index_detail: 1, //默认第一页
            pageSize_detail: 50, //默认展示10条数据

            listname: '',
            isflag: '',
            loading: false,
            begindate: '',
            enddate: '',
            chanpinleibie: '',
            chanpinmingcheng: '',
            guigexinghao: '',
            tiaomabianhao: '',
            neimabianhao: '',
            chanpinbianhao: '',
            value1: '',
            value2: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            isdaijihuo: false,
            iszhibao: false,
            isshixiao: false,
            isguoqi: false,
            isshanchu: false,
            wordcaption: '展开搜索',
            scrollerHeight: '80px',
            showAll: false,
            ///////////////////////////////////////////////////////////////////////
            isstop: false,
            printlist: [],
            qrcode: null,
            qrCodeUrl: '',
            dialog_print: false,
            Q_prodcode: '',
            mguid: '',
            dialog_editinfo: false,
            upweburl: '',
            tableHeight: '',
            uppercent: 0,
            upcount: '',
            fileList: [],
            dialog_excelfile: false,
            mcomment: '',
            mydate: '',
            msdate: '',
            isclose: '',
            mdate: '',
            dialog_taskinfo: false,
            dialog_editdisplay: false,
            taskcode: '',
            deafultcvalue: '',
            menutreelist: [],
            begindate: '',
            enddate: '',
            radio1: '',
            tableHerd: [],
            tableData: [],
            mguid: '',
            exceldata: [],
            formdata: {
                id: 0,
                prodtype: '',
                prodcode: '',
                prodname: '',
                proddes: '',
                imgurl: '',
                barcoderule: '',
                makeperson: '',
                makedate: '',
                isstop: '',
                barcodedefault: '',
                filename: '',
                wbdays: '',
                yxdays: ''
            },
            imageUrl: '',
            begindate: '',
            enddate: '',
            printcount: '',
            createresult: [],
            prodcode: '',
            prodname: '',
            menutabledata: [],
            /////////////////////////////////////////////////////////xinzeng
            totalSize: null,
            index: 1, //默认第一页
            pageSize: 50, //默认展示10条数据
            orderListAll: [],
            orderList: [],
            isstop: true,
            ruleForm: {
                title: "",
                coverImg: "",
                content: "",
            },
            selectedRows: [],
            printlist_detail: [],
            form_detail: {
                listname: '',
                mmguid: '',
                mmguid: '',
                beginnum: '',
                endnum: '',
                isflag: '',
                barcode: '',
                mguid: ''
            },
            scanlist: [],
            mflag: 0
        }
    },
    methods: {
        async mexportexcel() {
            let that = this;
            let pid = ''
            if (that.printlist.length > 0) {
                let importdata = [];
                let ecount = 0

                that.printlist.forEach(function (item, index) {
                    ecount = ecount + parseInt(item.levcount)
                    // console.log(ecount)
                    let batid = item.bid.toString();
                    if (pid) {
                        pid = pid + ',' + batid
                    } else {
                        pid = batid
                    }
                });
                if (ecount > 10000) {

                    this.$message({
                        message: '每次导出最大限额10000条，请重新选择',
                        type: 'error',
                        duration: 1000
                    });
                    return
                }
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let usercode = window.sessionStorage.getItem("usercode");
                let token = window.sessionStorage.getItem("token");
                let bid = pid
                const { data: res } = await QEMPORTEXCELINFO({
                    usercode,
                    token,
                    bid
                });
                let result = JSON.parse(res);
                if (result[0]) {
                    let importdata = [];
                    let sourcedate = []

                    sourcedate = result

                    if (sourcedate.length > 0) {
                        sourcedate.forEach(function (item, index) {
                            let batid = item.mguid.toString();
                            const parm1 = '-';
                            batid = batid.replace(/-/g, '');
                            importdata.push(
                                {
                                    '内部编码': 'https://qa.xinfei028.com/c/?id=' + batid.replace("-", ""),
                                    '外部编号': item.barcode
                                }
                            )
                        })
                        this._toExcel(importdata);
                    };


                } else {

                    that.$message({
                        message: '未发现数据，请尝试重新查询数据，选择导出数据',
                        type: 'success',
                        duration: 1000
                    });


                }
                loading.close()
            } else {
                that.$message({
                    message: '请选择数据',
                    type: 'error',
                    duration: 1000
                });
            }
        },
        checkvisible(btname) {
            let isvisible = false

            const menuData = this.$store.state.biaoqianchi.find(v => v.bname === btname);

            if (menuData) {
                if (menuData.isvisible) { isvisible = menuData.isvisible } else isvisible = false
            }
            else isvisible = false
            return isvisible
        },
        exportexcel() {
            //console.log(this.printlist);
            let importdata = [];
            let sourcedate = []
            // console.log('导出：',this.printlist_detail);
            if (this.printlist_detail.length > 0)
                sourcedate = this.printlist_detail
            else
                sourcedate = this.orderListAll_detial
            if (sourcedate.length > 0) {
                sourcedate.forEach(function (item, index) {
                    let batid = item.mguid.toString();
                    const parm1 = '-';
                    batid = batid.replace(/-/g, '');
                    importdata.push(
                        {
                            '内部编码': 'https://qa.xinfei028.com/c/?id=' + batid.replace("-", ""),
                            '外部编号': item.barcode
                        }
                    )
                })
                this._toExcel(importdata);
            };
        },
        comparesumlist() {
            this.chuchangdate = ''
            this.prodcode_list = ''
            this.dialog_prodlist = true
            this.mflag = 1
        },
        async tijiaopipei() {
            if (this.prodcode) { } else {
                this.$message.error("请选择匹配商品");
                return
            }
            if (this.scanlist.length == 0) {
                this.$message.error("请选择匹配商品");
                return
            }
            if (this.chuchangdate) { } else {
                this.$message.error("请选择出厂日期");
                return
            }
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let chuchangdate = this.dateFormat(this.chuchangdate);
            let mmguid = this.form_detail.mmguid;
            if (mmguid) { } else {
                this.$message.error("请选择归属");
                return
            }
            let prodcode = this.prodcode;
            let mguidlist = ''
            this.scanlist.forEach(function (item, index) {
                if (mguidlist)
                    mguidlist = mguidlist + ',' + item.barcode;
                else
                    mguidlist = item.barcode;

            });
            //  console.log('mguidlist',mguidlist);
            //  return;
            const { data: res } = await COMPAREPRODBAR({
                usercode,
                token,
                mmguid,
                mguidlist,
                prodcode,
                chuchangdate
            });
            //console.log(res);
            let mlist = [];

            mlist = JSON.parse(res);
            if (mlist.length != 0) {
                if (mlist[0].barcode == "203") {
                    ;
                    this.$message.error(mlist[0].iserror);
                } else if (mlist[0].barcode == "201") {
                    this.$message.error(mlist[0].iserror);
                } else if (mlist[0].barcode == "200") {
                    this.$message.success("已完全匹配，");
                    this.scanlist = []
                }
                else {
                    this.scanlist = mlist;
                }
            };

        },
        async selectprodid(item) {
            this.prodlist = []
            this.prodcode = item.prodcode;

            //console.log('this.mflag',this.mflag);
            //console.log('this.printlist_detail',this.printlist_detail);

            if (this.mflag == 1) {
                ///////////////////主页匹配商品，数据处理入口
                let importdata = [];
                let flag = 1;
                let sourcedate = []
                let plistjson = ''
                let prodcode = item.prodcode
                if (this.chuchangdate) { } else {
                    this.$message.error('请选择出厂日期');

                    return;
                }

                if (this.printlist_detail.length > 0) {
                    sourcedate = this.printlist_detail;
                    flag = 2;

                    this.printlist_detail.forEach(function (item, index) {
                        // console.log('bidbid:',item.bid);
                        if (plistjson) {
                            plistjson = plistjson + ',' + item.bid;
                        } else {
                            plistjson = item.bid;
                        };

                    });

                } else {
                    flag = 1
                }
                let token = window.sessionStorage.getItem("token");
                let usercode = window.sessionStorage.getItem("usercode");
                let mmguid = this.form_detail.mmguid
                let beginnum = this.form_detail.beginnum
                let endnum = this.form_detail.endnum
                let isflag = this.form_detail.isflag
                let barcode = this.form_detail.barcode
                let mguid = this.form_detail.mguid
                let pagecount = this.pageSize_detail;
                let pagenum = this.index_detail;
                let chuchangdate = this.dateFormat(this.chuchangdate);

                const { data: res } = await GETNBARCODELISTDETAIL({
                    usercode,
                    token,
                    mmguid,
                    beginnum,
                    endnum,
                    isflag,
                    barcode,
                    mguid,
                    pagecount,
                    pagenum,
                    flag,
                    plistjson,
                    prodcode,
                    chuchangdate
                });
                let mlist = [];
                mlist = JSON.parse(res);
                //console.log('匹配商品返回', mlist);
                if (mlist.length != 0) {
                    if (mlist[0].totalcount == 200) {
                        this.$message.success('匹配产品已匹配');
                        this.gettasktablelistdata_detail();
                    } else {
                        this.$message.error(mlist[0].mmguid);
                        return;
                    }

                }


            }
            this.dialog_prodlist = false
        },
        async getbarrulelistitem() {
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
           
            const { data: res } = await Qbarrulelistitem({
                usercode,
                token
            });
            //console.log(res);
            let mlist = [];
            this.barruleitemlist = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {

                this.barruleitemlist = mlist;

            }else
            {
                this.barruleitemlist =[]
            }

        },
        async searchprolist() {
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let id = '';
            let prodstr = this.prodcode_list;
            let pagecount = 10000;
            let pagenum = 1;
            const { data: res } = await QSKULIST({
                usercode,
                token,
                id,
                prodstr,
                pagecount,
                pagenum
            });
            //console.log(res);
            let mlist = [];
            this.prodlist = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {

                this.prodlist = mlist;

            };

        },
        searchprodlist() {
            this.mflag = 0
            this.prodcode_list = ''
            this.prodlist = []
            if (this.mflag == 1) {
                this.chuchangdate = ''

            }
            this.dialog_prodlist = true
            this.$nextTick(function () {
                this.$refs.searchpd.focus();
            });

        },
        resetscan() {
            this.chuchangdate = ''
            this.mflag = 0
            this.scanlist = []
            this.prodcode = ''
            this.scantotal = '      '
            this.neimabianhao = ''
            this.dialog_scan = true;
            this.$nextTick(function () {
                this.$refs.scaninput.focus();
            });
        },
        replacestr() {
            let res = this.neimabianhao
            const search = decodeURIComponent(encodeURIComponent(res.split('?')[1]).replaceAll('%0A', ''));
            // console.log('search', encodeURIComponent(res), encodeURIComponent(search));
            const searchPair = search.split('&');
            const searchObj = {};
            searchPair.forEach(item => {
                const [key, value] = item.split('=');
                searchObj[key] = value;
            });
            const mres = searchObj.id;
            //console.log('虎丘', searchObj, mres)
            if (mres) {
                let arr = this.scanlist.filter((i) => { return mres == i.barcode; });

                if (arr.length > 0) {
                    this.neimabianhao = ''
                    this.$message.error("该条码已存在，切勿重复扫描");
                    return
                } else {
                    if (this.scanlist.length == 60) {
                        this.$message.success("扫描超限，最多一次扫描60个");
                        return
                    }
                    this.$message.success("扫描成功");
                    this.neimabianhao = ''
                    this.scanlist.push(
                        {
                            barcode: mres,
                            iserror: 0
                        }
                    )
                }


            } else {
                this.$message.error("异常操作，请联系管理员");
                let mres = res.result.toString().replace("https://qa.xinfei028.com/c?id=", "");

            }
        }, courierNumberEnterDown(e) {
            this.keyDownTime = new Date().getTime();
        },
        courierNumberEnterUp(e) {
            this.keyUpTime = new Date().getTime();
            if (this.keyUpTime - this.keyDownTime < 30) {
                this.replacestr();

                //this.$message.success("已扫描");
            } else {

                this.replacestr();
                // this.$message.success("已扫描");

            }
        },
        handleEnter() {
            this.$message.error("复制失败");
            // console.log(this.inputValue)
        },
        onCopy(e) {
            this.$message.success("复制成功");
        },
        onError() {
            this.$message.error("复制失败");
        },
        reback() {
            this.isdetail = true;
        },
        displaychangeisstop(item) {
            this.saveformdata(5, item);
        },
        delbiaoqianchi() {
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.upbatchstop()

            }).catch(() => {
                return


            });
        },
        async upbatchstop() {
            let that = this;
            if (that.printlist.length > 0) {
                let importdata = [];
                let bid = ''
                that.printlist.forEach(function (item, index) {
                    let batid = item.bid.toString();
                    if (bid) {
                        bid = bid + ',' + batid
                    } else {
                        bid = batid
                    }
                    // importdata.push(
                    //     {
                    //         'mguid': batid,
                    //     }
                    // )
                });
                // let jsonstr = JSON.stringify(importdata);
                // console.log('要删除：',jsonstr);
                let usercode = window.sessionStorage.getItem("usercode");
                let token = window.sessionStorage.getItem("token");
                const { data: res } = await BATCHSTOPBARCODELIST({
                    usercode,
                    token,
                    bid
                });
                let result = JSON.parse(res)[0];
                if (result.code !== 200) {
                    this.$message({
                        message: result.message,
                        type: 'error',
                        duration: 1000
                    });

                } else {

                    that.$message({
                        message: result.message,
                        type: 'success',
                        duration: 1000
                    });

                    that.gettasktablelistdata();
                }

            } else {
                that.$message({
                    message: '请选择数据',
                    type: 'error',
                    duration: 1000
                });
            }

        },

        isselect(i) {
            if (i == 0) {
                this.isshixiao = !this.isshixiao;
            }
            if (i == 1) {
                this.isguoqi = !this.isguoqi;
            }
            if (i == 2) {
                this.isquanbu = !this.isquanbu;
                if (this.isquanbu) {
                    this.isdaijihuo = false
                    this.iszhibao = false
                }
            }
            if (i == 3) {
                this.iszhibao = !this.iszhibao;
                if (this.iszhibao) {
                    this.isdaijihuo = false
                    this.isquanbu = false
                }
            }
            if (i == 4) {
                this.isdaijihuo = !this.isdaijihuo;
                if (this.isdaijihuo) {
                    this.iszhibao = false
                    this.isquanbu = false
                }
            }
        },
        closeSearch() {
            if (this.showAll) {
                this.wordcaption = '展开搜索'
                this.showAll = false
            } else {
                this.wordcaption = '收起搜索'
                this.showAll = true
            }
        },

        closepop() {
            this.showAll = !this.showAll;
            //console.log('父组件的状态：' + this.showAll)

        },
        handleSelectionChange(selection) {
            this.selectedRows = selection;

            this.printlist = this.selectedRows;
            // console.log('选中的数据：', this.selectedRows);
        },
        handleSelectionChange_detail(selection) {
            //this.selectedRows = selection;
            this.printlist_detail = selection;
            // console.log('选中的数据：', this.selectedRows);
        },
        handleCurrentChange(val) {
            this.index = val;
            this.gettasktablelistdata();

        },
        handleCurrentChange_detail(val) {
            this.index_detail = val;
            this.gettasktablelistdata_detail();

        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.gettasktablelistdata();

        },
        handleSizeChange_detail(val) {
            this.pageSize_detail = val;
            this.gettasktablelistdata_detail();

        },

        _toExcel(mdata) {
            if (mdata.length == 0) return;
            // let data = [{ "业务政策": 1, "产品名称": 2, "证件号码": 5 }, { "产品名称": 2 }]
            let data = mdata;
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "People");
            XLSX.writeFile(wb, "扫码质保.xls");
            this.$message.success('已导出，请查看!');
        },
        copyadd(i, item) {
            // console.log('item',item)
            this.formdata.prodname = item.listname
            this.formdata.makedate = ''
            this.formdata.mguid = ''  //内部编码
            this.formdata.yxdays = item.levcount//打印数量
            this.formdata.barcoderule = item.barrule
            this.dialog_taskinfo = true;
        },
        chakan(i, item) {
            this.index_detail = 1
            this.form_detail.mmguid = item.mguid
            this.form_detail.listname = item.listname
            this.form_detail.barrule = item.barrule
            this.form_detail.barcode = ''
            this.form_detail.beginnum = this.beginnum
            this.form_detail.endnum = this.endnum
            this.form_detail.mguid = ''
            //this.handleCurrentChange_detail(1)
            this.gettasktablelistdata_detail();
            this.isdetail = false
            // beginnum:'',
            // endnum:'',
            // isflag:'',
            //  barcode:'',
            //  mguid:''
        },
        addptypeinfo() {
            this.formdata.prodname = ''
            this.formdata.makedate = ''
            this.formdata.mguid = ''  //内部编码
            this.formdata.yxdays = ''//打印数量
            this.formdata.barcoderule = ''
            this.dialog_taskinfo = true;

        },
        importexcel() {
            this.dialog_excelfile = true;
        },
        dateFormat(data) {
            let date = new Date(data);
            let YY = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD;
        },
        async saveformdata(misflag, item) {

            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");

            let barrule = this.formdata.barcoderule;
            let listname = this.formdata.prodname;
            let isdisplay = '0'
            let mguid = ''
            let listcount = this.formdata.yxdays;//打印数量
            if (listcount > 500) {
                this.$message({
                    message: '条码数量每次限制最大数量为500',
                    type: 'error',
                    duration: 1000
                });
                return
            }
            let makedate = '';
            let isflag = '0'
            if (misflag)
                isflag = misflag
            if (misflag == '2') {
                mguid = item.mguid
            }
            let makeperson = ''
            if (misflag == 5) {

                listname = item.listname
                listcount = item.listcount
                makedate = item.makedate
                makeperson = item.makeperson
                mguid = item.mguid
                barrule = item.barrule

                isflag = '5'
                if (item.isdisplay)
                    isdisplay = 1
                else
                    isdisplay = 0
            }
            const { data: res } = await INSNBARCODELIST({
                usercode,
                token,
                barrule,
                listname,
                listcount,
                isdisplay,
                mguid,
                makedate,
                isflag,
                makedate,
                makeperson

            });

            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.formdata.id = 0,
                    this.formdata.prodtype = '';
                this.formdata.prodcode = '';
                this.formdata.prodname = '';
                this.formdata.proddes = '';
                this.formdata.imgurl = '';
                this.formdata.barcoderule = '';
                this.formdata.makeperson = '';
                this.formdata.makedate = '';
                this.formdata.isstop = '';
                this.formdata.barcodedefault = '';
                this.formdata.wbdays = '';
                this.imageUrl = '';
                this.begindate = '';
                this.enddate = '';
                this.mguid = '';
                this.dialog_taskinfo = false;
                this.refrashdate();
            };

        },
        async refrashdate() {
            this.gettasktablelistdata();
        },
        async gettasktablelistdata_detail() {
            this.loading_detail = true;
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let mmguid = this.form_detail.mmguid
            let beginnum = this.form_detail.beginnum
            let endnum = this.form_detail.endnum
            let isflag = this.form_detail.isflag
            let barcode = this.form_detail.barcode
            let mguid = this.form_detail.mguid
            let pagecount = this.pageSize_detail;
            let pagenum = this.index_detail;
           
            const { data: res } = await GETNBARCODELISTDETAIL({
                usercode,
                token,
                mmguid,
                beginnum,
                endnum,
                isflag,
                barcode,
                mguid,
                pagecount,
                pagenum
            });
            let mlist = [];
            this.orderListAll_detial = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {

                this.totalSize_detail = parseInt(mlist[0].totalcount);
                this.orderListAll_detial = mlist;
            }
            this.loading_detail = false;
        },
        async gettasktablelistdata(e) {
            let begindate = '2019-01-01';
            let enddate = '2099-01-01';
            //console.log('我的日期：',this.value);
            if (this.value2) {
                begindate = this.dateFormat(this.value2[0]);
                enddate = this.dateFormat(this.value2[1]);
            }
            //  else {
            //     this.$message({
            //         message: '日期不能为空！',
            //         type: 'error',
            //         duration: 1000
            //     });
            //     return;
            // }
            this.loading = true;
            let isdisplay = '1';
            if (this.isdaijihuo)
                isdisplay = '0';
            else
                isdisplay = '1';
            if (this.isquanbu) {
                isdisplay = '2'
            }
            let listname = this.chanpinleibie;
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");

            let pagecount = this.pageSize;
            let pagenum = this.index;
            let beginnum = this.beginnum
            let endnum = this.endnum
            let barrule=this.sbarvalue
            const { data: res } = await GETNBARCODELIST({
                usercode,
                token,
                listname,
                pagecount,
                pagenum,
                isdisplay,
                begindate,
                enddate,
                beginnum,
                endnum,
                barrule
            });
            let mlist = [];
            this.orderListAll = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {
                //console.log('点查询了', mlist);
                this.totalSize = parseInt(mlist[0].totalcount);
                this.orderListAll = mlist;
            }
            this.loading = false;
        }
    },
    mounted() {
        //this.gettasktablelistdata('0000400002');
        //this.gettreelist();
       // const end = new Date();
       // const start = new Date();
       // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
       // this.value2 = [start, end];
        this.upweburl = weburl;
        //this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 40;
        this.refrashdate();
        this.getbarrulelistitem();
    },
    watch: {
        // 被侦听的变量count
        scanlist() {
            this.scantotal = this.scanlist.length;
        }
    }
};
</script>
<style lang='less' scoped>
.isnormal {
    margin: 5px;
    height: auto;
    background-color: #ffffff;
    color: #303133;
}

.iserror {
    margin: 5px;
    height: auto;
    background-color: #d61a1a;
    color: #efefef;
}

/deep/.el-dialog__header {
    padding: 10px 10px 5px;
}

.isselected {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #409EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    font-size: 10px;
}

.isnoselected {
    font-size: 10px;
    color: #303133;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #C0C4CC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnRow {
    display: block;
}

.unBtnRow {
    display: none;
}

.issueContainer {
    .issue {
        margin: 1px auto;
    }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 260px;
}

.table-wrapper {
    height: calc(100% - 32px);
}

avatar-uploader .el-upload {
    border: 1px dashed #af1414;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
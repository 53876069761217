<template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;overflow: hidden;">
        <div class="testbox" style="width: 100%; display: flex; flex-direction: column; overflow: hidden;">
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: 40px;display: flex;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item style="font-size: 10px;color: red;" label="数据检索">
                        <el-input v-model="prodstr" placeholder="关键字检索"></el-input>
                    </el-form-item>

                    <el-form-item style="margin-left: 30px;">
                        <el-button type="info" plain icon="el-icon-search" @click="refrashdate">搜索</el-button>
                        <!-- <el-button type="primary" @click="refrashdate">查询</el-button>  el-icon-folder-add-->
                    </el-form-item>
                    <el-form-item v-if="checkvisible('新增')">
                        <el-button type="primary" plain icon="el-icon-folder-add" @click="addptypeinfo">新增产品</el-button>
                        <!-- <el-button type="primary" @click="refrashdate">新增</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: 20px;display: flex;justify-content:right;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(0)" :class="ishuishou ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">回收站</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <!-- <div class="table-wrapper"> -->
            <div class="table-wrapper" style="width: 100%; flex: 1; overflow: hidden;">
                <el-table :data="tableData" style="width: 100%" height="100%" class="table" ref="table" size="small"
                    :header-cell-style="{ background: 'rgb(251, 251, 251)', color: '#303133' }" highlight-current-row fit
                    stripe @cell-click="cellClick">
                    <el-table-column fixed prop="prodtype" label="" width="50">
                        <template slot-scope="scope">
                            <p>{{ scope.$index + 1 }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="prodtype" label="产品类别" width="150">
                    </el-table-column>
                    <el-table-column sortable prop="prodcode" label="产品编号" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="prodname" label="产品名称" width="130">
                    </el-table-column>
                    <el-table-column sortable prop="proddes" label="规格型号" width="160">
                    </el-table-column>
                    <el-table-column sortable prop="pinpai" label="品牌" width="160">
                    </el-table-column>
                    <el-table-column sortable prop="changjia" label="生产厂家" width="160">
                    </el-table-column>
                    <el-table-column sortable prop="salesarea" label="销售区域" width="160">
                    </el-table-column>             
                    <el-table-column sortable prop="wbdays" label="质保天数" width="100">
                    </el-table-column>
                    <el-table-column sortable prop="wbmonth" label="质保月数" width="100">
                    </el-table-column>                   
                    <el-table-column sortable prop="yxdays" label="有效天数" width="100">
                    </el-table-column>

                    <el-table-column sortable prop="barcoderule" style="color: red;" label="编码规则" width="240">
                    </el-table-column>
                    <el-table-column sortable prop="barcoderule" style="color: red;" label="状态" width="220">
                        <template slot-scope="scope">
                            <el-switch :disabled="!checkvisible('状态更改')" @change="displaychangeisstop(scope.row)"
                                style="font-size: 10px;" v-model="scope.row.isstop" active-color="#909399"
                                inactive-color="#13ce66" active-text="停用" inactive-text="启用">
                            </el-switch>

                        </template>

                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="280">
                        <template slot-scope="scope">
                            <el-button v-if="checkvisible('新增')" @click.native.prevent="copyadd(scope.$index, scope.row)"
                                type="text" size="small">
                                复制新增
                            </el-button>
                            <el-button v-if="checkvisible('修改')" @click.native.prevent="chakan(scope.$index, scope.row)"
                                type="text" size="small">
                                修改
                            </el-button>
                            <el-button v-if="checkvisible('生成条码')"
                                @click.native.prevent="createbarcode(scope.$index, scope.row)" type="text" size="small">
                                生成条码
                            </el-button>
                            <el-button v-if="checkvisible('编辑展示页')"
                                @click.native.prevent="editdesprod(scope.$index, scope.row)" type="text" size="small">
                                <p style="color: red;">编辑展示页</p>
                            </el-button>

                            <el-button v-if="checkvisible('删除')" @click.native.prevent="saveformdata(1, scope.row)"
                                type="text" size="small">
                                删除
                            </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="display: flex;justify-content: center;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="index"
                    :page-sizes="[10, 50, 100, 200, 400]" :page-size="pageSize"
                    layout="prev, pager, next, jumper, sizes , total" :total="totalSize" prev-text="上一页" next-text="下一页">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="详情编辑" :visible.sync="dialog_taskinfo" width="60%" :close-on-click-modal="false"
            :destroy-on-close="true">

            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">类别
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>

                <div style="width: 80%;"><el-input v-model="formdata.prodtype" placeholder="非空字段"></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">产品名称
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 80%;"><el-input v-model="formdata.prodname" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">产品编号
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 80%;"><el-input v-model="formdata.prodcode" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">规格型号</div>
                <div style="width: 80%;"><el-input v-model="formdata.proddes" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">品牌</div>
                <div style="width: 80%;"><el-input v-model="formdata.pinpai" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">生产厂家</div>
                <div style="width: 80%;"><el-input v-model="formdata.changjia" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">销售区域</div>
                <div style="width: 80%;"><el-input v-model="formdata.salesarea" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">维保天数
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 40%;"><el-input :disabled="wbd" type="number" v-model="formdata.wbdays" placeholder=""></el-input></div>
                <div style="width: 15%;">维保月份
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 20%;"><el-input :disabled="wbm" type="number" v-model="formdata.wbmonth" placeholder=""></el-input></div>


            </div>

            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">编码规则
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 80%;display: flex;align-items: center;">
                    <div style="width: 30%;"><el-input v-model="formdata.barcoderule" placeholder=""></el-input></div>
                    <div style="width: 70%;display: flex;margin-left:10px">
                        <i style="margin-left: 2px;" class="el-icon-tickets" v-clipboard:copy="'[固定字段]+[年]+[月]+[日]+[3位流水]'"
                            v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
                        <p style="font-size: 10px;color: #303133;margin-left:5px;">[</p>
                        <p style="font-size: 10px;color: #F56C6C;">固定字段</p>
                        <p style="font-size: 10px;color: #303133;">]+[年]+[月]+[日]+[</p>
                        <p style="font-size: 10px;color: #F56C6C;">3</p>
                        <p style="font-size: 10px;color: #303133;">位流水]</p>
                        <p style="font-size: 10px;color: #606266;margin-left: 6px;">以上部分可随意组合，红色字体内容可自定义</p>
                    </div>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 15%;">二维码有效期
                    <span style="font-size: 10px;color:red;">*(必填)</span>
                </div>
                <div style="width: 80%;display:flex;">
                    <div style="width: 30%;">
                        <el-input type="number" v-model="formdata.yxdays" placeholder=""></el-input>
                    </div>
                    <span style="font-size: 10px;color:#F56C6C;margin-left:10px;">注：必须在此时间内开启质保，否则二维码会失效！</span>
                </div>

            </div>
            <!-- <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">是否停用</div>
                <div style="width: 80%;"><el-switch v-model="formdata.isstop" active-text="停用" inactive-text="启用">
                    </el-switch></div>
            </div> -->
            <div style="width: 100%;display: flex;margin-top: 10px;">
                <div style="width: 10%;">产品图片</div>
                <div style="width: 80%;height: auto;">
                    <!-- <el-upload class="avatar-uploader" :action="upweburl" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload> -->

                    <el-upload :limit="3" :action="upweburl" accept=".png, .jpg" show-file-list :on-change="handleChange"
                        :on-remove="handleRemove" :on-success="handleAvatarSuccess" list-type="picture-card"
                        :file-list="fileList" :auto-upload="true" multiple>
                        <i slot="default" class="el-icon-plus" />
                    </el-upload>


                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 10px;">
                <div style="width: 10%;"></div>
                <div style="width: 80%;height: auto;">
                    <span style="font-size: 12px;color: red;">推荐尺寸750*450</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">复制新增</el-button>  -->
                <el-button type="primary" @click="saveformdata">保存</el-button>
                <el-button type="danger" @click="saveformdata(1)">删除</el-button>
                <el-button @click="dialog_taskinfo = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="生成条码" :visible.sync="dialog_excelfile" width="60%" :close-on-click-modal="false">
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品名称</div>
                <div style="width: 72%;"><el-input :disabled="true" v-model="formdata.prodname" placeholder=""></el-input>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品编号</div>
                <div style="width: 72%;"><el-input :disabled="true" v-model="formdata.prodcode" placeholder=""></el-input>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">规格型号</div>
                <div style="width: 72%;"><el-input :disabled="true" v-model="formdata.proddes" placeholder=""></el-input>
                </div>
            </div>
            
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">编码规则</div>
                <div style="width: 30%;"><el-input :disabled="true" v-model="formdata.barcoderule"
                        placeholder=""></el-input></div>
                <div style="width: 10%;margin-left: 20px;">质保天数</div>
                <div style="width: 30%;"><el-input :disabled="true" type="number" v-model="formdata.wbdays"
                        placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">起始日期</div>
                <div style="width: 30%;"> <el-date-picker v-model="begindate" type="date" placeholder="选择日期">
                    </el-date-picker></div>
                <!-- <div style="width: 40%;color: rgb(44, 44, 246);margin-top: 3px;font-size: 8px;margin-left:10px;">该日期系统默认为条码生成日期,可更改可默认</div> -->
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">二维码有效期天数</div>
                <div style="width: 30%;">
                    <div style="width: 100%;"><el-input :disabled="true" type="number" v-model="formdata.yxdays"
                            placeholder=""></el-input>
                    </div>
                    <!-- <el-date-picker v-model="enddate" type="date" placeholder="选择日期">
                    </el-date-picker> -->
                </div>
                <!-- <div style="width: 45%;color: rgb(44, 44, 246);margin-top: 3px;font-size: 8px;">该日期系统默认为起始日期至质保天数后 ，可更改可默认
                </div> -->
            </div>
            <div style="width: 100%;display: flex;margin-top: 10px;">
                <div style="width: 10%;color: red;">生成份数</div>
                <div style="width: 72%;"><el-input type="number" v-model="printcount" placeholder=""></el-input></div>
            </div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->
                <el-button type="primary" @click="cccode"  v-loading.fullscreen.lock="fullscreenLoading">生成</el-button>
                <el-button type="danger" @click="_toExcel">导出</el-button>
                <el-button @click="dialog_excelfile = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="展示菜单页面" :visible.sync="dialog_editdisplay" width="60%" :close-on-click-modal="false">
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;background-color: rgb(251, 251, 251);">产品名称</div>
                <div style="width: 20%;"><el-input :disabled="true" v-model="prodname" placeholder=""></el-input></div>
                <div style="width: 10%;margin-left: 10px;">产品编号</div>
                <div style="width: 20%;"><el-input :disabled="true" v-model="prodcode" placeholder=""></el-input></div>
            </div>
            <el-table :data="menutabledata" style="width: 100%" @cell-click="cellClick"
                :header-cell-style="{ background: 'rgb(251, 251, 251)' }">
                <el-table-column fixed prop="menuname" label="菜单名称" width="100">
                </el-table-column>
                <el-table-column prop="iconame" label="菜单图标" width="100">
                    <template slot-scope="scope">
                        <el-image :src="micourl + scope.row.iconame" style="width:20px;height:20px;"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="isstop" label="是否显示" width="180">
                    <template slot-scope="scope">
                        <el-switch @change="displaychange(scope.row)" style="font-size: 10px;" v-model="scope.row.isstop"
                            active-text="显示" inactive-text="隐藏">
                        </el-switch>
                    </template>

                </el-table-column>
                <el-table-column prop="makedate" label="创建日期" width="140">
                </el-table-column>
                <el-table-column prop="makeperson" label="创建人" width="180">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="chakandisplay(scope.$index, scope.row)" type="text" size="small">
                            编辑
                        </el-button>
                        <el-button v-if="scope.row.isstop == '不显示'" @click.native.prevent="enabledisplay(scope.row, 1)"
                            type="text" size="small">
                            显示
                        </el-button>
                        <el-button v-if="scope.row.isstop == '显示'" @click.native.prevent="enabledisplay(scope.row, 0)"
                            type="text" size="small">
                            隐藏
                        </el-button>
                        <el-button @click.native.prevent="deldisplay(scope.row)" type="text" size="small">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->
                <el-button type="primary" @click="addproddex">新增</el-button>
                <el-button type="primary" @click="addicolist">菜单图标</el-button>
                <el-button @click="dialog_editdisplay = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="内容页面编辑" :visible.sync="dialog_editinfo" width="90%" height="50%" :close-on-click-modal="false"
            :fullscreen="false">
            <div class="issueContainer">
                <div class="issue w">
                    <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-width="100px"
                        class="demo-ruleForm">
                        <div>
                            <el-form-item label="产品编号" prop="title">
                                <el-input :disabled="true" v-model="prodcode"></el-input>
                            </el-form-item>
                            <el-form-item label="产品名称" prop="title">
                                <el-input :disabled="true" v-model="prodname"></el-input>
                            </el-form-item>
                        </div>
                        <div style="display: flex;">
                            <el-form-item label="菜单名称" prop="title">
                                <el-input v-model="ruleForm.title"></el-input>
                            </el-form-item>
                            <el-form-item label="菜单图标" prop="title">

                                <el-select v-model="ruleForm.iconame" style="width: 100%;" placeholder="请选择"
                                    @change="changeSelection(ruleForm.iconame)" ref="select">
                                    <el-option v-for="item in icolist" :key="item.iconame" :label="item.iconame"
                                        :value="item.iconame">
                                        <img :src="micourl + item.iconame" style="height: 30px;margin-right: 10px;">
                                        <span>{{ item.iconame }}</span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
 
                        </div>
 
                        <el-form-item label="内容" prop="content">
                            <div style="height: 60%;">
                                <Wang-editor v-if="formData.isupEditor" ref="editor" :isClear="isclear"  :value="ruleForm.content" />
                            </div>
                        </el-form-item>

                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->

                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>

                <el-button @click="dialog_editinfo = false">关闭</el-button>
            </div>

        </el-dialog>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <el-dialog :visible.sync="dialogVisibleico">
            <el-upload ref="upload" class="upload-demo" :action="mupicowebs" multiple :on-success="icouploadsuccess"
                :data="icoupdata" :before-upload="icoupbefore" :limit="5" :on-change="icohandleChange"
                :file-list="icofileList">
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-table :data="icolist" style="width: auto" height="auto" size="small"
                :header-cell-style="{ background: 'rgb(251, 251, 251)', color: '#303133' }" highlight-current-row fit stripe
                @cell-click="cellClick">
                <el-table-column fixed prop="prodtype" label="" width="50">
                    <template slot-scope="scope">
                        <p>{{ scope.$index + 1 }}</p>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="iconame" label="图片名称" width="180">
                </el-table-column>
                <el-table-column sortable prop="iconame" label="图标" width="90">
                    <template slot-scope="scope">
                        <el-image style="width: 30px;height: 30px;" :src="micourl + scope.row.iconame"></el-image>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="delico(1, scope.row)" type="text" size="small">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import { weburl, INSPTYPEINFO, QSKULIST, MABARCODELIST, QRYPRODDESLIST, INSDISPROD, UpTaskFile, GETICOINFOLIST, icourl, upicofiles, DELIcoFile } from "@/api";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import WangEditor from "@/components/WangEditor";
export default {
    components: { WangEditor },
    data() {
        return {
            isclear:false,
            wbd:false,
            wbm:false,
            fullscreenLoading:false,
            micourl: icourl,
            mupicowebs: upicofiles,
            icofileList: [],
            icoupdata: {},
            icolist: [],
            dialogVisibleico: false,
            // fileList:[],
            isdisplay: false,
            ishuishou: false,
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,

            totalSize: null,
            index: 1, //默认第一页
            pageSize: 10, //默认展示10条数据
            prodstr: '',
            mguid: '',
            dialog_editinfo: false,
            upweburl: '',
            tableHeight: '',
            uppercent: 0,
            upcount: '',
            fileList: [],
            dialog_excelfile: false,
            mcomment: '',
            mydate: '',
            msdate: '',
            isclose: '',
            mdate: '',
            dialog_taskinfo: false,
            dialog_editdisplay: false,
            taskcode: '',
            deafultcvalue: '',
            menutreelist: [],
            begindate: '',
            enddate: '',
            radio1: '',
            tableHerd: [],
            tableData: [],
            mguid: '',
            exceldata: [],
            formdata: {
                id: 0,
                prodtype: '',
                prodcode: '',
                prodname: '',
                proddes: '',
                imgurl: '',
                barcoderule: '',
                makeperson: '',
                makedate: '',
                isstop: '',
                barcodedefault: '',
                filename: '',
                wbdays: '',
                wbmonth: '',
                yxdays: '',
                pinpai:'',
                changjia:'',
                salesarea:''
            },
            imageUrl: '',
            begindate: '',
            enddate: '',
            printcount: '',
            createresult: [],
            prodcode: '',
            prodname: '',
            menutabledata: [],
            /////////////////////////////////////////////////////////xinzeng
            isstop: true,
            ruleForm: {
                title: "",
                coverImg: "",
                content: "",
                iconame: ""
            },
            formData: {
                articleText: "",
                isupEditor: true
            },
            rules: {
                title: [{ required: true, message: "菜单名称不能为空", trigger: "blur" }],
                // coverImg: [
                //   { required: true, message: "请上传封面图片", trigger: "blur" },
                // ],
                content: [
                    {
                        required: true,
                        message: "请编辑内容详情",
                        trigger: "blur",
                    },
                ],
            },

        }
    },
    methods: {
        copyadd(i, item) {
            this.formdata.id = ''
            this.formdata.prodtype = item.prodtype;
            this.formdata.prodcode = item.prodcode;
            this.formdata.prodname = item.prodname;
            this.formdata.proddes = item.proddes;
            this.formdata.imgurl = item.imgurl;
            this.formdata.yxdays = item.yxdays;
            this.pinpai=item.pinpai
            this.changjia=item.changjia
            this.salesarea=item.salesarea
            this.formdata.barcoderule = item.barcoderule;
            this.formdata.makeperson = item.makeperson;
            this.formdata.makedate = item.makedate;
            this.formdata.isstop = item.isstop;
            this.formdata.barcodedefault = item.barcodedefault;
            if(item.wbdays==0){
                this.formdata.wbdays=''
            }else{
                this.formdata.wbdays = item.wbdays;
            }
            if(item.wbmonth==0){
                this.formdata.wbmonth=''
            }else{
                this.formdata.wbmonth = item.wbmonth;
            }     
            this.imageUrl = item.imgurl;
            this.fileList = item.imglist;
            this.dialog_taskinfo = true;

        },
        changeSelection(iname) {
            let brand = iname;
            for (let index in this.icolist) {
                let aa = this.icolist[index];
                let value = aa.iconame;
                if (brand === value) {
                    let murl = this.micourl + aa.iconame
                    this.$refs.select.$el.children[0].children[0].setAttribute('style', "background:url(" + murl + ") no-repeat 10px;background-size: 30px 30px;color:#333;padding-left: 50px;");
                }
            }
        },

        async delico(index, item) {
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            let flag = 1
            let filename = item.iconame
            const { data: res } = await DELIcoFile({
                usercode,
                token,
                flag,
                filename
            });
            this.geticolist()
        },
        icohandleChange(file, fileList) {
            this.icofileList = fileList.slice(-3);
        },
        icoupbefore() {
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            let flag = 0
            this.icoupdata = {
                usercode: usercode,
                token: token,
                flag: flag,
            }
            let promise = new Promise((resolve) => {
                this.$nextTick(function () {
                    resolve(true);
                });
            });
            return promise; //通过返回一个promis对象解决
        },
        icouploadsuccess() {
            this.$refs.upload.clearFiles();
            this.geticolist()
        },
        async geticolist() {
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            const { data: res } = await GETICOINFOLIST({
                usercode,
                token,

            });
            let result = JSON.parse(res);
            if (result[0]) {
                this.icolist = result
            } else {
                this.icolist = []
            }
        },
        addicolist() {
            this.geticolist()
            this.dialogVisibleico = true
        },
        checkvisible(btname) {
            let isvisible = false
           
            const menuData = this.$store.state.chanpinxinxi.find(v => v.bname === btname);
            
           if(menuData){
            if (menuData.isvisible) {isvisible=menuData.isvisible}
           }
             else isvisible=false
            return isvisible
        },
        onCopy(e) {
            this.$message.success("复制成功");
        },
        onError() {
            this.$message.error("复制失败");
        },
        async deldisplay(item) {
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            const { data: res } = await INSDISPROD({
                usercode,
                token,
                mguid: item.mguid,
                menuname: item.menuname,
                prodcode: this.prodcode,
                discontent: '1',
                isstop: 1
            });
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {

                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {

                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.refrasheditdesprod();
            }

        },
        isselect(i) {
            if (i == 0) {
                this.ishuishou = !this.ishuishou;
                // if (this.isshixiao) {
                //     this.isguoqi = false
                //     this.isshanchu = false
                // }
            }
            if (i == 1) {
                this.isguoqi = !this.isguoqi;
                // if (this.isguoqi) {
                //     this.isshixiao = false
                //     this.isshanchu = false
                // }
            }
            if (i == 2) {
                this.isshanchu = !this.isshanchu;
                // if (this.isshanchu) {
                //     this.isguoqi = false
                //     this.isshixiao = false
                // }
            }
            if (i == 3) {
                this.iszhibao = !this.iszhibao;
                // if (this.isshanchu) {
                //     this.isguoqi = false
                //     this.isshixiao = false
                // }
            }
            if (i == 4) {
                this.isdaijihuo = !this.isdaijihuo;
                // if (this.isshixiao) {
                //     this.isguoqi = false
                //     this.isshanchu = false
                // }
            }
        },
        displaychangeisstop(item) {
            //console.log('item.isstop',item.isstop);
            if (item.isstop)
                this.saveformdata(0, item, 0)
            else
                this.saveformdata(0, item, 1);

        },
        displaychange(item) {

            if (item.isstop) {
                this.enabledisplay(item, 1)
            } else
                this.enabledisplay(item, 0);
        },
        handleRemove(file) {
            //  this.$refs.child.uploadFiles  子组件储存文件
            let tmplist = this.fileList;
            this.fileList.forEach(function (item, index) {
                if (item.name == file.name) {
                    tmplist.splice(index, 1);
                };
                // console.log('我点了删除',item);
            });
            this.fileList = tmplist;
            this.$refs.child.uploadFiles.forEach((v, index) => {
                if (file.name === v.name && file.url === v.url) {
                    this.$refs.child.uploadFiles.splice(index, 1);
                }
            });
        },


        async enabledisplay(item, isbool) {
            // console.log('执行到这里了');
            //console.log('item.discontent:',item.discontent);
            this.ruleForm.content = item.discontent;
            this.mguid = item.mguid;
            this.menuname = item.menuname;
            this.ruleForm.title = item.menuname;
            let isstop = isbool;
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            const { data: res } = await INSDISPROD({
                usercode,
                token,
                mguid: this.mguid,
                menuname: this.ruleForm.title,
                prodcode: this.prodcode,
                discontent: item.discontent,
                isstop
            });
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                //   this.$notify.error({
                //     title: "错误",
                //     message: result.message,
                //     duration: 1000,
                //   });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                //   this.$notify({
                //     title: "成功",
                //     message: result.message,
                //     type: "success",
                //     duration: 2000,
                //   });
                this.refrasheditdesprod();

            };

        },
        addproddex(prod) {
            this.$store.state.mguid = this.prodcode;
            this.menuname = '';          
            this.ruleForm.content = '';
            this.ruleForm.iconame=''
            this.dialog_editinfo = true;
            this.isdisplay = true;
           // this.$refs.editor.info_.clear()
           
           // this.$refs.editor.content.clear()
            //this.$refs.editor.$txt.html();
   
            // this.$refs.editor.text_.clear()
        },
        uploadSuccess(response) {
            this.ruleForm.coverImg =
                "http://47.115.211.168:58084/Uploads/" + response;
        },
        submitForm(formName, iddel, item, isbool) {
            if (isbool) {
                this.ruleForm.content = item.discontent;
                this.mguid = item.mguid;
                this.menuname = item.menuname;
                this.ruleForm.title = item.menuname;
            }
            else {
                this.ruleForm.content = this.$refs.editor.info_;
            };

            //console.log('提交时的',this.mguid);
            if (iddel == '1') {
                this.ruleForm.content = '1';
                this.mguid = item.mguid;
            };
            let isstop = 0;
            //console.log('isstop',isstop);
            // if (isbool == 1)
            // {
            //   console.log('this.isdisplay',this.isdisplay);
            if (this.isdisplay)
                isstop = 1;
            else
                isstop = 0;
            // }      
            // else
            // {
            //     console.log('this.isdisplaythis.isdisplay',this.isdisplay);
            //     isstop = 1;
            // }


            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (!this.$route.query.id) {
                        let usercode = window.sessionStorage.getItem("usercode");
                        let token = window.sessionStorage.getItem("token");
                        const { data: res } = await INSDISPROD({
                            usercode,
                            token,
                            mguid: this.mguid,
                            menuname: this.ruleForm.title,
                            prodcode: this.prodcode,
                            discontent: this.ruleForm.content,
                            isstop,
                            iconame: this.ruleForm.iconame
                        });
                        let result = JSON.parse(res)[0];
                        if (result.code !== 200) {
                            //   this.$notify.error({
                            //     title: "错误",
                            //     message: result.message,
                            //     duration: 1000,
                            //   });
                            this.$message({
                                message: result.message,
                                type: 'error',
                                duration: 1000
                            });
                        } else {
                            // this.$notify({
                            //     title: "成功",
                            //     message: result.message,
                            //     type: "success",
                            //     duration: 2000,
                            // });
                            this.$message({
                                message: result.message,
                                type: 'success',
                                duration: 1000
                            });
                            this.refrasheditdesprod();
                            this.dialog_editinfo = false;
                            //this.$router.push("/journalism");
                        }
                    } else {
                        let usercode = window.sessionStorage.getItem("usercode");
                        let token = window.sessionStorage.getItem("token");
                        const { data: res } = await INSDISPROD({
                            usercode,
                            token,
                            mguid: this.mguid,
                            menuname: this.ruleForm.title,
                            prodcode: this.prodcode,
                            discontent: this.ruleForm.coverImg,
                            isstop,
                            iconame: this.ruleForm.iconame
                        });
                        let result = JSON.parse(res)[0];
                        if (result.code !== 200) {
                            //   this.$notify.error({
                            //     title: "错误",
                            //     message: result.message,
                            //     duration: 1000,
                            //   });
                            this.$message({
                                message: result.message,
                                type: 'error',
                                duration: 1000
                            });
                        } else {
                            // this.$notify({
                            //     title: "成功",
                            //     message: result.message,
                            //     type: "success",
                            //     duration: 2000,
                            // });
                            this.$message({
                                message: result.message,
                                type: 'success',
                                duration: 1000
                            });
                            this.refrasheditdesprod();
                            this.dialog_editinfo = false;

                            //this.$router.push("/journalism");
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        chakandisplay(index, item) {
          
            this.ruleForm.content = item.discontent;   
            //console.log('item.discontent',item.discontent)    
            this.mguid = item.mguid;
            this.$store.state.mguid = item.prodcode;
            this.menuname = item.menuname;
            this.ruleForm.title = item.menuname;
            this.ruleForm.iconame = item.iconame
            this.isdisplay = item.isstop;
            this.dialog_editinfo = true;
            this.changeSelection(this.ruleForm.iconame);


        },
        async refrasheditdesprod() {

            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let prodcode = this.prodcode;
            const { data: res } = await QRYPRODDESLIST({
                usercode,
                token,
                prodcode,
            });
            let result = JSON.parse(res);
            if (result.length == 0) {
                this.menutabledata = [];
            } else {
                this.menutabledata = result;
            };
        },
        async editdesprod(index, item) {
            this.geticolist()
            this.dialog_editdisplay = true;
            this.prodcode = item.prodcode;
            this.prodname = item.prodname;
            // console.log(item.mguid);
            this.mguid = item.mguid;
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let prodcode = item.prodcode;
            const { data: res } = await QRYPRODDESLIST({
                usercode,
                token,
                prodcode,
            });
            let result = JSON.parse(res);
            if (result.length == 0) {
                this.menutabledata = [];
            } else {
                this.menutabledata = result;
                // console.log('菜单内容', result);
            };
        },
        _toExcel() {
            if (this.createresult.length == 0) return;
            // let data = [{ "业务政策": 1, "产品名称": 2, "证件号码": 5 }, { "产品名称": 2 }]
            let data=[]
            this.createresult.forEach(function (item, index) {
                    let batid = item.mguid.toString();
                    const parm1 = '-';
                    batid = batid.replace(/-/g, '');
                    data.push(
                        {
                            '内部编码': 'https://qa.xinfei028.com/c/?id=' + batid.replace("-", ""),
                            '外部编号': item.barcode
                        }
                    )
                })
            //let data = this.createresult;
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "People");
            XLSX.writeFile(wb, "扫码质保.xls");
            this.$message.success('已导出，请查看!');
        },
        async cccode() {
            if (this.formdata.prodcode) { } else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '未发现产品编码！',
                //     duration: 1000,
                // });
                this.$message({
                    message: '未发现产品编码！',
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            if (this.printcount) {
                if (this.printcount <= 0) {
                    this.$message({
                        message: '生成份数必须大于零！',
                        type: 'error',
                        duration: 1000
                    });
                    return;
                }
                if (this.printcount >500) {
                    this.$message({
                        message: '每次生成份数不能大于500！',
                        type: 'error',
                        duration: 1000
                    });
                    return;
                }

            } else {
                this.$message({
                    message: '生成份数必须大于零！',
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            if (this.begindate) { } else {
                this.$message({
                    message: '生成日期不能为空！',
                    type: 'error',
                    duration: 1000
                });
                return;
            }
            
            //this.$message.success('系统生成中，请勿关闭!');
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");

            let prodtype = this.formdata.prodtype;
            let prodcode = this.formdata.prodcode;
            let prodname = this.formdata.prodname;
            let proddes = this.formdata.proddes;
            let begindate = '';
            if (this.begindate)
                begindate = this.dateFormat(this.begindate);
            else
                begindate = '';
            let enddate = '';
            if (this.enddate)
                enddate = this.dateFormat(this.enddate);
            else
                enddate = '';
            let barcoderule = this.formdata.barcoderule;
            let printcount = this.printcount;
            let yxdays = this.formdata.yxdays;
            this.fullscreenLoading = true;
            const { data: res } = await MABARCODELIST({
                usercode,
                token,
                prodcode,
                begindate,
                enddate,
                printcount,
                yxdays
            });

            let result = JSON.parse(res);
            this.fullscreenLoading = false;
            if(result[0].mguid=='201'){
                this.$message({
                    message: result[0].barcode,
                    type: 'error',
                    duration: 1000
                });
            }else{
                this.createresult=result;
                this.$message({
                    message: "已生成，可点击导出EXCEL，或者到标签信息页面查找下载！",
                    type: 'success',
                    duration: 1000
                });
            }
            return;
            if (result.length == 0) {
                // this.$notify.error({
                //     title: "错误",
                //     message:'生成失败！请重试!',
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {

                this.createresult = result;
                // this.$notify({
                //     title: "已生成，可点击导出EXCEL，或者到标签信息页面查找下载！",
                //     message: result.message,
                //     type: "success",
                // });
                this.$message({
                    message: "已生成，可点击导出EXCEL，或者到标签信息页面查找下载！",
                    type: 'success',
                    duration: 1000
                });

            };
        },
        dateFormat(data) {
            let date = new Date(data);
            let YY = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD;
        },
        createbarcode(index, item) {
            if (item.barcoderule) { }
            else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '请先完善编码规则！',
                //     duration: 1000,
                // });
                this.$message({
                    message: '请先完善编码规则！',
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            this.formdata.id = item.id,
                this.formdata.prodtype = item.prodtype;
            this.formdata.prodcode = item.prodcode;
            this.formdata.prodname = item.prodname;
            this.formdata.proddes = item.proddes;
            this.formdata.imgurl = item.imgurl;
            this.formdata.barcoderule = item.barcoderule;
            this.formdata.makeperson = item.makeperson;
            this.formdata.makedate = item.makedate;
            this.formdata.isstop = item.isstop;
            this.formdata.barcodedefault = item.barcodedefault;
            this.formdata.wbdays = item.wbdays;
            this.formdata.yxdays = item.yxdays;
            this.dialog_excelfile = true;
            this.createresult = [];
            this.begindate = this.dateFormat(new Date());
            this.enddate = '';
            this.printcount = '';
        },
        handleAvatarSuccess(res, file) {
            //console.log('执行我了，执行我了：', res);
            if (res) {
                let url = 'http://47.115.211.168:58084/Uploads/' + res;
                this.fileList.push({
                    name: res, url: url
                });
                this.formdata.imgurl = res;
                this.imageUrl = URL.createObjectURL(file.raw);
            }

        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        chakan(i, item) {
            this.formdata.id = item.id,
                this.formdata.prodtype = item.prodtype;
            this.formdata.prodcode = item.prodcode;
            this.formdata.prodname = item.prodname;
            this.formdata.proddes = item.proddes;
            this.formdata.imgurl = item.imgurl;
            this.formdata.yxdays = item.yxdays;
            //console.log('this.image',this.formdata.imgurl);
            this.formdata.barcoderule = item.barcoderule;
            this.formdata.makeperson = item.makeperson;
            this.formdata.makedate = item.makedate;
            this.formdata.isstop = item.isstop;
            this.formdata.barcodedefault = item.barcodedefault;
            if (item.wbdays==0){
                this.formdata.wbdays=''
            }else{this.formdata.wbdays = item.wbdays;}
            if (item.wbmonth==0){
                this.formdata.wbmonth=''
            }else{this.formdata.wbmonth = item.wbmonth;}      
            this.formdata.pinpai = item.pinpai;
            this.formdata.changjia = item.changjia;
            this.formdata.salesarea = item.salesarea;
            // console.log(item.scope.row.imagurl);
            this.imageUrl = item.imgurl;
           // console.log(item.imgurl)
            this.fileList = item.imglist;
          // console.log('图片列表：', item.imglist);
            this.dialog_taskinfo = true;
        },
        addptypeinfo() {
            this.formdata.id = 0,
                this.formdata.prodtype = '';
            this.formdata.prodcode = '';
            this.formdata.prodname = '';
            this.formdata.proddes = '';
            this.formdata.imgurl = '';
            this.formdata.barcoderule = '';
            this.formdata.makeperson = '';
            this.formdata.makedate = '';
            this.formdata.isstop = '';
            this.formdata.barcodedefault = '';
            this.formdata.yxdays = '';
            this.formdata.id = 0,
            this.formdata.prodtype = '';
            this.formdata.prodcode = '';
            this.formdata.prodname = '';
            this.formdata.proddes = '';
            this.formdata.imgurl = '';
            this.formdata.barcoderule = '';
            this.formdata.makeperson = '';
            this.formdata.makedate = '';
            this.formdata.isstop = '';
            this.formdata.barcodedefault = '';
            this.formdata.wbdays = '';
            this.imageUrl = '';
            this.fileList = [];
            this.dialog_taskinfo = true;
            this.imageUrl = '';
            this.formdata.wbmonth = '';
            this.formdata.pinpai = '';
            this.formdata.changjia = '';
            this.formdata.salesarea = '';
        },
        defaultupload() {
            //console.log('默认上传覆盖');
        },

        async handleimportexcel() {
            const loading = this.$loading({
                lock: true,
                text: '上传数据中，请稍后。。。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // console.log('上传结果1');
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let jsonstr = JSON.stringify(this.exceldata);
            const { data: res } = await IMPORTEXCEL({
                usercode,
                token,
                jsonstr
            });
            //console.log('上传结果');
            loading.close();
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                // this.$notify.error({
                //     title: "错误",
                //     message: result.message,
                //     duration: 1000,
                // });
                this.$message({
                    message: result[0].message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                // this.$notify({
                //     title: "成功",
                //     message: result.message,
                //     type: "success",
                // });
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.dialog_excelfile = false;
                // this.refrashdate();
            };
        },
        upload(file, fileList) {
            let files = { 0: file };
            this.readExcel1(files);
        },
        readExcel1(files) {
            // 如果没有文件名
            if (files.length <= 0) {
                return;
            }
            if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                this.$message.error('上传格式不正确，请上传xls或者xlsx格式');
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                    const data = ev.target.result;
                    const workbook = read(data, {
                        type: 'binary',
                    });
                    // 取第一张表
                    const wsname = workbook.SheetNames[0];
                    // 生成json表格内容
                    var ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                    this.exceldata = ws;
                    //console.log(this.exceldata);
                    this.upcount = this.exceldata.length;
                    // 后续数据的处理

                } catch (e) {
                    return false;
                }
            };
            fileReader.readAsBinaryString(files[0]);
        },
        importexcel() {
            this.dialog_excelfile = true;
        },
        async saveformdata(isdelete, item, isbool) {
            if (item) {
                this.formdata.id = item.id,
                this.formdata.prodtype = item.prodtype;
                this.formdata.prodcode = item.prodcode;
                this.formdata.prodname = item.prodname;
                this.formdata.proddes = item.proddes;
                this.formdata.imgurl = item.imgurl;
                this.formdata.barcoderule = item.barcoderule;
                this.formdata.makeperson = item.makeperson;
                this.formdata.makedate = item.makedate;
                this.formdata.yxdays = item.yxdays;
                this.formdata.pinpai = item.pinpai;
                this.formdata.changjia = item.changjia;
                this.formdata.salesarea = item.salesarea;
                this.formdata.wbmonth=item.wbmonth;
                //console.log('this.formdata.isstop:',this.formdata.isstop);
                if (isbool == 1)
                    this.formdata.isstop = false;
                else if (isbool == 0)
                    this.formdata.isstop = true;
                else
                    this.formdata.isstop = item.isstop;

                this.formdata.barcodedefault = item.barcodedefault;
                // console.log(item.scope.row.imagurl);
                this.imageUrl = item.imgurl;
                this.formdata.wbdays = item.wbdays;

            };
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let id = this.formdata.id;
            let prodtype = this.formdata.prodtype;
            let prodcode = this.formdata.prodcode;
            let prodname = this.formdata.prodname;
            let proddes = this.formdata.proddes;
            let yxdays = this.formdata.yxdays;
            let wbmonth=this.formdata.wbmonth;
            let imgurl = '';
            if (isbool != 1) {
                imgurl = '';

                if (this.fileList.length > 0) {
                    this.fileList.forEach(function (item, index) {
                        if (imgurl) {
                            imgurl = imgurl + ',' + item.name
                        } else {
                            imgurl = item.name
                        }

                    })
                } else
                    imgurl = '';
            }

            //let imgurl = this.formdata.imgurl;

            //imgurl=imgurl.replace(this.upweburl,'');
            //console.log('我的图片名称',imgurl);
            let barcoderule = this.formdata.barcoderule;
            let wbdays = this.formdata.wbdays;
            let makedate = '';
            if (isdelete == 1)
                makedate = '1';
            else
                makedate = this.formdata.makeperson;
            let makeperson = this.formdata.makeperson;
            let isstop = 0;
            if (this.formdata.isstop == false)
                isstop = 0;
            else
                isstop = 1;

            let barcodedefault = this.formdata.barcodedefault;
            let pinpai = this.formdata.pinpai;
            let changjia = this.formdata.changjia;
            let salesarea = this.formdata.salesarea;
            // let filename=this.formdata.filename;
            const { data: res } = await INSPTYPEINFO({
                usercode,
                token,
                id,
                prodtype,
                prodcode,
                prodname,
                proddes,
                imgurl,
                barcoderule,
                makeperson,
                makedate,
                isstop,
                barcodedefault,
                wbdays,
                yxdays,
                pinpai,
                changjia,
                salesarea,
                wbmonth
            });
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                // this.$notify.error({
                //     title: "错误",
                //     message: result.message,
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {

                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.formdata.id = 0,
                    this.formdata.prodtype = '';
                this.formdata.prodcode = '';
                this.formdata.prodname = '';
                this.formdata.proddes = '';
                this.formdata.imgurl = '';
                this.formdata.barcoderule = '';
                this.formdata.makeperson = '';
                this.formdata.makedate = '';
                this.formdata.isstop = '';
                this.formdata.barcodedefault = '';
                this.formdata.wbdays = '';
                this.imageUrl = '';
                this.dialog_taskinfo = false;

                this.refrashdate();
            };

        },
        async dispose(index, item) {
            let checkperson = item.person1 + item.person2 + item.person3;
            let username = this.$store.state.btypeinfo.bfullname;
            this.mcomment = '';
            this.mydate = '';
            this.msdate = '';
            this.mguid = '';
            //console.log(checkperson);
            if (checkperson.includes(username)) {


            } else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '您不需要对该异常做任何处理！',
                //     duration: 1000,
                // });
                this.$message({
                    message: '您不需要对该异常做任何处理！',
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            this.mcomment = '';
            this.mydate = item.mydate;
            this.msdate = item.msdate;
            this.mguid = item.mguid;
            if (item.ngstate == "已关闭")
                this.isclose = true;
            else
                this.isclose = false;
            this.dialog_taskinfo = true;
            return;

        },
        cellClick(row, column, cell, event) {
            if (row.filepath) {
                if (column.property == 'filepath') {
                    if (row.filepath) {
                        let filepath = weburl + row.filepath;

                        window.open(filepath);
                    };
                };
            };
        },
        async refrashdate() {

            this.gettasktablelistdata();

        },

        handleChange(value) {
            let i = 0;
            i = value.length;
            if (i == 0)
                this.taskcode = ''
            else
                this.taskcode = value[i - 1];
        },
        handleCurrentChange(val) {
            this.index = val;
            this.gettasktablelistdata();
            //this.getList();
        },
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
            this.pageSize = val;
            this.gettasktablelistdata();
            //this.getList();
        },
        async gettasktablelistdata(e) {
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let id = '';
            let prodstr = '';
            if (this.ishuishou)
                prodstr = this.prodstr + '@回收';
            else
                prodstr = this.prodstr;
            let pagecount = this.pageSize;
            let pagenum = this.index;
            const { data: res } = await QSKULIST({
                usercode,
                token,
                id,
                prodstr,
                pagecount,
                pagenum
            });
             //console.log(res);
            let mlist = [];
            this.tableData = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {
                this.totalSize = parseInt(mlist[0].totalcount);
                this.tableData = mlist;
              //  console.log(window.innerHeight, this.$refs.table.$el.offsetTop)
              //  this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 80;
            };
        }
    },
    mounted() {
        //this.gettasktablelistdata('0000400002');
        //this.gettreelist();

        this.upweburl = weburl;
       // this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 40;
        this.refrashdate();
    },
    watch:{
       'formdata.wbdays'(oldval,newval){
             if(this.formdata.wbdays){this.wbm=true}else{this.wbm=false};     
       },
       'formdata.wbmonth'(oldval,newval){
             if(this.formdata.wbmonth){this.wbd=true}else{this.wbd=false};     
       },      
       'dialog_editinfo'(oldval,newval){
        if(! oldval){
            console.log('我是状态',oldval)
            this.ruleForm.content ='';
            //console.log('item.prodcode',item.prodcode);
            this.mguid ='';
            this.$store.state.mguid ='';
            this.menuname ='';
            this.ruleForm.title ='';
            this.ruleForm.iconame =''
            this.isdisplay ='';  
            this.ruleForm.content='';
           // this.isclare=false;
          this.isclear=true;
 
        }
        if (this.dialog_editinfo){
            this.isclear=false;
        }
       }
    }

};
</script>
<style lang='less' scoped>
::v-deep .w-e-text-container {
    height: 350px !important;
}

.isselected {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #409EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    font-size: 10px;
}

.isnoselected {
    font-size: 10px;
    color: #303133;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #C0C4CC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.issueContainer {
    .issue {
        margin: 1px auto;
    }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 260px;
}

.testbox {
    height: calc(100% - 32px);
}

avatar-uploader .el-upload {
    border: 1px dashed #af1414;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
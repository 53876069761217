<template>
  <div id="app">
    <!-- <Header v-show="$route.meta.show"></Header> -->
    <!-- <Nav v-show="$route.meta.show"></Nav> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./components/Header";
import Nav from "./components/Nav";
export default {
  components: {
    Header,
    Nav,
  },
};
</script>
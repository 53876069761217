<template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;overflow: hidden;">
        <div class="table-wrapper" style="width: 100%; display: flex; flex-direction: column; overflow: hidden;">
            <div style="background-color: rgb(251, 251, 251);width: 100%;height: auto;display: flex;">
                <el-form :inline="true" class="demo-form-inline">
                    <!-- <el-form-item label="日期范围">
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item> -->
                    <el-form-item style="font-size: 10px;color: red;" label="公司名称">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="gongsiname"></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="联系人">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="lianxirren"></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="联系方式">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="liannxifangshi"></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="状态">
                        <el-select v-model="zhuangtai" placeholder="请选择">
                            <el-option v-for="item in stateoptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="margin-left: 10px;">
                        <el-button type="primary" plain size="" icon="el-icon-search" @click="refrashdate">搜索</el-button>
                        <!-- <el-button type="primary" @click="refrashdate">查询</el-button>  el-icon-folder-add-->
                    </el-form-item>
                    <el-form-item v-if="checkvisible('新增')">
                        <el-button type="sucess" plain icon="el-icon-folder-add" @click="cadd">新增</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="gettasktablelistdata(1)">到期提醒共：{{
                            stopcount }}</el-button>
                    </el-form-item>
                    <!-- <el-form-item>
                        <el-button type="danger" plain  icon="el-icon-folder-add" @click="cadd">批量删除</el-button>                        
                    </el-form-item>                -->

                </el-form>
            </div>
            <div style="width: 100%; flex: 1; overflow: hidden;">
                <el-table v-loading="loading" element-loading-text="数据正在加载中..." size="small" :data="orderListAll"
                    style="width: 100%" height="100%" class="table" ref="table"
                    :header-cell-style="{ background: 'rgb(251, 251, 251)', color: '#303133' }" border
                    :cell-style="cellStyle" @selection-change="handleSelectionChange"
                    :selectable="row => row.enabled !== false">
                    <!-- <el-table-column type="selection">

                </el-table-column> -->
                    <el-table-column fixed type="selection" width="35">

                    </el-table-column>
                    <el-table-column fixed label="" width="50">
                        <template slot-scope="scope">
                            <p>{{ scope.$index + 1 }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column sortable fixed prop="stopflag" label="" width="40">
                        <template slot-scope="scope">
                            <i v-if="scope.row.stopflag == 1" class="el-icon-message-solid"></i>
                        </template>

                    </el-table-column>
                    <el-table-column sortable fixed prop="makedate" label="建立日期" width="120">
                    </el-table-column>
                    <el-table-column sortable fixed prop="cname" label="公司名称" width="100">
                    </el-table-column>
                    <!-- <el-table-column sortable prop="cnetaddress" label="网址" width="140">
                    </el-table-column> -->
                    <el-table-column sortable prop="mperson" label="负责人" width="140">
                    </el-table-column>
                    <el-table-column sortable prop="phone" label="电话" width="150">
                    </el-table-column>
                    <el-table-column sortable prop="wx" label="微信" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="username" label="账号" width="120">
                    </el-table-column>
                    <!-- <el-table-column prop="pwd" label="密码" width="120">
                    </el-table-column> -->
                    <el-table-column sortable prop="stopdate" label="停用日期" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="yxdays" label="临到期提醒" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="barcount" label="剩余条码数量" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="usedbarcount" label="累计使用条码数量" width="150">
                    </el-table-column>
                    <el-table-column sortable prop="usedcount" label="剩余商品数量" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="isstop" style="color: red;" label="状态" width="70">
                        <template slot-scope="scope">
                            <el-switch :disabled="(scope.row.iscust == '1'?true: ! checkvisible('状态更改'))" @change="displaychangeisstop(scope.row)"
                                style="font-size: 10px;" v-model="scope.row.isstop" active-color="#909399"
                                inactive-color="#13ce66" active-text="" inactive-text="">
                            </el-switch>
                            <!-- <el-switch  style="font-size: 10px;"
                                v-model="scope.row.isstopflag" active-color="#909399" inactive-color="#13ce66" active-text=""
                                inactive-text="">
                            </el-switch> -->
                        </template>

                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="85">
                        <template slot-scope="scope">
                            <el-button v-if="(scope.row.iscust == '1') ?true:!checkvisible('修改')" @click="chakan(scope.$index, scope.row)" type="text" size="small">
                                修改
                            </el-button>
                            <el-button v-if="(scope.row.iscust == '1') ?false:(checkvisible('删除'))" @click.native.prevent="deletecom(scope.row)"
                                type="text" size="small">
                                <p style="color: red;">删除</p>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="display: flex;justify-content: center;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="index"
                    :page-sizes="[50, 200, 500, 1000, 2000]" :page-size="pageSize"
                    layout="prev, pager, next, jumper, sizes , total" :total="totalSize" prev-text="上一页" next-text="下一页">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="详情编辑" :visible.sync="dialog_taskinfo" :close-on-click-modal="false" :destroy-on-close="true">
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">创建日期*</div>
                <div style="width: 80%;"><el-input readonly="" v-model="formdata.makedate" placeholder="自动生成"></el-input>
                </div>
            </div>
            <!-- <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">网址</div>
                <div style="width: 80%;"><el-input  readonly=""  v-model="formdata.cnetaddress" placeholder="自动生成"></el-input></div>
               </div> -->
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">公司名称*</div>
                <div style="width: 80%;"><el-input blur="" v-model="formdata.cname" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">负责人*</div>
                <div style="width: 80%;"><el-input v-model="formdata.mperson" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">手机*</div>
                <div style="width: 40%;"><el-input autofocus v-model="formdata.phone" placeholder=""></el-input></div>
                <div style="width: 10%;">微信</div>
                <div style="width: 40%;"><el-input v-model="formdata.wx" placeholder=""></el-input></div>

            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">账号*</div>
                <div style="width: 40%;"><el-input v-model="formdata.ucode" placeholder=""></el-input></div>
                <div style="width: 10%;">密码*</div>
                <div style="width: 40%;"><el-input show-password v-model="formdata.upwd" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">商品数量*</div>
                <div style="width: 40%;"><el-input :disabled="formdata.iscust == '1'" v-model="formdata.cpcount"
                        placeholder=""></el-input></div>
                <div style="width: 10%;">剩余条码数量*</div>
                <div style="width: 40%;"><el-input :disabled="formdata.iscust == '1'" v-model="formdata.cbcount"
                        placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">截止有效期*</div>
                <div style="width: 40%;">
                    <el-date-picker :disabled="formdata.iscust == '1'" style="width: 290px;" v-model="formdata.stopdate"
                        type="date" placeholder="选择日期">
                    </el-date-picker>
                </div>
                <div style="width: 10%;">到期前*</div>
                <div style="width: 40%;">
                    <el-input style="width: 120px;" :disabled="formdata.iscust == '1'" v-model="formdata.yxdays"
                        placeholder=""></el-input>
                    <i>天提醒</i>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">备注</div>
                <div style="width: 80%;"><el-input :disabled="formdata.iscust == '1'" v-model="formdata.remark"
                        placeholder=""></el-input></div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveformdata()">保存</el-button>
                <el-button @click="dialog_taskinfo = false">关闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { BATCHSTOPBARCODE, GETCOMPANEYLIST, INSCOMPAEYINFO, weburl } from "@/api";

export default {

    data() {
        return {
            stopcount: '0',
            gongsiname: '',
            zhuangtai: '0',
            liannxifangshi: '',
            lianxirren: '',
            stateoptions: [{
                value: '0',
                label: '全部'
            }, {
                value: '1',
                label: '启用'
            }, {
                value: '2',
                label: '停用'
            }],
            loading: false,
            begindate: '',
            enddate: '',
            chanpinleibie: '',
            chanpinmingcheng: '',
            guigexinghao: '',
            tiaomabianhao: '',
            neimabianhao: '',
            chanpinbianhao: '',
            value1: '',
            value2: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            isstop: false,
            printlist: [],
            qrcode: null,
            qrCodeUrl: '',
            dialog_print: false,
            Q_prodcode: '',
            mguid: '',
            dialog_editinfo: false,
            upweburl: '',
            tableHeight: '',
            uppercent: 0,
            upcount: '',
            fileList: [],
            dialog_excelfile: false,
            mcomment: '',
            mydate: '',
            msdate: '',
            isclose: '',
            mdate: '',
            dialog_taskinfo: false,
            dialog_editdisplay: false,
            taskcode: '',
            deafultcvalue: '',
            menutreelist: [],
            begindate: '',
            enddate: '',
            radio1: '',
            tableHerd: [],
            tableData: [],
            mguid: '',
            exceldata: [],
            formdata: {
                iscust: '',
                makedate: '',
                cnetaddress: '',
                cname: '',
                mperson: '',
                phone: '',
                wx: '',
                ucode: '',
                upwd: '',
                cpcount: '',
                cbcount: '',
                yxdays: '',
                remark: '',
                mguid: '',
                stopdate: ''

            },
            imageUrl: '',
            begindate: '',
            enddate: '',
            printcount: '',
            createresult: [],

            menutabledata: [],
            /////////////////////////////////////////////////////////xinzeng
            totalSize: null,
            index: 1, //默认第一页
            pageSize: 50, //默认展示10条数据
            orderListAll: [],
            orderList: [],
            isstop: true,
            selectedRows: []
        }
    },
    methods: {
        checkvisible(btname) {
            let isvisible = false

            const menuData = this.$store.state.biaoqianchi.find(v => v.bname === btname);

            if (menuData) {
                if (menuData.isvisible) { isvisible = menuData.isvisible } else isvisible = false
            }
            else isvisible = false
            return isvisible
        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            // 定义样式变量
            let cellStyle;
            // 根据每一行的status属性的值进行判断
            // 如果是正常就展示以绿色字体展示，如果是禁用就以红色颜色展示

            switch (row.stopflag) {
                // 0代表正常
                //case '0':
                // 设置文本颜色 绿色 可以直接写颜色编码，也可以直接写颜色的单词
                //  cellStyle = 'color:#70DB93';
                //   break;
                // 0代表金禁用
                case '1':
                    // 设置文本颜色 红色
                    cellStyle = 'color:#E6A23C';
                    break;
                // 如果有其他状态，就默认显示，不给文本颜色
                default:
                    cellStyle = '';
            }
            switch (row.isstop) {
                // 0代表正常
                //case '0':
                // 设置文本颜色 绿色 可以直接写颜色编码，也可以直接写颜色的单词
                //  cellStyle = 'color:#70DB93';
                //   break;
                // 0代表金禁用
                case true:
                    // 设置文本颜色 红色
                    cellStyle = 'color:#F56C6C';
                    break;
                // 如果有其他状态，就默认显示，不给文本颜色
               // default:
               //     cellStyle = '';
            }           

            //return cellStyle  // 返回最终处理过的样式 这样写就是让全部行被style修饰
            // 返回最终处理过的样式 只让账号状态这个属性的属性被style修饰
            // if(column.label == '账号状态'){
            return cellStyle
            //}
        },
        deletecom(item) {
            this.isflag = 3
            this.saveformdata(item);
        },
        checkvisible(btname) {

            // console.log('menuData',this.$store.state.btnauthor)
            const menuData = this.$store.state.gongsixinxi.find(v => v.bname === btname);
            //console.log('menuData',menuData)
            let mresult = false
            if (!menuData)
                mresult = false
            else
                mresult = menuData.isvisible
            return mresult
        },
        displaychangeisstop(item) {
           // console.log('a:', item)
            if (item.isstop == true) {
                item.isstop = false
            } else { item.isstop = true }
            item.isstopflag = !item.isstopflag
            // item.isstop=!item.isstop

            this.isflag = 1

            this.saveformdata(item);

        },
        cadd() {
            this.isflag = 0
            this.formdata.cname = ''
            this.formdata.mperson = ''
            this.formdata.phone = ''
            this.formdata.wx = ''
            this.formdata.ucode = ''
            this.formdata.upwd = ''
            this.formdata.cpcount = ''
            this.formdata.cbcount = ''
            this.formdata.yxdays = ''
            this.formdata.remark = ''
            this.formdata.cnetaddress = ''
            this.formdata.makedate = this.dateFormat(new Date);
            this.dialog_taskinfo = true
        },

        async upbatchstop(sguid) {
            let that = this;

            if (that.printlist.length > 0) {
                let importdata = [];

                that.printlist.forEach(function (item, index) {
                    let batid = item.mguid.toString();
                    importdata.push(
                        {
                            'mguid': batid,
                        }
                    )
                });
                let jsonstr = JSON.stringify(importdata);
                // console.log('要删除：',jsonstr);
                let usercode = window.sessionStorage.getItem("usercode");
                let token = window.sessionStorage.getItem("token");
                const { data: res } = await BATCHSTOPBARCODE({
                    usercode,
                    token,
                    jsonstr
                });
                let result = JSON.parse(res)[0];
                if (result.code !== 200) {
                    this.$message({
                        message: result.message,
                        type: 'error',
                        duration: 1000
                    });

                } else {
 
                    that.$message({
                        message: result.message,
                        type: 'success',
                        duration: 1000
                    });

                    that.gettasktablelistdata();
                }

            } else {
                that.$message({
                    message: '请选择数据',
                    type: 'error',
                    duration: 1000
                });
            }

        },

        handleSelectionChange(selection) {
            this.selectedRows = selection;

            this.printlist = this.selectedRows;
            // console.log('选中的数据：', this.selectedRows);
        },


        handleCurrentChange(val) {
            this.index = val;
            this.gettasktablelistdata();
            //this.getList();
        },
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
            this.pageSize = val;
            this.gettasktablelistdata();
            //this.getList();
        },


        chakan(i, item) {

            this.isflag = 1
            this.formdata.cname = item.cname
            this.formdata.mperson = item.mperson
            this.formdata.phone = item.phone
            this.formdata.wx = item.wx
            this.formdata.ucode = item.username
            this.formdata.upwd = item.pwd
            this.formdata.cpcount = item.prodcount
            this.formdata.cbcount = item.barcount
            this.formdata.yxdays = item.yxdays
            this.formdata.remark = item.remark
            this.formdata.cnetaddress = item.cnetaddress
            this.formdata.mguid = item.mguid
            this.formdata.makedate = item.makedate
            this.formdata.stopdate = item.stopdate
            this.formdata.iscust = item.iscust
            this.dialog_taskinfo = true;
        },

        dateFormat(data) {
            let date = new Date(data);
            let YY = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD;
        },
        async saveformdata(item) {
            if (item) {
                this.formdata.cname = item.cname
                this.formdata.mperson = item.mperson
                this.formdata.phone = item.phone
                this.formdata.wx = item.wx
                this.formdata.ucode = item.username
                this.formdata.upwd = item.pwd
                this.formdata.cpcount = item.prodcount
                this.formdata.cbcount = item.barcount
                this.formdata.yxdays = item.yxdays
                this.formdata.remark = item.remark
                this.formdata.cnetaddress = item.cnetaddress
                this.formdata.mguid = item.mguid
                this.formdata.usedcount = item.usedcount
                this.formdata.isstop = item.isstop;

                this.formdata.stopdate = item.stopdate
                if (this.isflag != 3)
                    this.isflag = 1
                //console.log('aaa')        
            }

            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");

            let mguid = this.formdata.mguid
            let cname = this.formdata.cname

            let cnetaddress = this.formdata.cnetaddress
            let mperson = this.formdata.mperson
            let phone = this.formdata.phone
            let username = this.formdata.ucode
            let pwd = this.formdata.upwd
            let wx = this.formdata.wx
            let barcount = this.formdata.cbcount
            let prodcount = this.formdata.cpcount

            let isstop = this.formdata.isstop
            let yxdays = this.formdata.yxdays
            let makedate = this.formdata.makedate
            let makeperson = this.formdata.makeperson
            let stopdate = this.dateFormat(this.formdata.stopdate)
            let isflag = this.isflag
            const { data: res } = await INSCOMPAEYINFO({
                usercode,
                token,
                mguid,
                cname,
                cnetaddress,
                mperson,
                phone,
                username,
                pwd,
                wx,
                barcount,
                isstop,
                yxdays,
                makedate,
                makeperson,
                isflag,
                prodcount,
                stopdate
            });

            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.formdata.cname = ''//
                this.formdata.mperson = ''//item.mperson
                this.formdata.phone = ''//item.phone
                this.formdata.wx = ''//item.wx
                this.formdata.ucode = ''//item.ucode
                this.formdata.upwd = ''//item.upwd
                this.formdata.cpcount = ''//item.cpcount
                this.formdata.cbcount = ''//item.cbcount
                this.formdata.yxdays = ''//item.yxdays
                this.formdata.remark = ''//item.remark
                this.formdata.cnetaddress = ''//item.cnetaddress
                this.formdata.mguid = ''//item.mguid  
                this.formdata.iscust = ''
                this.dialog_taskinfo = false;
                this.refrashdate();
            };

        },
        async dispose(index, item) {
            let checkperson = item.person1 + item.person2 + item.person3;
            let username = this.$store.state.btypeinfo.bfullname;
            this.mcomment = '';
            this.mydate = '';
            this.msdate = '';
            this.mguid = '';
            //console.log(checkperson);
            if (checkperson.includes(username)) {


            } else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '您不需要对该异常做任何处理！',
                //     duration: 1000,
                // });
                this.$message({
                    message: '您不需要对该异常做任何处理！',
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            this.mcomment = '';
            this.mydate = item.mydate;
            this.msdate = item.msdate;
            this.mguid = item.mguid;
            if (item.ngstate == "已关闭")
                this.isclose = true;
            else
                this.isclose = false;
            this.dialog_taskinfo = true;
            return;

        },

        async refrashdate() {

            this.gettasktablelistdata();
        },

        handleChange(value) {
            let i = 0;
            i = value.length;
            if (i == 0)
                this.taskcode = ''
            else
                this.taskcode = value[i - 1];
        },
        async gettasktablelistdata(e) {
            let begindate = '2019-01-01';
            let enddate = '2099-01-01';
            // if(this.value2){
            //   begindate = this.dateFormat(this.value2[0]);
            //   enddate = this.dateFormat(this.value2[1]);
            // }else{
            //     this.$message({
            //         message: '日期不能为空！',
            //         type: 'error',
            //         duration: 1000
            //     });
            //     return;
            // }
            this.loading = true;
            let pwx = this.liannxifangshi;
            let isstop = this.zhuangtai;
            let ustr = this.lianxirrenv;
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let pagecount = this.pageSize;
            let pagenum = this.index;
            let gongsiname = this.gongsiname

            const { data: res } = await GETCOMPANEYLIST({
                usercode,
                token,
                pwx,
                pagecount,
                pagenum,
                isstop,
                begindate,
                enddate,
                ustr,
                gongsiname
            });
            let mlist = [];
            this.orderListAll = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {

                this.totalSize = parseInt(mlist[0].totalcount);
                this.orderListAll = mlist;
                this.stopcount = parseInt(mlist[0].stopcount)
                //console.log(this.orderListAll)

            } else {
                this.totalSize = 0;
                this.stopcount = 0

            }
            this.loading = false;
        }
    },
    mounted() {
        //this.gettasktablelistdata('0000400002');
        //this.gettreelist();
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.value2 = [start, end];
        this.upweburl = weburl;
        this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 680;
        this.refrashdate();
    }
};
</script>
<style lang='less' scoped>
.isselected {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #409EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    font-size: 10px;
}

.isnoselected {
    font-size: 10px;
    color: #303133;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #C0C4CC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnRow {
    display: block;
}

.unBtnRow {
    display: none;
}

.issueContainer {
    .issue {
        margin: 1px auto;
    }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 260px;
}

.table-wrapper {
    height: calc(100% - 32px);
}

avatar-uploader .el-upload {
    border: 1px dashed #af1414;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
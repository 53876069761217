<template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;">
    <div style="display: flex;width:50%">
        <el-table :data="taskinfodata" tooltip-effect="dark" :indent=30 row-key="id" id="tableId" default-expand-all
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                    >
                    <el-table-column label="序号" width="90" v-if="false">
                        <template slot-scope="scope">{{ scope.row.id }}</template>
                    </el-table-column>
                    <el-table-column prop="TaskCode" label="报表名称" width="100" v-if="false">
                    </el-table-column>
                    <el-table-column prop="parentname" label="是否显示" width="100" v-if="false">
                    </el-table-column>
                    <el-table-column prop="TaskName" label="创建日期">
                    </el-table-column>
                    <el-table-column prop="btimer" label="创建人" width="100">
                    </el-table-column>
                    <el-table-column prop="htime" label="是否启用" width="100">
                    </el-table-column>
                     
                    <el-table-column label="" width="240">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary"
                                @click="chakantaskitem(scope.$index, scope.row)">查看</el-button>
                            <el-button size="mini" type="danger"
                                @click="deletetaskitem(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
    </div>
    <div style="display: flex;width:50%">

    <el-table ref="MFieldData" :data="MFieldData" tooltip-effect="dark" >
                    <el-table-column label="序号" width="50">
                        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                    </el-table-column>
                    <el-table-column prop="displayname" label="显示名称" width="200">
                        <template slot-scope="scope">
                            <el-input type="textarea" autosize placeholder="不能为空" v-model="scope.row.displayname">
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ftype" label="类型" width="120">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.ftype" placeholder="请选择类别，不能为空">
                                <el-option label="文本" value="文本"></el-option>
                                <el-option label="日期" value="日期"></el-option>
                                <el-option label="选项" value="选项"></el-option>
                                <!-- <el-option label="选择框" value="选择框"></el-option> -->
                                <el-option label="图片" value="图片"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="listitem" label="列表值" width="300">
                        <template slot-scope="scope">
                            <el-input type="textarea" autosize placeholder="如过类型为选项时，此项不能为空" v-model="scope.row.listitem">
                            </el-input>

                        </template>
                    </el-table-column>
                    <el-table-column prop="isinput" label="" width="160">
                        <template slot-scope="scope">
                            <el-switch style='zoom:.8;' v-model="scope.row.isinput" active-text="考核" inactive-text="非考核">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isvisible" label="" width="160">
                        <template slot-scope="scope">
                            <el-button type="danger" @click="deldisplayset(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="" align="center" width="150px" fixed="right">
                                <template slot-scope="scope">
                                   
                                    <el-button class="operatBtn" size="mini" icon="el-icon-bottom"
                                               :disabled="scope.$index===(MFieldData.length-1)"
                                               @click="moveDown(scope.$index,scope.row)">
                                    </el-button>
                                    <el-button class="operatBtn" size="mini" icon="el-icon-top"
                                               :disabled="scope.$index===0"
                                               @click="moveUp(scope.$index,scope.row)">
                                    </el-button>                                  
                                </template>
                            </el-table-column>
                </el-table>
            </div>
    </div>
</template>
<script>
import { GETTASKTREELIST, INSTASKINFO, GETTASKLIST, SETDEFAULTVALUE, GETTASKDISSET, DELTASKITEM,GETDEFCOMLISTVALUE,GETTASKTREELISTFLOW,INSCHECKITEM } from "@/api";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
    data() {
        return {
            MFieldData:[]
        };
    },
    methods: {
     
    },
    mounted() {
        
    },

};
</script>
<style lang='less' scoped>  

</style>
<template>
  <div class="issueContainer">
    <div style="display: flex;justify-content: right;">
      <el-button @click="backnativepage"  icon="el-icon-back">返回</el-button>
    </div>
    <div class="issue w">
      <el-form
        :model="ruleForm"
        :rules="rules"
        size="small"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
      <div>
        <el-form-item label="产品编号" prop="title">
          <el-input :disabled="true" v-model="prodcode"></el-input>
        </el-form-item>    
        <el-form-item label="产品名称" prop="title">
          <el-input :disabled="true" v-model="prodname"></el-input>
        </el-form-item>  
      </div>
      <div style="display: flex;">
        <el-form-item label="菜单名称" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="" prop="title">
          <el-switch v-model="isstop" active-text="启用" inactive-text="禁用">
                    </el-switch> 
        </el-form-item>           
      </div>
        <!-- <el-form-item label="封面图片" prop="coverImg">
          <el-upload
            class="upload-demo"
            drag
            action="http://119.27.170.129:58084/api/ycshop/UpLoadFile"
            multiple
            v-model="ruleForm.coverImg"
            :on-success="uploadSuccess"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb,只可上传一张!上传图片尺寸为：
              250*140px像素。
            </div>
          </el-upload>
        </el-form-item> -->
        <el-form-item label="内容" prop="content">
          <Wang-editor
            v-if="formData.isupEditor"
            ref="editor"
            :value="ruleForm.content"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >发布</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import WangEditor from "@/components/WangEditor";
import { savenewsinfo,INSDISPROD,UpTaskFile } from "@/api";
export default {
  components: { WangEditor },
  data() {
    return {
      isstop:true,
      prodcode:'',
      prodname:'',
      ruleForm: {
        title: "",
        coverImg: "",
        content: "",
      },
      formData: {
        articleText: "",
        isupEditor: true 
      },
      rules: {
        title: [{ required: true, message: "菜单名称不能为空", trigger: "blur" }],
        // coverImg: [
        //   { required: true, message: "请上传封面图片", trigger: "blur" },
        // ],
        content: [
          {
            required: true,
            message: "请编辑内容详情",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    backnativepage(){
      this.$router.push('/ProductInfo', { params: { flag: '2' } });
    },
    submitForm(formName) {
      this.ruleForm.content = this.$refs.editor.info_;
      
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.$route.query.id) {
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            const { data: res } = await INSDISPROD({
              usercode,
              token,
              mguid: "",
              menuname: this.ruleForm.title,
              prodcode: 'XF5322224',
              discontent: this.ruleForm.content 
            });
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
              this.$notify.error({
                title: "错误",
                message: result.message,
                duration: 1000,
              });
            } else {
              this.$notify({
                title: "成功",
                message: result.message,
                type: "success",
                duration: 2000,
              });
              //this.$router.push("/journalism");
            }
          } else {
            let usercode = window.sessionStorage.getItem("usercode");
            let token = window.sessionStorage.getItem("token");
            const { data: res } = await INSDISPROD({
              usercode,
              token,
              mguid: '',
              menuname: this.ruleForm.title,
              prodcode: 'XF5322224',
              discontent: this.ruleForm.coverImg  
            });
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
              this.$notify.error({
                title: "错误",
                message: result.message,
                duration: 1000,
              });
            } else {
              this.$notify({
                title: "成功",
                message: result.message,
                type: "success",
                duration: 2000,
              });
              //this.$router.push("/journalism");
            }
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    uploadSuccess(response) {
      this.ruleForm.coverImg =
        "http://192.168.13.106:58084/Uploads/" + response;
    },
    async getnewsinfoList(id) {
      if (this.$route.query.id) {
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        const { data: res } = await getnewsinfo({
          usercode,
          token,
          id,
        });
        const result = JSON.parse(res)[0];
        this.ruleForm.content = result.contents;
        this.ruleForm.title = result.mtitle;
        this.ruleForm.coverImg = result.titlepic;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.prodcode=this.$route.query.prodcode;
    this.prodname=this.$route.query.prodname;
    //console.log('我的页面接收参数：',this.$route.query.flag);
    //this.getnewsinfoList(this.$route.query.id);
  },
};
</script>
<style lang='less' scoped>
.issueContainer {
  .issue {
    margin: 40px auto;
  }
}
</style>
<template>
  <div>
    <div class="personalContainer">
      <div class="personal w">
        <div class="personalTab" id="personalTab">
          <div class="list">
            <ul>
              <li class="listActive">个人中心</li>
              <li class="listItem" @click="listChange">
                <router-link to="/personal/userinfo">用户信息</router-link>
              </li>
              <li class="listItem" @click="listChange">
                <router-link to="/personal/billinginfo"> 开票信息 </router-link>
              </li>
              <li class="listItem" @click="listChange">
                <router-link to="/personal/goodsinfo"> 收货信息 </router-link>
              </li>
            </ul>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
      activeName: "user",
    };
  },
  methods: {
    listChange(e) {
      e.target.classList.add("active");
    },
    aActiveChange() {
      let listS = document.querySelectorAll(".personalTab .listItem a");
      listS.forEach((item) => {
        item.style.color = "#333333";
      });
      if (this.$route.path === "/personal/userinfo") {
        listS[0].style.color = "#ee5d62";
      } else if (this.$route.path === "/personal/billinginfo") {
        listS[1].style.color = "#ee5d62";
      } else if (this.$route.path === "/personal/goodsinfo") {
        listS[2].style.color = "#ee5d62";
      }
    },
  },
  mounted() {
    this.aActiveChange();
  },
  // 检测路由变化
  watch: {
    $route() {
      this.aActiveChange();
    },
  },
};
</script>
<style lang='less' scoped>
.personalContainer {
  margin-top: 40px;
  .personal {
    .personalTab {
      display: flex;
      .list {
        margin-right: 20px;
        .listActive {
          background: #f3f4f6;
        }
        .listItem {
          border-top: 0;
        }
        .listItem a {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        .listItem a.active {
          color: #ee5d62 !important;
        }
      }
      ul li {
        border: 1px solid #ebebeb;
        width: 150px;
        height: 35px;
        line-height: 35px;
        text-align: center;
      }
    }
  }
}
</style>
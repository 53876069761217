<template>
  <div>
    <div class="header">
      <div class="clearfix w headerBox">
        <div class="h-left">珠海市华亚机械科技有限公司采购电子签核系统</div>
        <div class="h-right">
          <div style="width: 100%;display:flex">
            <!-- <img  style="width: 50px;height: 50px;" src="./images/11.png"/> -->
            <!-- <el-avatar shape="circle" src="./images/11.png" :size="50"></el-avatar> -->
            <div style="display: flex;align-items: center;justify-content: center;">
            <div class="userImg_user">
              <img style="height: 100%;" src="./images/11.png" />
            </div>
          </div>
            <div style=" font-size: 12px;
            margin-left: 12px;
            color: #fff;
            font-weight: 100;">
             系统管理员
            </div>
             
            <div style=" font-size: 12px;
            margin-left: 30px;
            color: #fff;
            font-weight: 100;">
                修改密码
              </div>

              <div style=" font-size: 12px;
            margin-left: 30px;
            color: #fff;
            font-weight: 100;">
                退出系统
              </div>
              <div style=" font-size: 12px;
            margin-left: 30px;
            color: #fff;
            font-weight: 100;">
                修改密码
              </div>
 
              <div style=" font-size: 12px;
            margin-left: 30px;
            color: #fff;
            font-weight: 100;">
                权限设置
              </div>
          </div>
        </div>
        <!-- <div class="h-right">
          <div class="userInfo">
            <div class="userImg">
              <i class="el-icon-user"></i>
            </div>
            <div class="name">{{ userData.bfullname }}</div>
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <i class="el-icon-arrow-up"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <router-link to="/Personal"
                  ><el-dropdown-item> 个人中心 </el-dropdown-item></router-link
                >
                <router-link to="/pwdchange">
                  <el-dropdown-item>修改密码</el-dropdown-item></router-link
                >
                <el-dropdown-item>
                  <span @click="loginoutChange">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="yfmoneyTitle">
              账户余额：<span class="yfmoney">{{
                parseFloat(userData.yfmoney).toFixed(2)
              }}</span>
            </div>
            <div class="shop-btn" @click="goToPurchaseNote">
              <el-badge :value="$store.state.shopNum" class="item">
                <div class="shopBtn">
                  <img src="./images/top.png" alt="" />
                  <span>采购单</span>
                </div>
              </el-badge>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { issueRouter } from "@/router";
import { getUserInfo, loginout } from "@/api";
export default {
  data() {
    return {
      userData: {},
      shopNum: 0,
    };
  },
  methods: {
    goToPurchaseNote() {
      this.$router.push("/purchase");
    },
    // 获取用户信息
    async userInfo() {
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      const { data: res } = await getUserInfo({
        usercode,
        token,
      });
      this.userData = JSON.parse(res)[0];
      if (this.userData) {
        this.$store.state.btypeinfo = this.userData;
        console.log('客户信息', this.$store.state.btypeinfo);
        if (this.userData.bfullname === "系统管理员") {
          this.$store.commit("isIssueChange", true);
          this.$router.addRoutes(issueRouter);
        } else {
          this.$store.commit("isIssueChange", false);
        }
      }
    },
    // 登出
    async loginoutChange() {
      let usercode = window.sessionStorage.getItem("usercode");
      let token = window.sessionStorage.getItem("token");
      const { data: res } = await loginout({ usercode, token });
      let result = JSON.parse(res)[0];
      // console.log(result);
      if (result.code !== 200) {
        this.$notify.error({
          title: "错误",
          message: "退出失败,请重试!",
          duration: 1000,
        });
      } else {
        // this.$notify({
        //   title: "成功",
        //   message: result.message,
        //   type: "success",
        // });
        this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
        window.sessionStorage.removeItem("usercode");
        window.sessionStorage.removeItem("token");
        this.$store.commit("isIssueChange", false);
        this.$router.push("/login");
      }
    },
    // 优化 判断是否有用户信息 有则不发请求
    // ifUser() {
    //   let userinfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
    //   if (userinfo) {
    //     this.userData = userinfo;
    //     if (this.userData.bfullname === "管理员") {
    //       this.$store.commit("isIssueChange");
    //       this.$router.addRoutes(issueRouter);
    //     }
    //   } else {
    //     this.userInfo();
    //   }
    // },
  },
  mounted() {
    this.userInfo();
  },
  watch: {
    $route() {
      // 防止进来没数据
      if (this.$route.path === "/journalism") {
        this.userInfo();
      }
    },
  },
};
</script>
<style lang='less' scoped>
.header {
  height: 50px;
  background: #14aafb;
  line-height: 50px;

  .yfmoneyTitle {
    margin-left: 25px;
    color: #f6f6f6;
    font-size: 16px;

    .yfmoney {
      color: #e4040b;
    }
  }

  .headerBox {
    height: 55px;

    .shop-btn {
      float: left;
    }
  }

  .h-left {
    float: left;
    height: 100%;
    color: #eeecec;
    font-size: 20px;
    width: 50%;
  }
  .userImg_1 {
    // border-radius: 100%;
    // overflow: hidden;
    width: 20px;
    height: 20px;

  }
  .userImg_user {
    border-radius: 100%;
    overflow: hidden;
    width: 40px;
    height: 40px;

  }

  .userImg_user image {

    height: 100%;
  }

  .h-right {
    width: 50%;
    float: right;
    height: 100%;

    .userInfo {
      align-items: center;
      display: flex;

      .userImg {
        border: 1px solid #ccc;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name {
        margin: 0 10px;
        font-size: 18px;
      }

      .shop-btn {
        margin-left: 25px;
        width: 120px;

        .shopBtn {
          display: flex;
          width: 120px;
          height: 32px;
          align-items: center;
          justify-content: center;
          background: #f20d14;
          border-radius: 4px;
          cursor: pointer;
          line-height: 12px;

          img {
            margin-right: 3px;
          }

          span {
            font-size: 17px;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
}</style>
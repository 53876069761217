import Vue from "vue";
import VueRouter from 'vue-router'
// 使用插件
Vue.use(VueRouter)

// 引入路由组件
import Journalism from '@/pages/Journalism'
import Personal from '@/pages/Personal'
import Login from '@/pages/Login'
import PwdChange from '@/pages/PwdChange'
import User from '@/pages/Personal/User'
import Issue from '@/pages/Issue'
import MuserInfo from '@/pages/MuserInfo'
import DFormSet from '@/pages/DFormSet'  
import DesignReport from '@/pages/DesignReport'  
import LogList from '@/pages/LogList'  
import ProductInfo from '@/pages/ProductInfo'  
import BarcodeList from '@/pages/BarcodeList'  
import ApplyOpenList from '@/pages/ApplyOpenList'  
import Nbarcodelist from '@/pages/Nbarcodelist'  
import Companeyinfo from '@/pages/Companeyinfo' 
import Ruleauth from '@/pages/Ruleauth' 
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace

// 重写push|replace
VueRouter.prototype.push = function (location, resolve, reject) {
	if (resolve && reject) {
		originPush.call(this, location, resolve, reject)
	} else {
		originPush.call(this, location, () => { }, () => { })
	}
}
VueRouter.prototype.replace = function (location, resolve, reject) {
	if (resolve && reject) {
		originReplace.call(this, location, resolve, reject)
	} else {
		originReplace.call(this, location, () => { }, () => { })
	}
}

// 普通路由
export const ordinaryRouter = [{
	path: '*',
	redirect: '/login'
},
{
	path: '/journalism',
	component: Journalism,
	redirect: '/ProductInfo',
	meta: {
		show: true
	},
	children: [
		{
			path: '/Ruleauth',
			component:Ruleauth,
		  },
		{
			path: '/Companeyinfo',
			component:Companeyinfo,
		  },
		 {
			path: '/Nbarcodelist',
			component:Nbarcodelist,
		  },
		  {
			path: '/ApplyOpenList',
			component: ApplyOpenList,
		  },		  
		  {
			path: '/Issue',
			component: Issue,
		  },
		  {
			path: '/LogList',
			component: LogList,
		  },
		  {
			path: '/BarcodeList',
			component: BarcodeList,
		  },		  
		  {
			path: '/ProductInfo',
			component: ProductInfo,
		  },
		  {
			path: '/DesignReport',
			component: DesignReport,
		  },		  
		  
		{
			path: '/DFormSet',
			component: DFormSet,
		  },
		{
		  path: '/MuserInfo',
		  component: MuserInfo,
		}
	  ]
},

{
	path: '/ProductInfo',
	component: ProductInfo,
	meta: {
		show: true
	}
},
{
	path: '/Ruleauth',
	component: Ruleauth,
	meta: {
		show: true
	}
},
{
	path: '/Companeyinfo',
	component: Companeyinfo,
	meta: {
		show: true
	}
},
{
	path: '/DesignReport',
	component: DesignReport,
	meta: {
		show: true
	}
},
{
	path: '/ApplyOpenList',
	component: ApplyOpenList,
	meta: {
		show: true
	}
},
{
	path: '/Nbarcodelist',
	component:Nbarcodelist,
	meta: {
		show: true
	}
},
{
	path: '/BarcodeList',
	component: BarcodeList,
	meta: {
		show: true
	}
},
 

{
	path: '/LogList',
	component: LogList,
	meta: {
		show: true
	},
},
{
	path: '/DFormSet',
	component: DFormSet,
	meta: {
		show: true
	},
},

{
	path: '/MuserInfo',
	component: MuserInfo,
	meta: {
		show: true
	}
},
 
{
	path: '/personal',
	component: Personal,
	meta: {
		show: true
	},
	redirect: '/personal/userinfo',
	children: [{
		path: 'userinfo',
		component: User,
		meta: {
			show: true
		},
	},

	]
},
{
	path: '/login',
	component: Login,
	meta: {
		show: false
	}
},
{
	path: '/pwdchange',
	component: PwdChange,
	meta: {
		show: true
	}
},

{
	path: '/MuserInfo',
	component: MuserInfo,
	meta: {
		show: true
	}
}
]

// 权限路由
// export const orderRouter = [{
// 	path: '/orderpay',
// 	component: orderPay,
// 	meta: {
// 		show: true
// 	}
// },]
export const issueRouter = [{
	path: '/issue',
	component: Issue,
	meta: {
		show: true
	}
}]

// export const sunmitOrderRouter = [{
// 	path: '/sunmitorder',
// 	component: sunmitOrder,
// 	meta: {
// 		show: true
// 	}
// }]

// 配置路由
let router = new VueRouter({
	routes: ordinaryRouter,
	mode: 'history'
})

// 路由守卫
router.beforeEach((to, from, next) => {
	if (sessionStorage.getItem('token')) {
		if (to.path === '/login') {
			next('/journalism');
		} else {
			next();
		}
	} else {
		if (to.path === '/login') {
			next();
		} else {
			next('/journalism');
		}
	}
})

export default router

// 按需导入element-ui
import Vue from 'vue'
import {
    Button, Badge, MenuItem, Menu, Table, TableColumn, Pagination
    , Notification, InputNumber, Radio, RadioGroup, Empty, Message
    , Form, FormItem, Select, Option, Col, Submenu, MenuItemGroup, MessageBox,
    Dropdown, DropdownItem, DropdownMenu, Tabs, TabPane, Card, Input,
    Upload, Timeline, TimelineItem, Dialog, Loading, Cascader, DatePicker,Tree,Switch,Progress,Breadcrumb,
    BreadcrumbItem,RadioButton,scrollbar,Avatar,Tag,Image,Checkbox,CheckboxGroup
} from 'element-ui'


Vue.prototype.$ELEMENT = { size: 'mini', zIndex: 3000 };
Vue.use(Button)
Vue.use(Badge)
Vue.use(Tag)
Vue.use(MenuItem)
Vue.use(Menu)
Vue.use(Image)
Vue.use(Progress)
Vue.use(CheckboxGroup)
Vue.use(Table)
Vue.use(Avatar)
Vue.use(TableColumn)
Vue.use(scrollbar)
Vue.use(RadioButton)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Checkbox)
Vue.use(Option)
Vue.use(Col)
Vue.use(Switch)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Empty)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Dialog)
Vue.use(Tree)
Vue.use(Loading.directive);
Vue.use(Cascader)

Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading.service;


// APi接口进行统一管理QueryPoOrderListgetUserInfo
import requests from "./request";
import requestsnoproess from "./requestnoprogess";
import requestsupproess from "./uprequest";

//厦门溢彩'http://119.27.170.129:58084/api'

//let url='http://120.197.173.43:58085/api'
//let url = 'http://192.168.1.56:58084/api'
//let url = 'http://192.168.1.247:58084/api'
//let url = 'http://163.179.126.97:58084/api'
//export const weburl = 'http://120.197.173.43:58085/' + "Uploads/"
// export const weburl = 'http://192.168.13.106:58084/api/ycshop/UpTaskFile'
//   export const webloadurl = 'http://192.168.13.106:58084/Uploads/'
//   let url = 'http://192.168.13.106:58084/api'
//   export const weburl = 'http://192.168.13.106:58084/api/ycshop/UpTaskFile'
//   export const icourl='http://192.168.13.106:58084/Icons/'
//   export const upicofiles='http://192.168.13.106:58084/api/ycshop/UpIcoFile'

export const weburl = 'http://47.115.211.168:58084/api/ycshop/UpTaskFile'
export const webloadurl = 'http://47.115.211.168:58084/Uploads/'
let url = 'http://47.115.211.168:58084/api' 
export const icourl='http://47.115.211.168:58084/Icons/'
export const upicofiles='http://47.115.211.168:58084/api/ycshop/UpIcoFile'

// 获得标签池标签规则列表
export const Qbarrulelistitem = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/QBARRULELISTITEM', method: 'post', data: params })

// 获得标签池导出标签信息
export const QEMPORTEXCELINFO = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/QEMPORTEXCELINFO', method: 'post', data: params })
// 删除小程序菜单图标
export const DELIcoFile = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/DELIcoFile', method: 'post', data: params })
// 获得小程序菜单图标
export const GETICOINFOLIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/GETICOINFOLIST', method: 'post', data: params })
// 获得页面按钮权限
export const QBUTONAUTHOR = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/QBUTONAUTHOR', method: 'post', data: params })
// 保存主页面菜单列表
export const QPAGEAUTHLIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/QPAGEAUTHLIST', method: 'post', data: params })
// 保存角色对应权限列表
export const UPRULELISTINFO = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/UPRULELISTINFO', method: 'post', data: params })

// 获得角色对应权限列表
export const GETAUTHRULELIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/GETAUTHRULELIST', method: 'post', data: params })

 
// 获得角色列表
export const GETRULELIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/GETRULELIST', method: 'post', data: params })

// 新增角色信息
export const INSRULELIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/INSRULELIST', method: 'post', data: params })

// 查询公司信息
export const GETCOMPANEYLIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/GETCOMPANEYLIST', method: 'post', data: params })

// 新增公司信息
export const INSCOMPAEYINFO = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/INSCOMPAEYINFO', method: 'post', data: params })


// 匹配条码--商品
export const COMPAREPRODBAR = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/COMPAREPRODBAR', method: 'post', data: params })

// 查询标签池明细数据
export const GETNBARCODELISTDETAIL = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/GETNBARCODELISTDETAIL', method: 'post', data: params })

// 批量删除标签池
export const BATCHSTOPBARCODELIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/BATCHSTOPBARCODELIST', method: 'post', data: params })
// 查询标签池
// 插入修改删除标签池
export const INSNBARCODELIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/INSNBARCODELIST', method: 'post', data: params })
// 查询标签池
export const GETNBARCODELIST = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/GETNBARCODELIST', method: 'post', data: params })
// 登录接口
// 批量删除二维码
export const BATCHSTOPBARCODE = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/BATCHSTOPBARCODE', method: 'post', data: params })
// 登录接口
export const Login = (params) =>
    // 发请求 axios发请求返回的是promise对象
    requests({ url: url + '/ycshop/checkLogin', method: 'post', data: params })

    // 刷新标题标签数量 
export const getUserInfoss = (params) =>
requestsnoproess({ url: url + '/ycshop/getcustinfo', method: 'post', data: params })
// 获取用户基本信息 
export const getUserInfo = (params) =>
    requests({ url: url + '/ycshop/getcustinfo', method: 'post', data: params })
// 通过商品类别、名称、尺寸获得商品的颜色列表 
export const getPcolorlist = (params) =>
    requests({ url: url + '/ycshop/getsizecolorlist', method: 'post', data: params })
// 新增获得商品展示信息 
export const getShowPlist = (params) =>
    requests({ url: url + '/ycshop/getshowplist', method: 'post', data: params })
// 新增获得商品内部sku 
export const getShowPtypeid = (params) =>
    requests({ url: url + '/ycshop/getskuid', method: 'post', data: params })

// 商品材质列表、品牌列表
export const getcomlist = (params) =>
    requests({ url: url + '/ycshop/getcomlist', method: 'post', data: params })
 
// 修改密码
export const changepwd = (params) =>
    requests({ url: url + '/ycshop/changepwd', method: 'post', data: params })


// 登出
export const loginout = (params) =>
    requests({ url: url + '/ycshop/loginout', method: 'post', data: params })
 
// 保存用户信息
export const saveuserinfo = (params) =>
    requests({ url: url + '/ycshop/saveuserinfo', method: 'post', data: params })
// 保存OA用户信息
export const queryOAuserinfolist = (params) =>
    requests({ url: url + '/ycshop/QUERYOAUSERINFOLIST', method: 'post', data: params })
// 查询OA用户列表
export const saveOAuserinfo = (params) =>
    requests({ url: url + '/ycshop/saveoauserinfo', method: 'post', data: params })
 
// 查询部门人员列表
export const QUERYDEPARTPERSONLIST = (params) =>
    requests({ url: url + '/ycshop/QUERYDEPARTPERSONLIST', method: 'post', data: params })
// 查询部门列表
export const QUERYDEPARTLIST = (params) =>
    requests({ url: url + '/ycshop/QUERYDEPARTLIST', method: 'post', data: params })
  
// 更改密码
export const OACHANGEPWD = (params) =>
    requests({ url: url + '/ycshop/OACHANGEPWD', method: 'post', data: params })

// 文件上传
export const UpLoadFile = (params) =>
    requests({ url: url + '/ycshop/UpLoadFile', method: 'post', data: params })

// 删除文件
export const UpDelFile = (params) =>
    requests({ url: url + '/ycshop/UpDelFile', method: 'post', data: params })

// 导入excel
export const IMPORTEXCEL = (params) =>
    requests({ url: url + '/ycshop/IMPORTEXCEL', method: 'post', data: params })
  
    
// 获得操作日志
export const GETCOMPUTLOG = (params) =>
    requests({ url: url + '/ycshop/GETCOMPUTLOG', method: 'post', data: params })
    
    
// 保存标题信息
export const INSTITLENAME = (params) =>
    requests({ url: url + '/ycshop/INSTITLENAME', method: 'post', data: params })

// 保存产品信息
export const INSPTYPEINFO = (params) =>
    requests({ url: url + '/ycshop/INSPTYPEINFO', method: 'post', data: params })  
// 查询产品信息
export const QSKULIST = (params) =>
requests({ url: url + '/ycshop/QSKULIST', method: 'post', data: params }) 
// 生成编码
export const MABARCODELIST = (params) =>
requests({ url: url + '/ycshop/MABARCODELIST', method: 'post', data: params }) 
// 保存信息内容展示
export const INSDISPROD = (params) =>
requests({ url: url + '/ycshop/INSDISPROD', method: 'post', data: params })   
// 文件上传
export const UpTaskFile = (params) =>
requestsupproess({ url: url + '/ycshop/UpTaskFile', method: 'post', data: params }) 

// 获得产品展示菜单信息
export const QRYPRODDESLIST = (params) =>
requests({ url: url + '/ycshop/QRYPRODDESLIST', method: 'post', data: params }) 
// 获得产品条码信息
export const QRYBARLIST = (params) =>
requests({ url: url + '/ycshop/QRYBARLIST', method: 'post', data: params })   
// 获得产品条码信息
export const UPBARCODEDATE = (params) =>
requests({ url: url + '/ycshop/UPBARCODEDATE', method: 'post', data: params })  

// 获得激活申请列表
export const GETAPPLYOPEN = (params) =>
requests({ url: url + '/ycshop/GETAPPLYOPEN', method: 'post', data: params }) 

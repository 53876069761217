<template>
    <div style="display: flex;width: 100%;height: 100%;justify-content: center;overflow: hidden;">
        <div class="testbox" style="width: 100%; display: flex; flex-direction: column; overflow: hidden;">

            <div style="background-color: rgb(251, 251, 251);width: 100%;height: auto;display: flex;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="日期范围">
                        <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels
                            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="产品类别">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="chanpinleibie" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="产品名称">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="chanpinmingcheng" clearable></el-input>
                    </el-form-item>
                   
                    <el-form-item style="margin-left: 10px;">
                        <el-button type="primary" plain icon="el-icon-search" @click="refrashdate">搜索</el-button>
                        <!-- <el-button type="primary" @click="refrashdate">查询</el-button>  el-icon-folder-add-->
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain icon="el-icon-folder-add" @click="addptypeinfo">导出</el-button>
                        <!-- <el-button type="primary" @click="refrashdate">新增</el-button> -->
                    </el-form-item>
                    <el-form-item v-if="checkvisible('状态更改')">
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="upbatchstop(0)">批量停用</el-button>
                         
                    </el-form-item>     
                    <el-form-item  v-if="checkvisible('删除')">
                        <el-button type="danger" plain icon="el-icon-folder-add" @click="upbatchstop(1)">批量删除</el-button>
                         
                    </el-form-item>               
                    <el-form-item>
                        <el-button type="text" size="mini" @click="closeSearch">
                            {{ wordcaption }}
                            <i :class="showAll ? 'el-icon-arrow-up ' : 'el-icon-arrow-down'"></i>
                        </el-button>
                        <!-- <span style="color: rgb(195, 195, 195);font-size: 10px;">展开搜索</span> -->
                    </el-form-item>
                    <el-form-item>
                        <!-- <el-button icon="el-icon-printer" @click="batchprint">批量打印</el-button>
                        <el-button type="primary" @click="refrashdate">新增</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div v-if="showAll" style="background-color: rgb(251, 251, 251);width: 100%;height: 40px;display: flex;">
                <el-form :inline="true" class="demo-form-inline">

                    <!-- <el-form-item style="font-size: 10px;color: red;" label="数据检索">
                        <el-input placeholder="关键字检索" v-model="Q_prodcode"></el-input>
                    </el-form-item> -->
                    <el-form-item style="font-size: 10px;color: red;" label="内码编号">
                        <el-input style="width: 260px;" placeholder="关键字检索" v-model="neimabianhao" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="条码编号">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="tiaomabianhao" clearable ></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="产品编号">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="chanpinbianhao" clearable></el-input>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="规格型号">
                        <el-input style="width: 120px;" placeholder="关键字检索" v-model="guigexinghao" clearable></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <div   style="background-color: rgb(251, 251, 251);width: 100%;height: 20px;display: flex;justify-content:right;">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(4)" :class="isdaijihuo ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">待激活</span>
                        </div>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(3)" :class="iszhibao ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">质保中</span>
                        </div>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;" label="">
                        <div @click="isselect(0)" :class="isshixiao ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">已失效标签</span>
                        </div>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="">
                        <div @click="isselect(1)" :class="isguoqi ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">已过保标签</span>
                        </div>
                    </el-form-item>
                    <el-form-item style="font-size: 10px;color: red;" label="">
                        <div @click="isselect(2)" :class="isshanchu ? 'isselected' : 'isnoselected'">
                            <span style="font-size: 10px;cursor: pointer;">已停用标签</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>

            <div class="table-wrappers" style="width: 100%; flex: 1; overflow: hidden;">
                <el-table v-loading="loading" element-loading-text="数据正在加载中..." size="small" :data="orderListAll"
                    style="width: 100%" height="100%" class="table" ref="table" @cell-click="cellClick"
                    :header-cell-style="{ background: 'rgb(251, 251, 251)', color: '#303133' }" border
                    @selection-change="handleSelectionChange" :selectable="row => row.enabled !== false">
                    <!-- <el-table-column type="selection">

                </el-table-column> -->
                    <el-table-column fixed type="selection" width="35">

                    </el-table-column>
                    <el-table-column fixed   label="" width="50">
                        <template slot-scope="scope">
                            <p>{{ scope.$index + 1 }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column sortable fixed prop="makedate" label="建立日期" width="120">
                    </el-table-column>
                    <el-table-column sortable fixed prop="prodtype" label="产品类别" width="100">
                    </el-table-column>
                    <el-table-column sortable prop="prodcode" label="产品编号" width="140">
                    </el-table-column>
                    <el-table-column sortable prop="prodname" label="产品名称" width="140">
                    </el-table-column>
                    <el-table-column sortable prop="proddes" label="规格型号" width="150">
                    </el-table-column>
                    <el-table-column sortable prop="barocde" label="条码编号" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="barcoderule" style="color: red;" label="状态" width="70">
                        <template slot-scope="scope">
                      
                            <el-switch :disabled="! checkvisible('状态更改')" @change="displaychangeisstop(scope.row)" style="font-size: 10px;"
                                v-model="scope.row.isstopflag" active-color="#909399" inactive-color="#13ce66" active-text=""
                                inactive-text="">
                            </el-switch>

                        </template>

                    </el-table-column>
                    <el-table-column sortable prop="mguid" style="color: red;" label="条码内码" width="220">
                        <template slot-scope="scope">
                            <span>{{ scope.row.mguid.toString().replace(/-/g, '') }} </span>
                            <i 
                            class="el-icon-tickets"
                            v-clipboard:copy="'https://qa.xinfei028.com/c/?id='+scope.row.mguid.toString().replace(/-/g, '')"
                            v-clipboard:success="onCopy"
  v-clipboard:error="onError"
                            ></i>
                            <el-tag style="margin-left:10px" size="mini" closable>{{ scope.row.bstate }}</el-tag>

                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="begindate" label="出厂日期" width="120">
                    </el-table-column>
                    <el-table-column prop="yxlevdays" label="有效剩余天数" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="qbdate" label="起保日期" width="120">
                    </el-table-column>
                    <el-table-column sortable prop="wblevdays" label="质保剩余天数" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.wblevdays<0?0:scope.row.wblevdays }}

                        </template>
                    </el-table-column>
 
                    <el-table-column fixed="right" label="操作" width="85">
                        <template slot-scope="scope">
                            <el-button  v-if="checkvisible('修改')" @click.native.prevent="chakan(scope.$index, scope.row)" type="text" size="small">
                                修改
                            </el-button>
 
                            <el-button v-if="scope.row.isstopflag && checkvisible('删除')" @click.native.prevent="uponlystop(scope.row.mguid)"
                                type="text" size="small">
                                <p style="color: red;">删除</p>
                            </el-button>
 
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="display: flex;justify-content: center;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="index"
                    :page-sizes="[50, 200, 500]" :page-size="pageSize"
                    layout="prev, pager, next, jumper, sizes , total" :total="totalSize" prev-text="上一页" next-text="下一页">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="详情编辑" :visible.sync="dialog_taskinfo" width="60%" :close-on-click-modal="false"
            :destroy-on-close="true">

            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">类别</div>
                <div style="width: 80%;"><el-input disabled v-model="formdata.prodtype" placeholder="非空字段"></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品名称</div>
                <div style="width: 80%;"><el-input disabled v-model="formdata.prodname" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品编号</div>
                <div style="width: 80%;"><el-input disabled v-model="formdata.prodcode" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">规格型号</div>
                <div style="width: 80%;"><el-input disabled v-model="formdata.proddes" placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">编码规则</div>
                <div style="width: 80%;"><el-input disabled v-model="formdata.barcoderule" placeholder=""></el-input></div>
            </div>     
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">出厂日期</div>
                <div style="width: 80%;"> <el-date-picker v-model="begindate" type="date" placeholder="选择日期">
                    </el-date-picker></div>
 
            </div>                  
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">质保天数</div>
                <div style="width: 20%;"><el-input :disabled="wbd"  type="number" v-model="formdata.wbdays"
                        placeholder=""></el-input></div>
                        <div style="width: 10%;">质保月数</div>
                <div style="width: 20%;"><el-input :disabled="wbm"  type="number" v-model="formdata.wbmonth"
                        placeholder=""></el-input></div>
            </div>
                
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <!-- <div style="width: 10%;">编码规则</div>
                <div style="width: 30%;"><el-input disabled v-model="formdata.barcoderule" placeholder=""></el-input></div> -->
                <div style="width: 10%;">二维码有效期</div>
                <div style="width: 80%;"><el-input type="number" v-model="formdata.yxdays" placeholder=""></el-input></div>
            </div>
 
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->
                <el-button type="primary" @click="saveformdata">保存</el-button>
                <!-- <el-button type="danger" @click="saveformdata(1)">删除</el-button> -->
                <el-button @click="dialog_taskinfo = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="生成条码" :visible.sync="dialog_excelfile" width="60%" :close-on-click-modal="false">
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品名称</div>
                <div style="width: 72%;"><el-input disabled="true" v-model="formdata.prodname" placeholder=""></el-input>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">产品编号</div>
                <div style="width: 72%;"><el-input disabled="true" v-model="formdata.prodcode" placeholder=""></el-input>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">规格型号</div>
                <div style="width: 72%;"><el-input disabled="true" v-model="formdata.proddes" placeholder=""></el-input>
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">编码规则</div>
                <div style="width: 30%;"><el-input disabled="true" v-model="formdata.barcoderule" placeholder=""></el-input>
                </div>
                <div style="width: 10%;margin-left: 20px;">质保天数</div>
                <div style="width: 30%;"><el-input disabled="true" type="number" v-model="formdata.wbdays"
                        placeholder=""></el-input></div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">起始日期</div>
                <div style="width: 30%;"> <el-date-picker v-model="begindate" type="date" placeholder="选择日期">
                    </el-date-picker></div>
                <div style="width: 40%;color: rgb(44, 44, 246);margin-top: 3px;font-size: 8px;">该日期系统默认为条码生成日期,可更改可默认</div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;">截止日期</div>
                <div style="width: 30%;"> <el-date-picker v-model="enddate" type="date" placeholder="选择日期">
                    </el-date-picker></div>
                <div style="width: 45%;color: rgb(44, 44, 246);margin-top: 3px;font-size: 8px;">该日期系统默认为起始日期至质保天数后 ，可更改可默认
                </div>
            </div>
            <div style="width: 100%;display: flex;margin-top: 10px;">
                <div style="width: 10%;color: red;">生成份数</div>
                <div style="width: 72%;"><el-input type="number" v-model="printcount" placeholder=""></el-input></div>
            </div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->
                <el-button type="primary" @click="cccode">生成</el-button>
                <el-button type="danger" @click="_toExcel">导出</el-button>
                <el-button @click="dialog_excelfile = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="展示菜单页面" :visible.sync="dialog_editdisplay" width="60%" :close-on-click-modal="false">
            <div style="width: 100%;display: flex;margin-top: 3px;">
                <div style="width: 10%;background-color: rgb(251, 251, 251);">产品名称</div>
                <div style="width: 20%;"><el-input disabled="true" v-model="prodname" placeholder=""></el-input></div>
                <div style="width: 10%;margin-left: 10px;">产品编号</div>
                <div style="width: 20%;"><el-input disabled="true" v-model="prodcode" placeholder=""></el-input></div>
            </div>
            <el-table :data="menutabledata" style="width: 100%" @cell-click="cellClick"
                :header-cell-style="{ background: 'rgb(251, 251, 251)' }">
                <el-table-column fixed prop="menuname" label="菜单名称" width="100">
                </el-table-column>
                <el-table-column prop="isstop" label="是否显示" width="140">

                </el-table-column>
                <el-table-column prop="makedate" label="创建日期" width="140">
                </el-table-column>
                <el-table-column prop="makeperson" label="创建人" width="180">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="chakandisplay(scope.$index, scope.row)" type="text" size="small">
                            编辑
                        </el-button>
                        <el-button @click.native.prevent="submitForm('ruleForm', 1, scope.row)" type="text" size="small">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->
                <el-button type="primary" @click="addproddex">新增</el-button>

                <el-button @click="dialog_editdisplay = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="内容页面编辑" :visible.sync="dialog_editinfo" width="100%" :close-on-click-modal="false"
            :fullscreen="true">

            <div class="issueContainer">

                <div class="issue w">
                    <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-width="100px"
                        class="demo-ruleForm">
                        <div>
                            <el-form-item label="产品编号" prop="title">
                                <el-input :disabled="true" v-model="prodcode"></el-input>
                            </el-form-item>
                            <el-form-item label="产品名称" prop="title">
                                <el-input :disabled="true" v-model="prodname"></el-input>
                            </el-form-item>
                        </div>
                        <div style="display: flex;">
                            <el-form-item label="菜单名称" prop="title">
                                <el-input v-model="ruleForm.title"></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="title">
                                <el-switch v-model="isstop" active-text="启用" inactive-text="禁用">
                                </el-switch>
                            </el-form-item>
                        </div>
                        <!-- <el-form-item label="封面图片" prop="coverImg">
          <el-upload
            class="upload-demo"
            drag
            action="http://119.27.170.129:58084/api/ycshop/UpLoadFile"
            multiple
            v-model="ruleForm.coverImg"
            :on-success="uploadSuccess"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb,只可上传一张!上传图片尺寸为：
              250*140px像素。
            </div>
          </el-upload>
        </el-form-item> -->
                        <el-form-item label="内容" prop="content">
                            <Wang-editor v-if="formData.isupEditor" ref="editor" :value="ruleForm.content" />
                        </el-form-item>

                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="adddisplayitem()">继续新增</el-button> -->

                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>

                <el-button @click="dialog_editinfo = false">关闭</el-button>
            </div>

        </el-dialog>
        <el-dialog title="打印二维码" :visible.sync="dialog_print" width="40%" :close-on-click-modal="false">
            <div style="display: flex;">

                <div v-bind:id="'printarea-' + index" v-for="(items, index,) in printlist" :key="index"
                    style="margin-left: 10px;">
                    <div id="qqq" style="display:flex;justify-content:center;width: 100%;height: 90px;margin-top: 10px;">
                        <div v-bind:id="'item-' + index" style="margin:0 auto;">xx</div>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                        <h5>{{ items.barocde }}</h5>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button v-print="'#printarea'" type="primary">打印条码</el-button>
                <!-- <el-button type="primary" @click="billPrintClick">打印测试</el-button> 
                <el-button type="primary" @click="testprint">打印测试1</el-button>-->
                <el-button @click="dialog_print = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { weburl, INSPTYPEINFO, QSKULIST, MABARCODELIST,BATCHSTOPBARCODE, QRYPRODDESLIST, INSDISPROD, UpTaskFile, QRYBARLIST, UPBARCODEDATE } from "@/api";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import WangEditor from "@/components/WangEditor";
import QRCode from 'qrcodejs2';
import SearchButton from '@/components/SearchButton/index.vue';

export default {
    components: { WangEditor, SearchButton },
    name: "User",
    dicts: ['sys_normal_disable', 'sys_user_sex'],

    data() {
        return {
            wbd:false,
            wbm:false,
            loading: false,
            begindate: '',
            enddate: '',
            chanpinleibie: '',
            chanpinmingcheng: '',
            guigexinghao: '',
            tiaomabianhao: '',
            neimabianhao: '',
            chanpinbianhao: '',
            value1: '',
            value2: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            isdaijihuo:false,
            iszhibao:false,
            isshixiao: false,
            isguoqi: false,
            isshanchu: false,
            wordcaption: '展开搜索',
            scrollerHeight: '80px',
            showAll: false,
            ///////////////////////////////////////////////////////////////////////
            isstop: false,
            printlist: [],
            qrcode: null,
            qrCodeUrl: '',
            dialog_print: false,
            Q_prodcode: '',
            mguid: '',
            dialog_editinfo: false,
            upweburl: '',
            tableHeight: '',
            uppercent: 0,
            upcount: '',
            fileList: [],
            dialog_excelfile: false,
            mcomment: '',
            mydate: '',
            msdate: '',
            isclose: '',
            mdate: '',
            dialog_taskinfo: false,
            dialog_editdisplay: false,
            taskcode: '',
            deafultcvalue: '',
            menutreelist: [],
            begindate: '',
            enddate: '',
            radio1: '',
            tableHerd: [],
            tableData: [],
            mguid: '',
            exceldata: [],
            formdata: {
                id: 0,
                prodtype: '',
                prodcode: '',
                prodname: '',
                proddes: '',
                imgurl: '',
                barcoderule: '',
                makeperson: '',
                makedate: '',
                isstop: '',
                barcodedefault: '',
                filename: '',
                wbdays: '',
                yxdays:'',
                wbmonth:''
            },
            imageUrl: '',
            begindate: '',
            enddate: '',
            printcount: '',
            createresult: [],
            prodcode: '',
            prodname: '',
            menutabledata: [],
            /////////////////////////////////////////////////////////xinzeng
            totalSize: null,
            index: 1, //默认第一页
            pageSize: 50, //默认展示10条数据
            orderListAll: [],
            orderList: [],
            isstop: true,
            ruleForm: {
                title: "",
                coverImg: "",
                content: "",
            },
            selectedRows: [],
            formData: {
                articleText: "",
                isupEditor: true
            },
            rules: {
                title: [{ required: true, message: "菜单名称不能为空", trigger: "blur" }],
                // coverImg: [
                //   { required: true, message: "请上传封面图片", trigger: "blur" },
                // ],
                content: [
                    {
                        required: true,
                        message: "请编辑内容详情",
                        trigger: "blur",
                    },
                ],
            },
        }
    },
    methods: {
        checkvisible(btname){
            let isvisible = false
           
           const menuData = this.$store.state.biaoqianxinxi.find(v => v.bname === btname);
          // console.log('menudata',menuData,btname)
          if(menuData){
            if(menuData.isvisible)
           {isvisible=menuData.isvisible} else {isvisible=false}
          }
            else isvisible=false
           return isvisible
        },
        displaychangeisstop(item){
            if(item.isstopflag){
                this.saveformdata(1, item);
            }else{
                this.saveformdata(5, item);
            }
        },
        onCopy(e) {
      this.$message.success("复制成功");
    },
    onError() {
      this.$message.error("复制失败");
    },
    async uponlystop(sguid){
        let that=this;
        
            if(sguid){
                let importdata = [];
                importdata.push(
                        {
                            'mguid': sguid,          
                        }
                    );
   
                let jsonstr=JSON.stringify(importdata);
               // console.log('要删除：',jsonstr);
                let usercode = window.sessionStorage.getItem("usercode");
                        let token = window.sessionStorage.getItem("token");
                        const { data: res } = await BATCHSTOPBARCODE({
                            usercode,
                            token,
                            jsonstr           
                        });
                        let result = JSON.parse(res)[0];
                        if (result.code !== 200) {
                            this.$message({
                                message: result.message,
                                type: 'error',
                                duration: 1000
                            });
                           
                        } else {
                            // this.$notify({
                            //     title: "成功",
                            //     message: result.message,
                            //     type: "success",
                            //     duration: 2000,
                            // });
                            that.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                
                that.gettasktablelistdata();
            }

            }else{
                that.$message({
                    message: '请选择数据',
                    type: 'error',
                    duration: 1000
                });
            }

        },
       async upbatchstop(xflag){
        let that=this;
        
            if(that.printlist.length>0){
                let importdata = [];
             
                that.printlist.forEach(function (item, index) {
                    let batid = item.mguid.toString();
                    importdata.push(
                        {
                            'mguid': batid,          
                        }
                    )
                })             ;
                let jsonstr=JSON.stringify(importdata);
                let flag=xflag
               // console.log('要删除：',jsonstr);
                let usercode = window.sessionStorage.getItem("usercode");
                        let token = window.sessionStorage.getItem("token");
                        const { data: res } = await BATCHSTOPBARCODE({
                            usercode,
                            token,
                            jsonstr,
                            flag    
                        });
                        let result = JSON.parse(res)[0];
                        if (result.code !== 200) {
                            this.$message({
                                message: result.message,
                                type: 'error',
                                duration: 1000
                            });
                           
                        } else {
                            // this.$notify({
                            //     title: "成功",
                            //     message: result.message,
                            //     type: "success",
                            //     duration: 2000,
                            // });
                            that.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                
                that.gettasktablelistdata();
            }

            }else{
                that.$message({
                    message: '请选择数据',
                    type: 'error',
                    duration: 1000
                });
            }

        },
        handleCopy(index, row) {
            let copyData = row.mguid;
            copyData = 'https://qa.xinfei028.com/c/id=' + copyData.replace(/-/g, '');
            this.copyToClipboard(copyData);
            return;
        },
        
        copyToClipboard(textToCopy) {
            // navigator clipboard 需要https等安全上下文
           // console.log('点击了复制');
            this.$message.success('复制成功')
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                return navigator.clipboard.writeText(textToCopy);
            } else {
                // 创建text area
                let textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                // 使text area不在viewport，同时设置不可见
                textArea.style.position = "absolute";
                textArea.style.opacity = 0;
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                return new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    Document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                });
            }
        },
        isselect(i) {

            if (i == 0) {
                this.isshixiao = !this.isshixiao;
                // if (this.isshixiao) {
                //     this.isguoqi = false
                //     this.isshanchu = false
                // }
            }
            if (i == 1) {
                this.isguoqi = !this.isguoqi;
                // if (this.isguoqi) {
                //     this.isshixiao = false
                //     this.isshanchu = false
                // }
            }
            if (i == 2) {
                this.isshanchu = !this.isshanchu;
                // if (this.isshanchu) {
                //     this.isguoqi = false
                //     this.isshixiao = false
                // }
            }
            if (i == 3) {
                this.iszhibao = !this.iszhibao;
                // if (this.isshanchu) {
                //     this.isguoqi = false
                //     this.isshixiao = false
                // }
            } 
            if (i == 4) {
                this.isdaijihuo = !this.isdaijihuo;
                // if (this.isshixiao) {
                //     this.isguoqi = false
                //     this.isshanchu = false
                // }
            }          
        },
        closeSearch() {
            if (this.showAll) {
                this.wordcaption = '展开搜索'
                this.showAll = false
            } else {
                this.wordcaption = '收起搜索'
                this.showAll = true
            }
            //this.showAll = !this.showAll;
            //this.$emit('closepop');
            //console.log('子组件的状态：' + this.showAll)
        },
        dianwo() {
            //console.log('父组件的状态：' + this.scrollerHeight);
            this.scrollerHeight = '120px';
        },
        closepop() {
            this.showAll = !this.showAll;
            //console.log('父组件的状态：' + this.showAll)

        },
        handleSelectionChange(selection) {
            this.selectedRows = selection;

            this.printlist = this.selectedRows;
            // console.log('选中的数据：', this.selectedRows);
        },
        //生成二维码
        generateQRCode(index, mguid) {
            let mid = 'item-' + index;
            console.log(mguid);
            if (document.getElementById(mid)) { //不写这个判断也会报错
                document.getElementById(mid).innerHTML = ''; //这一步是防止重复生成二维码
                this.$nextTick(function () {
                    var qrcode = new QRCode(document.getElementById(mid), {
                        text: mguid, //二维码内容
                        width: 60,
                        height: 60,
                        render: 'table',
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
                    })
                });
            };
            return;
            console.log(this.$refs.printarea[0].innerText);
            return;
            this.$refs[`${barcode}`].innerHTML = "";

            // this.$refs[barcode][0].innerHTML="";   
            this.$nextTick(function () {
                var qrcode = new QRCode(this.$refs[barcode][0], {
                    text: 'item.barcode', //二维码内容
                    width: 60,
                    height: 60,
                    render: 'table',
                    colorDark: "#333333", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
                })
            });


        },
        bindQRCode: function (index) {
            // console.log(this.printlist);
            this.generateQRCode(0);
        },
        printbarcode(index, item) {
            // this.generateQRCode();

            this.mguid = item.mguid;
            this.Q_prodcode = item.barocde;
            this.dialog_print = true;
            //  setTimeout(()=>{this.generateQRCode()},100);
            //   this.billPrintClick();
        },

        handleCurrentChange(val) {
            this.index = val;
            this.gettasktablelistdata();
            //this.getList();
        },
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
            this.pageSize = val;
            this.gettasktablelistdata();
            //this.getList();
        },
        addproddex() {
            this.menuname = '';
            this.ruleForm.content = '';
            this.dialog_editinfo = true;
        },
        uploadSuccess(response) {
            this.ruleForm.coverImg =
                "http://192.168.13.106:58084/Uploads/" + response;
        },
        submitForm(formName, iddel, item) {
            this.ruleForm.content = this.$refs.editor.info_;

            if (iddel == '1') {
                this.ruleForm.content = '1';
                this.mguid = item.mguid;
            };

            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (!this.$route.query.id) {
                        let usercode = window.sessionStorage.getItem("usercode");
                        let token = window.sessionStorage.getItem("token");
                        const { data: res } = await INSDISPROD({
                            usercode,
                            token,
                            mguid: this.mguid,
                            menuname: this.ruleForm.title,
                            prodcode: this.prodcode,
                            discontent: this.ruleForm.content
                        });
                        let result = JSON.parse(res)[0];
                        if (result.code !== 200) {
                            // this.$notify.error({
                            //     title: "错误",
                            //     message: result.message,
                            //     duration: 1000,
                            // });
                            this.$message({
                                message: result.message,
                                type: 'error',
                                duration: 1000
                            });
                        } else {
                            // this.$notify({
                            //     title: "成功",
                            //     message: result.message,
                            //     type: "success",
                            //     duration: 2000,
                            // });
                            this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                            this.refrasheditdesprod();
                            this.dialog_editinfo = false;
                            //this.$router.push("/journalism");
                        }
                    } else {
                        let usercode = window.sessionStorage.getItem("usercode");
                        let token = window.sessionStorage.getItem("token");
                        const { data: res } = await INSDISPROD({
                            usercode,
                            token,
                            mguid: this.mguid,
                            menuname: this.ruleForm.title,
                            prodcode: this.prodcode,
                            discontent: this.ruleForm.coverImg
                        });
                        let result = JSON.parse(res)[0];
                        if (result.code !== 200) {
                            // this.$notify.error({
                            //     title: "错误",
                            //     message: result.message,
                            //     duration: 1000,
                            // });
                            this.$message({
                                message: result.message,
                                type: 'error',
                                duration: 1000
                            });
                        } else {
                            // this.$notify({
                            //     title: "成功",
                            //     message: result.message,
                            //     type: "success",
                            //     duration: 2000,
                            // });
                            this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                            this.refrasheditdesprod();
                            this.dialog_editinfo = false;

                            //this.$router.push("/journalism");
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        chakandisplay(index, item) {
            this.ruleForm.content = item.discontent;
            this.mguid = item.mguid;
            this.menuname = item.menuname;
            this.ruleForm.title = item.menuname;
            this.dialog_editinfo = true;
        },
        async refrasheditdesprod() {

            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let prodcode = this.prodcode;
            const { data: res } = await QRYPRODDESLIST({
                usercode,
                token,
                prodcode,
            });
            let result = JSON.parse(res);
            if (result.length == 0) {
                this.menutabledata = [];
            } else {
                this.menutabledata = result;
            };
        },
        async editdesprod(index, item) {
            this.dialog_editdisplay = true;
            this.prodcode = item.prodcode;
            this.prodname = item.prodname;
            console.log(item.mguid);
            this.mguid = item.mguid;
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let prodcode = item.prodcode;
            const { data: res } = await QRYPRODDESLIST({
                usercode,
                token,
                prodcode,
            });
            let result = JSON.parse(res);
            if (result.length == 0) {
                this.menutabledata = [];
            } else {
                this.menutabledata = result;
            };
        },
        _toExcel(mdata) {
            if (mdata.length == 0) return;
            // let data = [{ "业务政策": 1, "产品名称": 2, "证件号码": 5 }, { "产品名称": 2 }]
            let data = mdata;
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "People");
            XLSX.writeFile(wb, "扫码质保.xls");
            this.$message.success('已导出，请查看!');
        },
        async cccode() {
            if (this.formdata.prodcode) { } else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '未发现产品编码！',
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            this.$message.success('系统生成中，请勿关闭!');
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");

            let prodtype = this.formdata.prodtype;
            let prodcode = this.formdata.prodcode;
            let prodname = this.formdata.prodname;
            let proddes = this.formdata.proddes;
            let begindate = this.begindate;
            let enddate = this.enddate;
            let barcoderule = this.formdata.barcoderule;
            let printcount = this.printcount;

            const { data: res } = await MABARCODELIST({
                usercode,
                token,
                prodcode,
                begindate,
                enddate,
                printcount
            });

            let result = JSON.parse(res);
            if (result.length == 0) {
                // this.$notify.error({
                //     title: "错误",
                //     message: '生成失败！请重试!',
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {

                this.createresult = result;
                // this.$notify({
                //     title: "已生成，可点击导出EXCEL，或者到标签信息页面查找下载！",
                //     message: result.message,
                //     type: "success",
                // });
                this.$message({
                    message:"已生成，可点击导出EXCEL，或者到标签信息页面查找下载！",
                    type: 'success',
                    duration: 1000
                });
            };
        },

        createbarcode(index, item) {
            if (item.barcoderule) { }
            else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '请先完善编码规则！',
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            this.formdata.id = item.id,
                this.formdata.prodtype = item.prodtype;
            this.formdata.prodcode = item.prodcode;
            this.formdata.prodname = item.prodname;
            this.formdata.proddes = item.proddes;
            this.formdata.imgurl = item.imgurl;
            this.formdata.barcoderule = item.barcoderule;
            this.formdata.makeperson = item.makeperson;
            this.formdata.makedate = item.makedate;
            this.formdata.isstop = item.isstop;
            this.formdata.barcodedefault = item.barcodedefault;
            this.formdata.wbdays = item.wbdays;
            this.dialog_excelfile = true;
            this.createresult = [];
            this.begindate = '';
            this.enddate = '';
            this.printcount = '';
        },
        handleAvatarSuccess(res, file) {
            // console.log('执行我了，执行我了：', res);
            if (res)
                this.formdata.imgurl = res;
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        chakan(i, item) {
            this.formdata.id = item.id,
                this.formdata.prodtype = item.prodtype;
            this.formdata.prodcode = item.prodcode;
            this.formdata.prodname = item.prodname;
            this.formdata.proddes = item.proddes;
            this.formdata.imgurl = item.imgurl;
            //console.log('this.image',this.formdata.imgurl);
            this.formdata.barcoderule = item.barcoderule;
            this.formdata.makeperson = item.makeperson;
            this.formdata.makedate = item.makedate;
            this.formdata.isstop = item.isstop;
            this.formdata.barcodedefault = item.barcodedefault;
            if(item.wbdays==0){
                this.formdata.wbdays=''
            }else
            this.formdata.wbdays = item.wbdays;
            this.formdata.yxdays = item.yxdays;
            if(item.wbmonth==0){
                this.formdata.wbmonth=''
            }else
            this.formdata.wbmonth=item.wbmonth;
            // console.log(item.scope.row.imagurl);
            this.imageUrl = item.imgurl;
            this.begindate = item.begindate;
            this.enddate = item.qbdate;
            this.mguid = item.mguid;

            this.dialog_taskinfo = true;
        },
        addptypeinfo() {
            //console.log(this.printlist);
            let importdata = [];
            if (this.printlist.length > 0) {
                this.printlist.forEach(function (item, index) {
                    let batid = item.mguid.toString();
                    const parm1 = '-';
                    batid = batid.replace(/-/g, '');

                    importdata.push(
                        {
                            '内部编码': 'https://qa.xinfei028.com/c/?id=' + batid.replace("-", ""),
                            '外部编号': item.barocde
                        }
                    )
                })
                this._toExcel(importdata);
            };
  
        },
        defaultupload() {
            //console.log('默认上传覆盖');
        },

        async handleimportexcel() {
            const loading = this.$loading({
                lock: true,
                text: '上传数据中，请稍后。。。',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // console.log('上传结果1');
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let jsonstr = JSON.stringify(this.exceldata);
            const { data: res } = await IMPORTEXCEL({
                usercode,
                token,
                jsonstr
            });
            //console.log('上传结果');
            loading.close();
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                // this.$notify.error({
                //     title: "错误",
                //     message: result.message,
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                // this.$notify({
                //     title: "成功",
                //     message: result.message,
                //     type: "success",
                // });
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.dialog_excelfile = false;
                // this.refrashdate();
            };
        },
        upload(file, fileList) {
            let files = { 0: file };
            this.readExcel1(files);
        },
        readExcel1(files) {
            // 如果没有文件名
            if (files.length <= 0) {
                return;
            }
            if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                this.$message.error('上传格式不正确，请上传xls或者xlsx格式');
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                    const data = ev.target.result;
                    const workbook = read(data, {
                        type: 'binary',
                    });
                    // 取第一张表
                    const wsname = workbook.SheetNames[0];
                    // 生成json表格内容
                    var ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                    this.exceldata = ws;
                    //console.log(this.exceldata);
                    this.upcount = this.exceldata.length;
                    // 后续数据的处理

                } catch (e) {
                    return false;
                }
            };
            fileReader.readAsBinaryString(files[0]);
        },
        importexcel() {
            this.dialog_excelfile = true;
        },
        dateFormat(data) {
            let date = new Date(data);
            let YY = date.getFullYear() + '-';
            let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD;
        },
        async saveformdata(isdelete, item) {
            if (item) {
                this.formdata.id = item.id,
                this.formdata.prodtype = item.prodtype;
                this.formdata.prodcode = item.prodcode;
                this.formdata.prodname = item.prodname;
                this.formdata.proddes = item.proddes;
                this.formdata.imgurl = item.imgurl;
                this.formdata.barcoderule = item.barcoderule;
                this.formdata.makeperson = item.makeperson;
                this.formdata.makedate = item.makedate;
                this.formdata.isstop = item.isstop;
                this.formdata.barcodedefault = item.barcodedefault;
                this.formdata.mguid = item.mguid;
                this.mguid = item.mguid;
                this.formdata.wbmonth=item.wbmonth;
                // console.log(item.scope.row.imagurl);
                this.imageUrl = item.imgurl;
                this.formdata.wbdays = item.wbdays;

            };
            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let id = this.formdata.id;
            let prodtype = this.formdata.prodtype;
            let prodcode = this.formdata.prodcode;
            let prodname = this.formdata.prodname;
            let proddes = this.formdata.proddes;
            let imgurl = this.formdata.imgurl;
            let barmguid = this.mguid;
            let wbmonth=this.formdata.wbmonth;
            let begindate = '';
            if (isdelete == 1)
                begindate = '1';
            else  if (isdelete == 5)
            begindate = '5';
            else
                begindate = this.dateFormat(this.begindate);
            let enddate='';
            if(this.enddate)
              enddate = this.dateFormat(this.enddate);
            //this.enddate.format("YYYY-MM-DD");
            //imgurl=imgurl.replace(this.upweburl,'');
           //  console.log('我的点启用了',begindate);
            let barcoderule = this.formdata.barcoderule;
            let wbdays = this.formdata.wbdays;
            let makedate = '';
            let yxdays = this.formdata.yxdays;
            //console.log('wbdays',wbdays);
            const { data: res } = await UPBARCODEDATE({
                usercode,
                token,
                barmguid,
                begindate,
                enddate,
                yxdays,
                zbdays:wbdays,
                wbmonth
            });
           // console.log('result',result);
            let result = JSON.parse(res)[0];
            if (result.code !== 200) {
                // this.$notify.error({
                //     title: "错误",
                //     message: result.message,
                //     duration: 1000,
                // });
                this.$message({
                    message: result.message,
                    type: 'error',
                    duration: 1000
                });
            } else {
                // this.$notify({
                //     title: "成功",
                //     message: result.message,
                //     type: "success",
                // });
                this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
                this.formdata.id = 0,
                    this.formdata.prodtype = '';
                this.formdata.prodcode = '';
                this.formdata.prodname = '';
                this.formdata.proddes = '';
                this.formdata.imgurl = '';
                this.formdata.barcoderule = '';
                this.formdata.makeperson = '';
                this.formdata.makedate = '';
                this.formdata.isstop = '';
                this.formdata.barcodedefault = '';
                this.formdata.wbdays = '';
                this.imageUrl = '';
                this.begindate = '';
                this.enddate = '';
                this.mguid = '';
                this.formdata.wbmonth=''
                this.dialog_taskinfo = false;
                this.refrashdate();
            };

        },
        async dispose(index, item) {
            let checkperson = item.person1 + item.person2 + item.person3;
            let username = this.$store.state.btypeinfo.bfullname;
            this.mcomment = '';
            this.mydate = '';
            this.msdate = '';
            this.mguid = '';
            //console.log(checkperson);
            if (checkperson.includes(username)) {


            } else {
                // this.$notify.error({
                //     title: "错误",
                //     message: '您不需要对该异常做任何处理！',
                //     duration: 1000,
                // });
                this.$message({
                    message: '您不需要对该异常做任何处理！',
                    type: 'error',
                    duration: 1000
                });
                return;
            };
            this.mcomment = '';
            this.mydate = item.mydate;
            this.msdate = item.msdate;
            this.mguid = item.mguid;
            if (item.ngstate == "已关闭")
                this.isclose = true;
            else
                this.isclose = false;
            this.dialog_taskinfo = true;
            return;

        },
        cellClick(row, column, cell, event) {
            if (row.filepath) {
                if (column.property == 'filepath') {
                    if (row.filepath) {
                        let filepath = weburl + row.filepath;

                        window.open(filepath);
                    };
                };
            };
        },
        async refrashdate() {

            this.gettasktablelistdata();
        },

        handleChange(value) {
            let i = 0;
            i = value.length;
            if (i == 0)
                this.taskcode = ''
            else
                this.taskcode = value[i - 1];
        },
        async gettasktablelistdata(e) {
            // console.log(this.Q_prodcode);
            
            
            let begindate='2019-01-01';
            let enddate='2099-01-01';
            //console.log('我的日期：',this.value);
            if(this.value2){
              begindate = this.dateFormat(this.value2[0]);
              enddate = this.dateFormat(this.value2[1]);
            }else{
                this.$message({
                    message: '日期不能为空！',
                    type: 'error',
                    duration: 1000
                });
                return;
            }
            this.loading = true;
            let chanpinleibie = this.chanpinleibie;
            let chanpinmingcheng = this.chanpinmingcheng;
            let guigexinghao = this.guigexinghao;
            let tiaomabianhao = this.tiaomabianhao;
            let neimabianhao = this.neimabianhao;
            let chanpinbianhao = this.chanpinbianhao;


            let token = window.sessionStorage.getItem("token");
            let usercode = window.sessionStorage.getItem("usercode");
            let prodcode = this.Q_prodcode;
            let pagecount = this.pageSize;
            let pagenum = this.index;
            let sqlstr = '';
            if (this.isguoqi)
                sqlstr = '已过保'
            if (this.isshixiao)
                sqlstr = '已失效'+sqlstr
            if (this.isshanchu)
                sqlstr = '已删除'+sqlstr
            if (this.iszhibao)
                sqlstr = '质保中'+sqlstr
            if (this.isdaijihuo)
                sqlstr = '待激活'+sqlstr           
            // if (this.isstop) {
            //     sqlstr = ' and isstop=已删除';
            // }
            // sqlst=' and '

            const { data: res } = await QRYBARLIST({
                usercode,
                token,
                prodcode,
                pagecount,
                pagenum,
                sqlstr,
                begindate,
                enddate,
                chanpinleibie,
                chanpinmingcheng,
                guigexinghao,
                tiaomabianhao,
                neimabianhao,
                chanpinbianhao
            });
            let mlist = [];
            this.orderListAll = [];
            mlist = JSON.parse(res);
            if (mlist.length != 0) {
                //console.log('点查询了',mlist);
                this.totalSize = parseInt(mlist[0].totalcount);
                this.orderListAll = mlist

            }
            this.loading = false;
        }
    },
    mounted() {
        //this.gettasktablelistdata('0000400002');
        //this.gettreelist();
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.value2 = [start, end];
        this.upweburl = weburl;
       // this.tableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 40;
        //this.refrashdate();
    },
    watch:{
        'formdata.wbdays'(oldval,newval){
            if(this.formdata.wbdays){this.wbm=true;}else{this.wbm=false;};
        },
        'formdata.wbmonth'(oldval,newval){
            if(this.formdata.wbmonth){this.wbd=true;}else{this.wbd=false;};
        }
    }
};
</script>
<style lang='less' scoped>
.isselected {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #409EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    font-size: 10px;
}

.isnoselected {
    font-size: 10px;
    color: #303133;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 25px;
    width: 80px;
    background-color: #C0C4CC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnRow {
    display: block;
}

.unBtnRow {
    display: none;
}

.issueContainer {
    .issue {
        margin: 1px auto;
    }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 260px;
}

// .table-wrappers {
//     height:  calc(100% - 200px)  ;
    
// }
.testbox{
    height:calc(100% - 32px) ;
}

avatar-uploader .el-upload {
    border: 1px dashed #af1414;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
<template>
  <div>
    <div class="userContainer">
      <div class="user">
        <div class="title">用户信息</div>
        <div class="inputContainer">
          <div class="inputItem">
            <label for="Id">账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</label>
            <span>{{ userDataInfo.buserocde }}</span>
          </div>
          <div class="inputItem">
            <label for="companyUser">公司名称</label>
            <span>{{ companyUser }}</span>
          </div>
          <div class="inputItem">
            <label for="principal">负&nbsp;责&nbsp;人</label>
            <input type="text" id="principal" v-model="principal" />
          </div>
          <div class="inputItem">
            <label for="phone">联系电话</label>
            <input type="text" id="phone" v-model="phone" />
          </div>
          <div class="inputItem">
            <label for="address">公司地址</label>
            <input type="text" id="address" v-model="address" />
          </div>
          <div class="inputItem">
            <label for="mailbox"
              >邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱</label
            >
            <input type="text" id="mailbox" v-model="mailbox" />
          </div>
          <div class="btn">
            <button @click="amendSave">保存</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, saveuserinfo } from "@/api";
export default {
  data() {
    return {
      companyUser: "",
      principal: "",
      phone: "",
      address: "",
      mailbox: "",
      userDataInfo: {},
    };
  },
  methods: {
    async userInfo() {
      let token = window.sessionStorage.getItem("token");
      let usercode = window.sessionStorage.getItem("usercode");
      const { data: res } = await getUserInfo({
        usercode,
        token,
      });
      let userInfoData = JSON.parse(res);
      this.userDataInfo = userInfoData[0];
      this.companyUser = this.userDataInfo.bfullname;
      this.principal = this.userDataInfo.bperson;
      this.phone = this.userDataInfo.bphone;
      this.address = this.userDataInfo.baddress;
      this.mailbox = this.userDataInfo.email;
    },
    async amendSave() {
      const { companyUser, principal, phone, address, mailbox } = this;
      if (companyUser && principal && phone && address && mailbox) {
        let usercode = window.sessionStorage.getItem("usercode");
        let token = window.sessionStorage.getItem("token");
        const { data: res } = await saveuserinfo({
          usercode,
          token,
          bfullname: companyUser,
          person: principal,
          phone,
          address,
          email: mailbox,
        });
        let result = JSON.parse(res)[0];
        if (result.code !== 200) {
          this.$notify.error({
            title: "错误",
            message: result.message,
            duration: 1000,
          });
        } else {
          // this.$notify({
          //   title: "成功",
          //   message: result.message,
          //   type: "success",
          //   duration: 1000,
          // });
          this.$message({
                    message: result.message,
                    type: 'success',
                    duration: 1000
                });
          this.userInfo();
        }
      } else {
        this.$notify.error({
          title: "错误",
          message: "所填信息不能为空!",
          duration: 1000,
        });
      }
    },
  },
  mounted() {
    this.userInfo();
  },
};
</script>
<style lang='less' scoped>
.userContainer {
  border: 1px solid #dbdbdb;
  width: 1025px;
  .user {
    .inputContainer {
      padding-left: 30px;
      .btn {
        padding: 60px 0 100px 60px;
        button {
          width: 110px;
          height: 30px;
          background: #e4040c;
          color: #fff;
          cursor: pointer;
        }
      }
      .inputItem {
        padding-top: 20px;
        label {
          display: inline-block;
          width: 60px;
        }
        input {
          text-indent: 10px;
          width: 180px;
          height: 25px;
          border: 1px solid #cccccc;
        }
        #companyUser {
          width: 200px;
        }
        #address {
          width: 400px;
        }
      }
    }
    .title {
      height: 35px;
      line-height: 35px;
      background: #f3f4f6;
      border-bottom: 1px solid #dbdbdb;
      text-indent: 30px;
    }
  }
}
</style>